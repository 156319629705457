import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  DataGrid,
  GridActionsCellItem,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Pagination,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { DropsContext } from 'contexts/DropsContext';
import { CfoContext } from 'contexts/CfoContext';
import { GridContext } from 'contexts/GridContext';
import { dropBoolean } from 'utils/drops';
import { useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';
import localeText from 'utils/localeText';
import styles from './styles';

const Importacao = () => {
  const rotina = 'CfoRegra';
  const defaultValues = {
    cfoemitente_id: null,
    cfo_id: null,
    natureza_operacao_id: null,
  };
  const [rows, setRows] = useState([]);
  const { openModal, closeModal } = useModal();
  const { control, watch } = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);
  const { deleteGrid, deleteLoading } = useContext(GridContext);
  const { getLoading, getCfoRegra, postLoading, postCfoRegra } =
    useContext(CfoContext);

  const data = rows?.filter((item) =>
    Object.entries(watch())
      .filter(([chave, valor]) => Boolean(valor))
      .every(([chave, valor]) => item[chave] === valor)
  );

  const loadData = () => {
    getCfoRegra({ param: 'IMP', cb: setRows });
    closeModal();
  };

  useEffect(() => {
    loadData();
  }, []);

  const Modal = ({ item, onSubmit }) => {
    const defaultValues = {
      id: item?.id,
      cfoemitente_id: item?.cfoemitente_id || null,
      cfo_id: item?.cfo_id || null,
      natureza_operacao_id: item?.natureza_operacao_id || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(CfoContext);

    return (
      <>
        <DialogTitle>
          {Boolean(item?.id) ? 'Editar' : 'Adicionar'} Regra
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Dropdown
                name="cfoemitente_id"
                label="CFOP do Emitente"
                control={control}
                options={drops?.Cfo}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                name="cfo_id"
                label="CFOP"
                control={control}
                options={drops?.Cfo}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                name="natureza_operacao_id"
                label="Natureza da Operação"
                control={control}
                options={drops?.NaturezaOperacao}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            loading={postLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Salvar
          </Button>
          <Button color="secondary" variant="contained" onClick={closeModal}>
            Cancelar
          </Button>
        </DialogActions>
      </>
    );
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  const CfoEmitCell = ({ row }) => {
    const item = row?.cfoemitente;
    const lines = [{ label: 'Origem / Destino', value: item?.orgdest }];

    return (
      <Box flex={1} my={1}>
        <Typography fontWeight="bold" mb={1} variant="body1">
          {item?.cfo} - {item?.descricao}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const CfoCell = ({ row }) => {
    const item = row?.cfo;
    const lines = [{ label: 'Origem / Destino', value: item?.orgdest }];

    return (
      <Box flex={1} my={1}>
        <Typography fontWeight="bold" mb={1} variant="body1">
          {item?.cfo} - {item?.descricao}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const NaturezaCell = ({ row }) => {
    const item = row?.natureza_operacao;
    const lines = [{ label: 'Operação', value: item?.operacao }];

    return (
      <Box flex={1} my={1}>
        <Typography fontWeight="bold" mb={1} variant="body1">
          {item?.natureza}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={1} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Card title="Filtros">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Dropdown
                  name="cfoemitente_id"
                  label="CFOP do Emitente"
                  control={control}
                  options={drops?.Cfo}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="cfo_id"
                  label="CFOP"
                  control={control}
                  options={drops?.Cfo}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="natureza_operacao_id"
                  label="Natureza da Operação"
                  control={control}
                  options={drops?.NaturezaOperacao}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={10} display="flex">
          <Card style={styles?.card}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption" color="text.secondary">
                  Essas regras são utilizadas na importação do XML de notas
                  fiscais para que o sistema identifique a natureza de operação
                  e substitua o CFOP dos itens baseando-se no CFOP do Emitente.
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    openModal(
                      <Modal
                        item={watch()}
                        onSubmit={(data) =>
                          postCfoRegra({ data, cb: loadData })
                        }
                      />
                    )
                  }
                  startIcon={<Add fontSize="medium" />}
                >
                  Adicionar Regra
                </Button>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={data}
                  columns={[
                    {
                      field: 'cfoemitente',
                      headerName: 'CFOP do Emitente',
                      flex: 2,
                      sortable: true,
                      renderCell: (props) => <CfoEmitCell {...props} />,
                    },
                    {
                      field: 'cfo',
                      headerName: 'CFOP',
                      flex: 2,
                      sortable: true,
                      renderCell: (props) => <CfoCell {...props} />,
                    },
                    {
                      field: 'natureza_operacao',
                      headerName: 'Natureza da Operação',
                      flex: 1,
                      sortable: true,
                      renderCell: (props) => <NaturezaCell {...props} />,
                    },
                    {
                      field: 'actions',
                      type: 'actions',
                      headerName: 'Ações',
                      width: 100,
                      getActions: ({ id, row }) => {
                        return [
                          <GridActionsCellItem
                            icon={<Edit />}
                            label="Editar"
                            onClick={() =>
                              openModal(
                                <Modal
                                  item={row}
                                  onSubmit={(data) =>
                                    postCfoRegra({ data, cb: loadData })
                                  }
                                />
                              )
                            }
                          />,
                          <GridActionsCellItem
                            icon={<Delete />}
                            label="Excluir"
                            onClick={() =>
                              openModal(
                                <DeleteModal
                                  onSubmit={() =>
                                    deleteGrid({
                                      params: { rotina, id },
                                      cb: loadData,
                                    })
                                  }
                                />
                              )
                            }
                          />,
                        ];
                      },
                    },
                  ]}
                  autoHeight
                  pagination
                  pageSize={5}
                  density="compact"
                  disableColumnMenu
                  showCellRightBorder
                  showColumnRightBorder
                  disableSelectionOnClick
                  localeText={localeText}
                  keepNonExistentRowsSelected
                  getRowHeight={() => 'auto'}
                  loading={getLoading || deleteLoading || postLoading}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Pagination: CustomPagination,
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Digitacao = () => {
  const rotina = 'CfoRegra';
  const defaultValues = {
    cfo_id: null,
    natureza_operacao_id: null,
    origem_tributaria_id: null,
    situacao_tributaria_id: null,
    contribuinte_id: null,
    usofinal: null,
    subtrib: null,
    suframa: null,
  };
  const [rows, setRows] = useState([]);
  const { openModal, closeModal } = useModal();
  const { control, watch } = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);
  const { deleteGrid, deleteLoading } = useContext(GridContext);
  const { getLoading, getCfoRegra, postLoading, postCfoRegra } =
    useContext(CfoContext);

  const data = rows?.filter((item) =>
    Object.entries(watch())
      .filter(([chave, valor]) => Boolean(valor))
      .every(([chave, valor]) => item[chave] === valor)
  );

  const loadData = () => {
    getCfoRegra({ param: 'DIG', cb: setRows });
    closeModal();
  };

  useEffect(() => {
    loadData();
  }, []);

  const Modal = ({ item, onSubmit }) => {
    const defaultValues = {
      id: item?.id,
      cfo_id: item?.cfo_id || null,
      natureza_operacao_id: item?.natureza_operacao_id || null,
      origem_tributaria_id: item?.origem_tributaria_id || null,
      situacao_tributaria_id: item?.situacao_tributaria_id || null,
      contribuinte_id: item?.contribuinte_id || null,
      usofinal: item?.usofinal || null,
      subtrib: item?.subtrib || null,
      suframa: item?.suframa || null,
      dadosadc: item?.dadosadc || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(CfoContext);

    return (
      <>
        <DialogTitle>
          {Boolean(item?.id) ? 'Editar' : 'Adicionar'} Regra
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Dropdown
                name="cfo_id"
                label="CFOP"
                control={control}
                options={drops?.Cfo}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                name="natureza_operacao_id"
                label="Natureza da Operação"
                control={control}
                options={drops?.NaturezaOperacao}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="origem_tributaria_id"
                label="Origem Tributária"
                control={control}
                options={drops?.OrigemTributaria}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="situacao_tributaria_id"
                label="Situação Tributária"
                control={control}
                options={drops?.SituacaoTributaria}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="contribuinte_id"
                label="Contribuinte"
                control={control}
                options={drops?.Contribuinte}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="usofinal"
                label="Uso Final"
                control={control}
                options={dropBoolean}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="subtrib"
                label="Substituição Tributária"
                control={control}
                options={dropBoolean}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="suframa"
                label="SUFRAMA"
                control={control}
                options={dropBoolean}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="dadosadc"
                label="Dados Adicionais"
                control={control}
                multiline
                rows={5}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            loading={postLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Salvar
          </Button>
          <Button color="secondary" variant="contained" onClick={closeModal}>
            Cancelar
          </Button>
        </DialogActions>
      </>
    );
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  const CfoCell = ({ row }) => {
    const item = row?.cfo;
    const lines = [{ label: 'Origem / Destino', value: item?.orgdest }];

    return (
      <Box flex={1} my={1}>
        <Typography fontWeight="bold" mb={1} variant="body1">
          {item?.cfo} - {item?.descricao}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const NaturezaCell = ({ row }) => {
    const item = row?.natureza_operacao;
    const lines = [{ label: 'Operação', value: item?.operacao }];

    return (
      <Box flex={1} my={1}>
        <Typography fontWeight="bold" mb={1} variant="body1">
          {item?.natureza}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={1} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const OrigemCell = ({ row }) => {
    const item = row?.origem_tributaria;
    const lines = [
      { label: 'Código', value: item?.codigo?.toString() },
      { label: 'Origem', value: item?.origem },
    ];

    return (
      <Box flex={1} my={1}>
        <Typography fontWeight="bold" mb={1} variant="body1">
          {item?.descricao}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={1} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const SituacaoCell = ({ row }) => {
    const item = row?.situacao_tributaria;
    const lines = [{ label: 'Código', value: item?.codigo?.toString() }];

    return (
      <Box flex={1} my={1}>
        <Typography fontWeight="bold" mb={1} variant="body1">
          {item?.descricao}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={1} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const ContribuinteCell = ({ row }) => {
    const item = row?.contribuinte;
    const lines = [{ label: 'Código', value: item?.codigo?.toString() }];

    return (
      <Box flex={1} my={1}>
        <Typography fontWeight="bold" mb={1} variant="body1">
          {item?.descricao}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={1} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Card title="Filtros">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Dropdown
                  name="cfo_id"
                  label="CFOP"
                  control={control}
                  options={drops?.Cfo}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="natureza_operacao_id"
                  label="Natureza da Operação"
                  control={control}
                  options={drops?.NaturezaOperacao}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="origem_tributaria_id"
                  label="Origem Tributária"
                  control={control}
                  options={drops?.OrigemTributaria}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="situacao_tributaria_id"
                  label="Situação Tributária"
                  control={control}
                  options={drops?.SituacaoTributaria}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="contribuinte_id"
                  label="Contribuinte"
                  control={control}
                  options={drops?.Contribuinte}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="usofinal"
                  label="Uso Final"
                  control={control}
                  options={dropBoolean}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="subtrib"
                  label="Substituição Tributária"
                  control={control}
                  options={dropBoolean}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="suframa"
                  label="SUFRAMA"
                  control={control}
                  options={dropBoolean}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={10} display="flex">
          <Card style={styles?.card}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption" color="text.secondary">
                  Essas regras são utilizadas para o preenchimento automático do
                  CFOP na inclusão dos itens de notas fiscais.
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    openModal(
                      <Modal
                        item={watch()}
                        onSubmit={(data) =>
                          postCfoRegra({ data, cb: loadData })
                        }
                      />
                    )
                  }
                  startIcon={<Add fontSize="medium" />}
                >
                  Adicionar Regra
                </Button>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={data}
                  columns={[
                    {
                      field: 'cfo',
                      headerName: 'CFOP',
                      width: 500,
                      sortable: true,
                      renderCell: (props) => <CfoCell {...props} />,
                    },
                    {
                      field: 'natureza_operacao',
                      headerName: 'Natureza da Operação',
                      width: 300,
                      sortable: true,
                      renderCell: (props) => <NaturezaCell {...props} />,
                    },
                    {
                      field: 'origem_tributaria',
                      headerName: 'Origem Tributária',
                      width: 200,
                      sortable: true,
                      renderCell: (props) => <OrigemCell {...props} />,
                    },
                    {
                      field: 'situacao_tributaria',
                      headerName: 'Situação Tributária',
                      width: 200,
                      sortable: true,
                      renderCell: (props) => <SituacaoCell {...props} />,
                    },
                    {
                      field: 'contribuinte',
                      headerName: 'Contribuinte',
                      width: 200,
                      sortable: true,
                      renderCell: (props) => <ContribuinteCell {...props} />,
                    },
                    {
                      field: 'usofinal',
                      headerName: 'Uso Final',
                      type: 'boolean',
                      width: 100,
                      sortable: true,
                      valueGetter: ({ value }) => value === 'SIM',
                    },
                    {
                      field: 'subtrib',
                      headerName: 'Subst. Trib.',
                      type: 'boolean',
                      width: 100,
                      sortable: true,
                      valueGetter: ({ value }) => value === 'SIM',
                    },
                    {
                      field: 'suframa',
                      headerName: 'SUFRAMA',
                      type: 'boolean',
                      width: 100,
                      sortable: true,
                      valueGetter: ({ value }) => value === 'SIM',
                    },
                    {
                      field: 'actions',
                      type: 'actions',
                      headerName: 'Ações',
                      width: 100,
                      getActions: ({ id, row }) => {
                        return [
                          <GridActionsCellItem
                            icon={<Edit />}
                            label="Editar"
                            onClick={() =>
                              openModal(
                                <Modal
                                  item={row}
                                  onSubmit={(data) =>
                                    postCfoRegra({ data, cb: loadData })
                                  }
                                />
                              )
                            }
                          />,
                          <GridActionsCellItem
                            icon={<Delete />}
                            label="Excluir"
                            onClick={() =>
                              openModal(
                                <DeleteModal
                                  onSubmit={() =>
                                    deleteGrid({
                                      params: { rotina, id },
                                      cb: loadData,
                                    })
                                  }
                                />
                              )
                            }
                          />,
                        ];
                      },
                    },
                  ]}
                  autoHeight
                  pagination
                  pageSize={5}
                  density="compact"
                  disableColumnMenu
                  showCellRightBorder
                  showColumnRightBorder
                  disableSelectionOnClick
                  localeText={localeText}
                  keepNonExistentRowsSelected
                  getRowHeight={() => 'auto'}
                  loading={getLoading || deleteLoading || postLoading}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Pagination: CustomPagination,
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Cfop = () => {
  const titulo = 'Regras de CFOP';
  const [tab, setTab] = useState(0);

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs variant="fullWidth" value={tab} onChange={(_, v) => setTab(v)}>
            <Tab value={0} label="Regras para importação de NF" />
            <Tab value={1} label="Regras para digitação de NF" />
          </Tabs>
        </Grid>
        {Boolean(tab) ? <Digitacao /> : <Importacao />}
      </Grid>
    </Container>
  );
};

export default Cfop;
