import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { PrecoContext } from 'contexts/PrecoContext';
import { DropsContext } from 'contexts/DropsContext';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Card from 'components/Card';

const Manutenção = () => {
  const defaultValues = {
    produto_id: null,
    celinha_id: null,
    cegrupo_id: null,
    cliente_id: null,
    vendedor_id: null,
    grupo_entidade_id: null,
    condicao_pagamento_id: null,
    tabpreco_id: null,
    preco: '',
    margem: '',
    inicio: null,
    termino: null,
  };
  const navigate = useNavigate();
  const { drops } = useContext(DropsContext);
  const { postLoading, postPreco } = useContext(PrecoContext);
  const { control, handleSubmit } = useForm({ defaultValues });

  const onSubmit = (data) => postPreco({ data, cb: () => navigate(-1) });

  return (
    <Container>
      <Header titulo="Adicionar Regra" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VirtualDrop
              name="produto_id"
              control={control}
              label="Produto"
              options={drops?.Produto}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="celinha_id"
              control={control}
              label="Linha do Produto"
              options={drops?.LinhaProduto}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="cegrupo_id"
              control={control}
              label="Grupo do Produto"
              options={drops?.GrupoProduto}
            />
          </Grid>
          <Grid item xs={12}>
            <VirtualDrop
              name="cliente_id"
              control={control}
              label="Cliente"
              options={drops?.Entidade}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="vendedor_id"
              control={control}
              label="Vendedor"
              options={drops?.EntidadeTipo?.filter(
                (t) => t?.tipo === 'VENDEDOR' || t?.tipo === 'REPRESENTANTE'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="grupo_entidade_id"
              control={control}
              label="Grupo da Entidade"
              options={drops?.GrupoEntidade}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="condicao_pagamento_id"
              control={control}
              label="Condição de Pagamento"
              options={drops?.CondicaoPagamento}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="tabpreco_id"
              control={control}
              label="Tabela de Preço"
              options={drops?.TabelaPreco}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="preco" control={control} label="Preço" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="margem" control={control} label="Margem" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="inicio"
              control={control}
              label="Início"
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="termino"
              control={control}
              label="Termino"
              type="date"
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Manutenção;
