import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { ContasContext } from 'contexts/ContasContext';
import { useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Contas = () => {
  const rotina = 'Contas';
  const titulo = 'Contas - Caixas e Bancos';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { postGrid, deleteGrid, exportPdf, exportXls } =
    useContext(GridContext);
  const { contas, getContas, getLoading } = useContext(ContasContext);
  const { control, getValues, reset } = useForm({
    defaultValues: contas?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getContas({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      action: () => navigate('/app/Tabelas/Contas/Manutencao'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: contas?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: contas?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={contas?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
  ];

  const rowOptions = [
    {
      name: 'Editar',
      icon: 'edit',
      action: ({ row }) =>
        navigate('/app/Tabelas/Contas/Manutencao', { state: row }),
    },
    {
      name: 'Deletar',
      icon: 'delete',
      action: ({ row }) =>
        openModal(
          <DeleteModal
            onSubmit={() =>
              deleteGrid({
                params: { rotina, id: row?.id },
                cb: () => {
                  loadGrid();
                  closeModal();
                },
              })
            }
          />
        ),
    },
    {
      name: 'Ativar',
      icon: 'key',
      menu: true,
      show: ({ row }) => row?.ativo !== 'SIM',
      action: ({ row }) =>
        postGrid({
          data: { data: { ...row, ativo: 'SIM' }, rotina },
          cb: loadGrid,
        }),
    },
    {
      name: 'Inativar',
      icon: 'key_off',
      menu: true,
      show: ({ row }) => row?.ativo === 'SIM',
      action: ({ row }) =>
        postGrid({
          data: { data: { ...row, ativo: 'NAO' }, rotina },
          cb: loadGrid,
        }),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={contas?.colunas} />
      <Card>
        <Grid
          grid={contas}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={contas} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Contas;
