const styles = {
  accordionHeader: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  permissionContainer: {
    pl: '2em',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  permissionsText: {
    flexGrow: 1,
    mr: '1em',
  },
};

export default styles;
