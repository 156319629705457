import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

const Produto = () => {
  const [config, setConfig] = useState({ current: null, tab: 0 });

  const onChangeTab = ({ key, tab }) => {
    setConfig((prev) =>
      prev?.current === key ? { current: key, tab } : { current: key, tab: 0 }
    );
  };

  return <Outlet context={{ tab: config?.tab, onChangeTab }} />;
};

export default Produto;
