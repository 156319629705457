import React from 'react';
import { Grid } from '@mui/material';
import { dropBoolean, dropModulo } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';

const Filter = ({ grid, control }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('modulo') && (
        <Grid item xs={12}>
          <Dropdown
            name="modulo"
            control={control}
            label="Módulo"
            options={dropModulo}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('ativo') && (
        <Grid item xs={12}>
          <Dropdown
            name="ativo"
            control={control}
            label="Ativo"
            options={dropBoolean}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
