import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  ButtonBase,
  Checkbox,
  Grid,
  InputLabel,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { findOnArray } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { dropCondicaoMl } from 'utils/drops';
import { DropsContext } from 'contexts/DropsContext';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from './styles';
import api from 'services/api';

const Manutenção = () => {
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [verifyAdditionals, setVerifyAdditionals] = useState(false);
  const steps = ['Identificação do Produto', 'Categorias', 'Anúncio'];
  const defaultValues = {
    produto_id: null,
    condition: null,
    descricao: '',
    integra_access_id: null,
    categorias: [],
    category_id: null,
    config: null,
    saldo: '',
    reserva: 'SIM',
    title: '',
    preco: '',
    listing_type_id: null,
    logistic_type: null,
    garantia: null,
    tempogarantia: '',
    especificacao: '',
    free_shipping: false,
    local_pick_up: false,
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { drops } = useContext(DropsContext);
  const dynamicForm = useForm();
  const { control, handleSubmit, setValue, getValues, watch } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (state?.id) {
      const prod = findOnArray(state?.id, drops?.Produto);
      setValue('produto_id', prod?.value || null);
      setValue('descricao', prod?.label || '');
    }
  }, []);

  useEffect(() => {
    if (tab !== 2) {
      dynamicForm?.reset();
      setVerifyAdditionals(false);
    }
  }, [tab]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      if (tab === 0) {
        if (!Boolean(values?.produto_id)) {
          return toastWarning('Produto obrigatório');
        }
        if (!Boolean(values?.condition)) {
          return toastWarning('Condição obrigatória');
        }

        const { data } = await api.post(
          `/Integracoes/MercadoLivre/Categorias/Predicao`,
          {
            item: values?.descricao,
            integra_access_id: values?.integra_access_id,
          }
        );
        setValue('categorias', data || []);
        setTab(1);
      } else if (tab === 1) {
        const payload = {
          produto_id: values?.produto_id,
          category_id: values?.category_id,
          condition: values?.condition,
          integra_access_id: values?.integra_access_id,
        };
        const { data } = await api.post(
          `/Integracoes/MercadoLivre/Envio`,
          payload
        );
        setValue('config', data || null);
        setValue('saldo', data?.saldo);
        setValue('especificacao', data?.especificacao);
        setValue('preco', data?.preco);
        setTab(2);
      } else if (tab === 2) {
        const {
          produto_id,
          title,
          category_id,
          condition,
          listing_type_id,
          logistic_type,
          garantia,
          tempogarantia,
          saldo,
          reserva,
          especificacao,
          preco,
          free_shipping,
          local_pick_up,
          descricao,
          config,
          categorias,
          integra_access_id,
          ...rest
        } = values;
        const attributes = Object.keys(rest).map((id) => ({
          id,
          value_name: rest[id],
        }));
        const data = {
          produto_id,
          title,
          category_id,
          condition,
          listing_type_id,
          logistic_type,
          garantia,
          tempogarantia,
          attributes,
          saldo,
          reserva,
          especificacao,
          preco,
          free_shipping,
          local_pick_up,
          integra_access_id,
        };
        if (!verifyAdditionals) {
          const {
            data: { obrigatorios, condicionais },
          } = await api.post(`/Integracoes/MercadoLivre/Detalhes`, {
            category_id,
            attributes,
            integra_access_id: values?.integra_access_id,
          });
          setVerifyAdditionals(true);
          if (Boolean(obrigatorios?.length)) {
            const attributes = [
              ...config?.attributes,
              ...obrigatorios,
              ...condicionais,
            ];
            toastWarning(
              'Antes de finalizar, precisamos de mais algumas informações.'
            );
            setValue('config', { ...config, attributes });
          } else {
            await api.post(`/Integracoes/MercadoLivre/Anuncio`, data);
            navigate(-1);
          }
        } else {
          await api.post(`/Integracoes/MercadoLivre/Anuncio`, data);
          navigate(-1);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const RenderSteps = () => {
    if (tab === 0) {
      return (
        <Grid item xs={12}>
          <Card title="Identificação do Produto">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <VirtualDrop
                  name="produto_id"
                  control={control}
                  label="Produto"
                  options={drops?.Produto}
                  disabled={Boolean(state?.id)}
                  onValueChange={(v) => setValue('descricao', v?.label || '')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Dropdown
                  name="condition"
                  control={control}
                  label="Condição"
                  options={dropCondicaoMl}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Input
                  name="descricao"
                  control={control}
                  label="Descrição + Marca + Modelo"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Dropdown
                  name="integra_access_id"
                  control={control}
                  label="Integração"
                  options={drops?.IntegraAccess?.filter((f) =>
                    f?.label?.includes('MERCADO LIVRE')
                  )}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  loading={loading}
                >
                  Próximo
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      );
    }
    if (tab === 1) {
      const { categorias } = getValues();
      return (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setTab(0)}
                loading={loading}
              >
                Voltar
              </Button>
            </Grid>
            {categorias?.map((c) => (
              <Grid item xs={12} key={c?.category_id}>
                <ButtonBase
                  sx={styles?.baseButton}
                  disabled={loading}
                  onClick={() => {
                    setValue('category_id', c?.category_id);
                    handleSubmit(onSubmit)();
                  }}
                >
                  <Card style={styles?.cardCategory}>
                    <Typography variant="h6">{c?.category_name}</Typography>
                    <Typography variant="subtitle1" fontSize={14}>
                      {c?.domain_name}
                    </Typography>
                  </Card>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </Grid>
      );
    }
    if (tab === 2) {
      const { config } = getValues();
      const exceeded = Boolean(
        (config?.altura_prod || 0) > (config?.altura_categoria || 0) ||
          (config?.largura_prod || 0) > (config?.largura_categoria || 0) ||
          (config?.peso_prod || 0) > (config?.peso_categoria || 0)
      );
      return (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setTab(1)}
                loading={loading}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input name="title" control={control} label="Título" />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputMask name="preco" control={control} label="Preço" />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputMask name="saldo" control={control} label="Saldo" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <InputLabel variant="body2">Reservar estoque</InputLabel>
              <Switch
                checked={watch('reserva') === 'SIM'}
                onChange={(_, v) => setValue('reserva', v ? 'SIM' : 'NAO')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="listing_type_id"
                control={control}
                label="Tipo de Publicação"
                options={config?.tipos_publicacao?.map((v) => ({
                  value: v?.id,
                  label: v?.name,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="logistic_type"
                control={control}
                label="Tipo de Logística"
                options={config?.logistic_types}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="garantia"
                control={control}
                label="Garantia"
                options={config?.garantias?.map((v) => ({
                  value: v?.name,
                  label: v?.name,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name="tempogarantia"
                control={control}
                label="Tempo da Garantia"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="especificacao"
                control={control}
                label="Especificação"
                multiline
                rows={10}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="div" align="center">
                Atributos
              </Typography>
            </Grid>
            <RenderInputs
              inputs={config?.attributes}
              control={dynamicForm?.control}
            />
            {Boolean(config?.free_shipping) && (
              <Grid item xs={12} sm={6} display="flex" alignItems="center">
                <Checkbox
                  checked={watch('free_shipping')}
                  id="free_shipping"
                  label="Frete Gráris"
                  color="primary"
                  onChange={(_, v) => setValue('free_shipping', v)}
                />
                <InputLabel htmlFor="free_shipping">Frete Grátis</InputLabel>
              </Grid>
            )}
            {Boolean(config?.local_pick_up) && (
              <Grid item xs={12} sm={6} display="flex" alignItems="center">
                <Checkbox
                  checked={watch('local_pick_up')}
                  id="local_pick_up"
                  label="Retirada em Mãos"
                  color="primary"
                  onChange={(_, v) => setValue('local_pick_up', v)}
                />
                <InputLabel htmlFor="local_pick_up">
                  Retirada em Mãos
                </InputLabel>
              </Grid>
            )}
            <Grid item xs={12}>
              {exceeded && (
                <Typography variant="subtitle2" color="red">
                  Atenção! Dimensões do produto excedida. Você estará sujeito a
                  cobrança adicional de frete.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                loading={loading}
                onClick={dynamicForm?.handleSubmit((dv) =>
                  handleSubmit((v) => onSubmit({ ...v, ...dv }))()
                )}
              >
                Finalizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const RenderInputs = ({ inputs = [], control }) => {
    return inputs
      ?.sort((a, b) => (b?.tags?.required ? 1 : a?.tags?.required ? -1 : 0))
      ?.map((input) => {
        const label = input?.tags?.required ? '* ' + input?.name : input?.name;
        if (input?.value_type === 'string') {
          return (
            <Grid item xs={12} key={input?.id}>
              <Tooltip title={input?.hint} placement="top">
                <Box>
                  <Input name={input?.id} control={control} label={label} />
                </Box>
              </Tooltip>
            </Grid>
          );
        }
        if (input?.value_type === 'number') {
          return (
            <Grid item xs={12} key={input?.id}>
              <Tooltip title={input?.hint} placement="top">
                <Box>
                  <InputMask name={input?.id} control={control} label={label} />
                </Box>
              </Tooltip>
            </Grid>
          );
        }
        if (input?.value_type === 'list' || input?.value_type === 'boolean') {
          return (
            <Grid item xs={12} key={input?.id}>
              <Tooltip title={input?.hint} placement="top">
                <Box>
                  <Dropdown
                    name={input?.id}
                    control={control}
                    label={label}
                    options={input?.values?.map((v) => ({
                      value: v?.name,
                      label: v?.name,
                    }))}
                  />
                </Box>
              </Tooltip>
            </Grid>
          );
        }
        return null;
      });
  };

  return (
    <Container>
      <Header titulo="Cadastrar Anúncio" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Stepper activeStep={tab} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Card>
        </Grid>
        <RenderSteps />
      </Grid>
    </Container>
  );
};

export default Manutenção;
