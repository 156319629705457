import React from 'react';
import Loader from 'components/Loader';
import Entidade from './Entidade';
import Documentos from './Documentos';
import Endereços from './Endereços';
import Contatos from './Contatos';
import Vínculos from './Vínculos';
import Registros from './Registros';
import Operadoras from './Operadoras';
import Física from './Física';
import Perfis from './Perfis';
import Anexos from './Anexos';
import Filial from './Filial';
import Aprovações from './Aprovações';

const Tabs = ({ tab, getLoading, ...props }) => {
  if (getLoading) {
    return <Loader />;
  }
  switch (tab) {
    case 0:
      return <Entidade {...props} />;
    case 1:
      return <Documentos {...props} />;
    case 2:
      return <Endereços {...props} />;
    case 3:
      return <Contatos {...props} />;
    case 4:
      return <Vínculos {...props} />;
    case 5:
      return <Registros {...props} />;
    case 6:
      return <Operadoras {...props} />;
    case 7:
      return <Física {...props} />;
    case 8:
      return <Perfis {...props} />;
    case 9:
      return <Anexos {...props} />;
    case 10:
      return <Filial {...props} />;
    case 11:
      return <Aprovações {...props} />;
    default:
      return null;
  }
};

export default Tabs;
