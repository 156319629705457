import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import Container from 'components/Container';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';

const Manutenção = () => {
  const rotina = 'SecaoCnae';
  const defaultValues = {
    descricao: '',
    codigo: '',
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { drops, getDrops } = useContext(DropsContext);
  const { postLoading, postGrid } = useContext(GridContext);
  const { control, handleSubmit, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(state?.values);
  }, []);

  const onSubmit = (data) =>
    postGrid({
      data: { data, rotina },
      cb: () => {
        navigate(-1);
        getDrops([rotina]);
      },
    });

  return (
    <Container>
      <Header
        titulo={(Boolean(state) ? 'Editar' : 'Cadastrar') + ' Seção CNAE'}
      />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input name="descricao" control={control} label="Descrição" />
          </Grid>
          <Grid item xs={12}>
            <Input name="codigo" control={control} label="Código" />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Manutenção;
