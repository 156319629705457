import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import { ProdutosContext } from 'contexts/ProdutosContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import TableContainer from 'components/TableContainer';
import styles from '../styles';
import moment from 'moment';

const Registros = ({ onSubmit, onDelete, produto = {}, disableActions }) => {
  const { openDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const rotina = 'ProdutoRegs';

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      produto_id: produto?.id,
      users_id: user?.id,
      registro: item?.registro || '',
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(ProdutosContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input name="registro" control={control} label="Registro" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Registros: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Registros" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Registro')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!produto?.ProdutoRegs?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Registro</TableCell>
                    <TableCell>Usuário</TableCell>
                    <TableCell>Data / Hora</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {produto?.ProdutoRegs?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.registro}</TableCell>
                      <TableCell>{item?.User?.name}</TableCell>
                      <TableCell>
                        {Boolean(item?.datahora) &&
                          moment(item?.datahora).isValid() &&
                          moment(item?.datahora).format(
                            'DD/MM/YYYY [às] HH:mm'
                          )}
                      </TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Registro'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Registros;
