import LogoMercadoLivreFull from 'assets/mercado_livre_full.svg';

const mock = [
  {
    field: 'status',
    renderCell: ({ value }) => renderStatus(value),
  },
  {
    field: 'full',
    renderCell: ({ value }) => renderLogo(value),
  },
];

const renderLogo = (value) => {
  const style = { objectFit: 'contain', width: '100%', height: '100%' };
  if (value) {
    return <img src={LogoMercadoLivreFull} style={style} />;
  }
  return '';
};

const renderStatus = (value) => {
  const status =
    value === 'active'
      ? { color: '#7FFF7F', text: 'ATIVO' }
      : value === 'paused'
      ? { color: '#FFFF7F', text: 'PAUSADO' }
      : value === 'closed'
      ? { color: '#FF7F7F', text: 'FECHADO' }
      : { color: 'transparent', text: '' };

  const style = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: status?.color,
    borderRadius: '50px',
  };
  return (
    <div style={style}>
      <span>{status?.text}</span>
    </div>
  );
};

export default mock;
