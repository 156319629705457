import React, { useContext, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import {
  Article,
  AttachFile,
  Contacts,
  CreditCard,
  Description,
  Group,
  Groups,
  Home,
  Link,
  Person,
  Recommend,
  Settings,
} from '@mui/icons-material';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { EntidadesContext } from 'contexts/EntidadesContext';
import { useModal, useDialog } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import MigrarCarteiraModal from './Modals/MigrarCarteiraModal';
import CustomTabs from './Tabs';

const Detalhes = () => {
  const { onChangeTab, tab } = useOutletContext();
  const { id } = useParams();
  const { drops } = useContext(DropsContext);
  const { openModal, closeModal } = useModal();
  const { closeDialog } = useDialog();
  const { deleteGrid } = useContext(GridContext);
  const {
    entidade,
    getLoading,
    getEntidade,
    postEntidade,
    postLoading,
    putEntidadeFilial,
    putLoading,
    deleteEntidadePerfil,
    postAnexo,
    deleteAnexo,
  } = useContext(EntidadesContext);
  const disableActions = false;

  const loadEntidade = () => getEntidade(id);

  useEffect(() => {
    loadEntidade();
    onChangeTab({ tab: tab, key: id });
  }, []);

  const onSubmit = (data) =>
    postEntidade({
      data,
      cb: () => {
        loadEntidade();
        closeDialog();
      },
    });

  const onDelete = (params) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params,
            cb: () => {
              loadEntidade();
              closeModal();
            },
          })
        }
      />
    );

  const onSubmitMidia = (data) =>
    putEntidadeFilial({
      data,
      cb: () => {
        loadEntidade();
      },
    });

  const onDeletePerfil = ({ id: perfil_id }) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteEntidadePerfil({
            id: perfil_id,
            cb: () => {
              loadEntidade();
              closeModal();
            },
          })
        }
      />
    );

  const onSubmitAnexo = (data) =>
    postAnexo({
      data,
      cb: () => {
        loadEntidade();
      },
    });

  const onDeleteAnexo = (id_anexo) =>
    deleteAnexo({
      id: id_anexo,
      cb: () => {
        loadEntidade();
      },
    });

  const tabs = [
    {
      value: 0,
      key: 'Entidade',
      label: 'Entidade',
      icon: <Settings />,
    },
    {
      value: 1,
      key: 'Documentos',
      label: 'Documentos',
      icon: <Description />,
    },
    {
      value: 2,
      key: 'Enderecos',
      label: 'Endereços',
      icon: <Home />,
    },
    {
      value: 3,
      key: 'Contatos',
      label: 'Contatos',
      icon: <Contacts />,
    },
    {
      value: 4,
      key: 'Vinculos',
      label: 'Vínculos',
      icon: <Link />,
    },
    {
      value: 5,
      key: 'Registros',
      label: 'Registros',
      icon: <Article />,
    },
    {
      value: 6,
      key: 'Operadoras',
      label: 'Operadoras',
      icon: <CreditCard />,
    },
    {
      value: 7,
      key: 'Fisica',
      label: 'Física',
      icon: <Person />,
      show: entidade?.pessoa === 'FISICA',
    },
    {
      value: 8,
      key: 'Perfis',
      label: 'Perfis',
      icon: <Group />,
    },
    {
      value: 9,
      key: 'Anexos',
      label: 'Anexos',
      icon: <AttachFile />,
    },
    {
      value: 10,
      key: 'Filial',
      label: 'Filial',
      icon: <Groups />,
      show: entidade?.EntidadeTipos?.some((t) => t?.tipo === 'FILIAL'),
    },
    {
      value: 11,
      key: 'Aprovacoes',
      label: 'Aprovações',
      icon: <Recommend />,
    },
  ];

  const options = [
    {
      name: 'Migrar Carteira',
      icon: 'wallet',
      show:
        entidade &&
        entidade?.EntidadeTipos?.some(
          (t) => t?.tipo === 'VENDEDOR' || t?.tipo === 'REPRESENTANTE'
        ),
      action: () => openModal(<MigrarCarteiraModal entidade={entidade} />),
    },
  ];

  return (
    <Container>
      <Header titulo="Entidade" subtitulo={entidade?.nome} outros={options} />
      <Card>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(_, tab) => onChangeTab({ tab, key: id })}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          {tabs.map(({ show = true, ...t }) => show && <Tab {...t} />)}
        </Tabs>
      </Card>
      <CustomTabs
        tab={tab}
        drops={drops}
        entidade={entidade}
        getLoading={getLoading}
        disableActions={disableActions}
        loading={postLoading || putLoading}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onSubmitMidia={onSubmitMidia}
        onDeletePerfil={onDeletePerfil}
        onSubmitAnexo={onSubmitAnexo}
        onDeleteAnexo={onDeleteAnexo}
      />
    </Container>
  );
};

export default Detalhes;
