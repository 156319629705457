import React from 'react';
import { Grid } from '@mui/material';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('conta') && (
        <Grid item xs={12}>
          <Input name="conta" control={control} label="Conta" />
        </Grid>
      )}
      {checkDisplay('plano') && (
        <Grid item xs={12}>
          <Dropdown
            name="plano"
            control={control}
            label="Plano"
            options={drops?.Plano}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('inivalid') && (
        <Grid item xs={6}>
          <InputMask
            name="inivalid.i"
            control={control}
            label="Início da Validade Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('inivalid') && (
        <Grid item xs={6}>
          <InputMask
            name="inivalid.f"
            control={control}
            label="Início da Validade Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('fimvalid') && (
        <Grid item xs={6}>
          <InputMask
            name="fimvalid.i"
            control={control}
            label="Fim da Validade Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('fimvalid') && (
        <Grid item xs={6}>
          <InputMask
            name="fimvalid.f"
            control={control}
            label="Fim da Validade Final"
            type="date"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
