import React from 'react';
import { Grid } from '@mui/material';
import {
  dropBoolean,
  dropEstruturaLinhaProduto,
  dropIdSped,
  dropCusto,
  dropTipoItem,
} from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';

const Filter = ({ grid, control }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('linha') && (
        <Grid item xs={12}>
          <Input name="linha" control={control} label="Linha" />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('servico') && (
        <Grid item xs={12}>
          <Dropdown
            name="servico"
            control={control}
            label="Serviço"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('processo') && (
        <Grid item xs={12}>
          <Dropdown
            name="processo"
            control={control}
            label="Processo"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('estrutura') && (
        <Grid item xs={12}>
          <Dropdown
            multiple
            name="estrutura"
            control={control}
            label="Estrutura"
            options={dropEstruturaLinhaProduto}
          />
        </Grid>
      )}
      {checkDisplay('apontamento') && (
        <Grid item xs={12}>
          <Dropdown
            name="apontamento"
            control={control}
            label="Apontamento"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('referencia') && (
        <Grid item xs={12}>
          <Dropdown
            name="referencia"
            control={control}
            label="Referência"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('obreferencia') && (
        <Grid item xs={12}>
          <Dropdown
            name="obreferencia"
            control={control}
            label="Observação Referência"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('sefazean') && (
        <Grid item xs={12}>
          <Dropdown
            name="sefazean"
            control={control}
            label="SEFAZ EAN"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('idsped') && (
        <Grid item xs={12}>
          <Dropdown
            multiple
            name="idsped"
            control={control}
            label="ID Sped"
            options={dropIdSped}
          />
        </Grid>
      )}
      {checkDisplay('custo') && (
        <Grid item xs={12}>
          <Dropdown
            multiple
            name="custo"
            control={control}
            label="Custo"
            options={dropCusto}
          />
        </Grid>
      )}
      {checkDisplay('tipoitem') && (
        <Grid item xs={12}>
          <Dropdown
            multiple
            name="tipoitem"
            control={control}
            label="Tipo Item"
            options={dropTipoItem}
          />
        </Grid>
      )}
      {checkDisplay('ativo') && (
        <Grid item xs={12}>
          <Dropdown
            name="ativo"
            control={control}
            label="Ativo"
            options={dropBoolean}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
