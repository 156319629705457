import { useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { dropBcIcms, dropNTributado, dropBoolean } from 'utils/drops';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import Input from 'components/Input';

const ManutençãoModal = ({ regra = {}, onSubmit }) => {
  const rotina = 'Tributacao';
  const defaultValues = {
    id: regra?.id,
    tributo_id: regra?.tributo_id || null,
    natureza_operacao_id: regra?.natureza_operacao_id || null,
    filial_id: regra?.filial_id || null,
    uforg_id: regra?.uforg_id || null,
    ufdest_id: regra?.ufdest_id || null,
    cfiscal_id: regra?.cfiscal_id || null,
    produto_id: regra?.produto_id || null,
    origem_tributaria_id: regra?.origem_tributaria_id || null,
    entidade_id: regra?.entidade_id || null,
    atividade_entidade_id: regra?.atividade_entidade_id || null,
    contribuinte_id: regra?.contribuinte_id || null,
    usofinal: regra?.usofinal || null,
    percentual: regra?.percentual || '',
    reducao_pc: regra?.reducao_pc || '',
    diferido_pc: regra?.diferido_pc || '',
    icms_interno: regra?.icms_interno || '',
    modbc_id: regra?.modbc_id || null,
    bcicms: regra?.bcicms || null,
    bcipi: regra?.bcipi || null,
    bcdesconto: regra?.bcdesconto || null,
    bcfrete: regra?.bcfrete || null,
    bcoutros: regra?.bcoutros || null,
    situacao_tributaria_id: regra?.situacao_tributaria_id || null,
    ntributado: regra?.ntributado || null,
    desoneracao_mot: regra?.desoneracao_mot || null,
    recuperavel: regra?.recuperavel || null,
    ativo: regra?.ativo || null,
    dadosadc: regra?.dadosadc || '',
  };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postLoading } = useContext(GridContext);
  const { drops } = useContext(DropsContext);

  const titleProps = {
    fontWeight: 'bold',
    variant: 'body1',
    fontSize: 18,
    mt: 2,
  };

  const onConfirm = (data) => onSubmit({ data, rotina });

  return (
    <>
      <DialogTitle>Adicionar Regra</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Typography {...titleProps}>Filtros</Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <VirtualDrop
              name="tributo_id"
              label="Tributo"
              control={control}
              options={drops?.Tributo}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <VirtualDrop
              name="natureza_operacao_id"
              label="Natureza da Operação"
              control={control}
              options={drops?.NaturezaOperacao}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VirtualDrop
              name="filial_id"
              label="Filial"
              control={control}
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <VirtualDrop
              name="uforg_id"
              label="UF de Origem"
              control={control}
              options={drops?.UF}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <VirtualDrop
              name="ufdest_id"
              label="UF de Destino"
              control={control}
              options={drops?.UF}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <VirtualDrop
              name="cfiscal_id"
              label="Classificação Fiscal"
              control={control}
              options={drops?.Cfiscal}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VirtualDrop
              name="produto_id"
              label="Produto"
              control={control}
              options={drops?.Produto}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <VirtualDrop
              name="origem_tributaria_id"
              label="Origem Tributária"
              control={control}
              options={drops?.OrigemTributaria}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VirtualDrop
              name="entidade_id"
              label="Entidade"
              control={control}
              options={drops?.Entidade}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VirtualDrop
              name="atividade_entidade_id"
              label="Atividade Entidade"
              control={control}
              options={drops?.AtividadeEntidade}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <VirtualDrop
              name="contribuinte_id"
              label="Contribuinte"
              control={control}
              options={drops?.Contribuinte}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <VirtualDrop
              name="usofinal"
              label="Uso Final"
              control={control}
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography {...titleProps}>Percentuais</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="percentual"
              control={control}
              label="% do Imposto"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="reducao_pc"
              control={control}
              label="% de Redução do Imposto"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="diferido_pc"
              control={control}
              label="% de Diferimento do Imposto"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="icms_interno"
              control={control}
              label="% do ICMS Interno"
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography {...titleProps}>Base de Cálculo</Typography>
          </Grid>
          <Grid item xs={12}>
            <VirtualDrop
              name="modbc_id"
              control={control}
              label="Modalidade"
              options={drops?.ModalidadeBc}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <VirtualDrop
              name="bcicms"
              control={control}
              label="Base de Cálculo do ICMS"
              options={dropBcIcms}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <VirtualDrop
              name="bcipi"
              control={control}
              label="Base de Cálculo do IPI"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <VirtualDrop
              name="bcdesconto"
              control={control}
              label="Base de Cálculo do Desconto"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <VirtualDrop
              name="bcfrete"
              control={control}
              label="Base de Cálculo do Frete"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <VirtualDrop
              name="bcoutros"
              control={control}
              label="Base de Cálculo do Outros"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography {...titleProps}>Outros</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <VirtualDrop
              name="situacao_tributaria_id"
              control={control}
              label="Situação Tributária"
              options={drops?.SituacaoTributaria}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VirtualDrop
              name="ntributado"
              control={control}
              label="Não Tributado"
              options={dropNTributado}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              name="desoneracao_mot"
              control={control}
              label="Motivo da Desoneração"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VirtualDrop
              name="recuperavel"
              control={control}
              label="Recuperável"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VirtualDrop
              name="ativo"
              control={control}
              label="Ativo"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="dadosadc"
              control={control}
              label="Dados Adicionais na NF"
              multiline
              rows={2}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit(onConfirm)}
          loading={postLoading}
        >
          Salvar
        </Button>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default ManutençãoModal;
