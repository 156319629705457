import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import Container from 'components/Container';
import moment from 'moment';

const EvoluçãoDoCusto = ({ indicadores }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetWidth / 2);
  }, []);

  const chart = indicadores?.indicadores?.custo;

  return (
    <Container>
      <Box ref={ref}>
        {Boolean(chart?.length) ? (
          <LineChart
            xAxis={[
              {
                scaleType: 'point',
                valueFormatter: (v) =>
                  Boolean(chart?.length)
                    ? moment(chart[v - 1]?.dtatualizacao).format('DD/MM/YY')
                    : [0],
                data: Boolean(chart?.length)
                  ? chart?.map((_, i) => i + 1)
                  : [0],
              },
            ]}
            series={[
              {
                curve: 'linear',
                color: '#22267B',
                label: 'Valor em R$ (Últimas 10 Modificações)',
                data: Boolean(chart?.length)
                  ? chart?.map((g) => g?.custo || 0)
                  : [0],
              },
            ]}
            width={width}
            height={height}
          />
        ) : (
          <Typography textAlign="center">Produto sem custo</Typography>
        )}
      </Box>
    </Container>
  );
};

export default EvoluçãoDoCusto;
