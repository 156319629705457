import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';
import { QueryStats } from '@mui/icons-material';
import { ProdutosContext } from 'contexts/ProdutosContext';
import Container from 'components/Container';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Card from 'components/Card';
import Blank from 'assets/blank.png';
import Vendas from './Indices/Vendas';
import Compras from './Indices/Compras';
import Inventário from './Indices/Inventário';
import Produção from './Indices/Produção';
import Estoque from './Indices/Estoque';
import EntradasSaidas from './Indices/EntradasSaidas';
import EvoluçãoDoPreço from './Indices/EvoluçãoDoPreço';
import EvoluçãoDoCusto from './Indices/EvoluçãoDoCusto';
import FormaçãoDoCusto from './Indices/FormaçãoDoCusto';
import styles from './styles';
import OptionsDrawer from './Drawer';

const Indicadores = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  const { produtoIndicadores, getLoading, getProdutoIndicadores } =
    useContext(ProdutosContext);

  useEffect(() => {
    if (id) {
      getProdutoIndicadores(id);
    }
  }, []);

  const options = [
    {
      value: 0,
      title: 'Vendas',
    },
    {
      value: 1,
      title: 'Compras',
      show: produtoIndicadores?.origem_tributaria?.includes('COMPRAD'),
    },
    {
      value: 2,
      title: 'Inventário',
    },
    {
      value: 3,
      title: 'Produção',
      show: produtoIndicadores?.origem_tributaria?.includes('FABRICAD'),
    },
    {
      value: 4,
      title: 'Entradas / Saídas',
    },
    {
      value: 5,
      title: 'Estoque',
    },
    {
      value: 6,
      title: 'Evolução do Preço',
    },
    {
      value: 7,
      title: 'Evolução do Custo',
    },
    {
      value: 8,
      title: 'Formação do Custo',
    },
  ];

  const RenderOption = () => {
    switch (tab) {
      case 0:
        return {
          title: 'Vendas',
          component: <Vendas indicadores={produtoIndicadores} />,
        };
      case 1:
        return {
          title: 'Compras',
          component: <Compras indicadores={produtoIndicadores} />,
        };
      case 2:
        return {
          title: 'Inventário',
          component: <Inventário indicadores={produtoIndicadores} />,
        };
      case 3:
        return {
          title: 'Produção',
          component: <Produção indicadores={produtoIndicadores} />,
        };
      case 4:
        return {
          title: 'Entradas / Saídas',
          component: <EntradasSaidas indicadores={produtoIndicadores} />,
        };
      case 5:
        return {
          title: 'Estoque',
          component: <Estoque indicadores={produtoIndicadores} />,
        };
      case 6:
        return {
          title: 'Evolução do Preço',
          component: <EvoluçãoDoPreço indicadores={produtoIndicadores} />,
        };
      case 7:
        return {
          title: 'Evolução do Custo',
          component: <EvoluçãoDoCusto indicadores={produtoIndicadores} />,
        };
      case 8:
        return {
          title: 'Formação do Custo',
          component: <FormaçãoDoCusto indicadores={produtoIndicadores} />,
        };
      default:
        return null;
    }
  };

  const handleDrawerToggle = () => setOpenDrawer(!openDrawer);

  const InfosProduto = () => {
    const lines = [
      {
        label: 'Referência:',
        value: produtoIndicadores?.referencia,
      },
      {
        label: 'Código:',
        value: produtoIndicadores?.codigo,
      },
      {
        label: 'Grupo:',
        value: produtoIndicadores?.grupo,
      },
      {
        label: 'Linha:',
        value: produtoIndicadores?.linha,
      },
      {
        label: 'Marca:',
        value: produtoIndicadores?.marca,
      },
      {
        label: 'Modelo:',
        value: produtoIndicadores?.modelo,
      },
      {
        label: 'NCM:',
        value: produtoIndicadores?.ncm,
      },
      {
        label: 'Origem Tributária:',
        value: produtoIndicadores?.origem_tributaria,
      },
      {
        label: 'Complemento 1:',
        value: produtoIndicadores?.complemento1,
      },
      {
        label: 'Complemento 2:',
        value: produtoIndicadores?.complemento2,
      },
      {
        label: 'Altura:',
        value: produtoIndicadores?.altura,
      },
      {
        label: 'Largura:',
        value: produtoIndicadores?.largura,
      },
      {
        label: 'Comprimento:',
        value: produtoIndicadores?.comprimento,
      },
      {
        label: 'Peso:',
        value: produtoIndicadores?.peso,
      },
      {
        label: 'Volume:',
        value: produtoIndicadores?.volume,
      },
    ];

    return (
      <Grid item sm={12} md={4}>
        <Card>
          <Card>
            <Box
              component="img"
              sx={styles.image}
              src={produtoIndicadores?.url || Blank}
            />
          </Card>
          <Box sx={styles?.lineContainer}>
            <Chip
              variant="outlined"
              label={produtoIndicadores?.ativo === 'SIM' ? 'ATIVO' : 'INATIVO'}
              sx={styles?.fullWidth}
              color={
                produtoIndicadores?.ativo === 'SIM' ? 'primary' : 'secondary'
              }
            />
          </Box>
          {lines?.map((l, i) => (
            <Box key={i?.toString()} sx={styles?.lineContainer}>
              <Typography variant="body2" sx={styles?.lineLabel}>
                {l?.label}
              </Typography>
              <Typography variant="body2" sx={styles?.lineValue}>
                {l?.value || '-'}
              </Typography>
            </Box>
          ))}
        </Card>
      </Grid>
    );
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={produtoIndicadores?.descricao || ''} />
      <OptionsDrawer
        options={options}
        open={openDrawer}
        onClose={handleDrawerToggle}
        onChange={(v) => {
          setTab(v);
          handleDrawerToggle();
        }}
      />
      <Grid container spacing={2}>
        <InfosProduto />
        <Grid item sm={12} md={8}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" alignItems="center">
                <IconButton color="primary" onClick={handleDrawerToggle}>
                  <QueryStats color="primary" />
                </IconButton>
                <Typography variant="h6" sx={styles?.title}>
                  {RenderOption()?.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {RenderOption()?.component}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Indicadores;
