import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { dropBoolean } from 'utils/drops';
import { useDialog } from 'components/Modals';
import { EntidadesContext } from 'contexts/EntidadesContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Documentos = ({
  drops,
  onSubmit,
  onDelete,
  entidade = {},
  disableActions,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'EntidadeDocumento';

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      entidade_id: entidade?.id,
      tipo_documento_id: item?.tipo_documento_id || null,
      documento: item?.documento || '',
      principal: item?.principal || 'NAO',
      dtexpedicao: item?.dtexpedicao || null,
      validade: item?.validade || null,
      categoria: item?.categoria || '',
      serie: item?.serie || '',
      uf: item?.uf || '',
      orgao_emissor: item?.orgao_emissor || '',
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(EntidadesContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="tipo_documento_id"
            control={control}
            label="Tipo de Documento"
            options={drops?.TipoDocumento}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="documento" control={control} label="Nº do Documento" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="principal"
            control={control}
            label="Principal"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="dtexpedicao"
            control={control}
            label="Data de Expedição"
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="validade"
            control={control}
            label="Data de Validade"
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="categoria"
            control={control}
            label="Categoria"
            mask="**"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="serie"
            control={control}
            label="Série"
            mask="*****"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="uf" control={control} label="UF" mask="aa" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="orgao_emissor"
            control={control}
            label="Órgão Emissor"
            mask="*****"
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Documentos: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Documentos" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Documento')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!entidade?.EntidadeDocumentos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo de Documento</TableCell>
                    <TableCell>Nº do Documento</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entidade?.EntidadeDocumentos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.TipoDocumento?.descricao}</TableCell>
                      <TableCell>{item?.documento}</TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Documento'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Documentos;
