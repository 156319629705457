import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { dropTipoPessoa } from 'utils/drops';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import styles from '../styles';

const Entidade = ({
  drops,
  loading,
  onSubmit,
  entidade = {},
  disableActions,
}) => {
  const defaultValues = {
    id: entidade?.id,
    nome: entidade?.nome || '',
    fantasia: entidade?.fantasia || '',
    pessoa: entidade?.pessoa || null,
    codigo: entidade?.codigo || '',
    atividade_entidade_id: entidade?.atividade_entidade_id || null,
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <Card title="Entidade" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Input name="nome" control={control} label="Razão Social" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="fantasia" control={control} label="Nome Fantasia" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="pessoa"
            control={control}
            label="Tipo de Pessoa"
            options={dropTipoPessoa}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="codigo" control={control} label="Código" type="number" />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            name="atividade_entidade_id"
            control={control}
            label="Atividade"
            options={drops?.AtividadeEntidade}
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={handleSubmit((Entidade) => onSubmit({ Entidade }))}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Entidade;
