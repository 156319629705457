const styles = {
  btnGroup: {
    width: '100%',
  },
  btn: {
    flex: 1,
  },
  card: {
    flex: 1,
  },
};

export default styles;
