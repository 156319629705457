import { useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { dropContaContra, dropCtaComplemento } from 'utils/drops';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

const ManutençãoModal = ({ regra = {}, onSubmit }) => {
  const defaultValues = {
    id: regra?.id,
    cxconta_id: regra?.cxconta_id || null,
    bxcontra: regra?.bxcontra || null,
    ctacred_id: regra?.ctacred_id || null,
    cpctacred: regra?.cpctacred || null,
    tmcpctacred: regra?.tmcpctacred || '',
    ctadeb_id: regra?.ctadeb_id || null,
    cpctadeb: regra?.cpctadeb || null,
    tmcpctadeb: regra?.tmcpctadeb || '',
    inivalid: regra?.inivalid || null,
    fimvalid: regra?.fimvalid || null,
  };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postLoading } = useContext(GridContext);
  const { drops } = useContext(DropsContext);

  const titleProps = {
    fontWeight: 'bold',
    variant: 'body1',
    fontSize: 18,
    mt: 2,
  };

  return (
    <>
      <DialogTitle>Adicionar Regra</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Typography {...titleProps}>Conta</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="cxconta_id"
              control={control}
              label="Conta"
              options={drops?.Cxconta}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="bxcontra"
              control={control}
              label="Conta Contra"
              options={dropContaContra}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography {...titleProps}>Conta de Crédito</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="ctacred_id"
              control={control}
              label="Conta de Crédito"
              options={drops?.Planoconta?.filter(
                (p) => p?.operacional === 'SIM'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="cpctacred"
              control={control}
              label="Complemento"
              options={dropCtaComplemento}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="tmcpctacred"
              control={control}
              label="Tamanho do Complemento"
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography {...titleProps}>Conta de Débito</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="ctadeb_id"
              control={control}
              label="Conta de Débito"
              options={drops?.Planoconta?.filter(
                (p) => p?.operacional === 'SIM'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="cpctadeb"
              control={control}
              label="Complemento"
              options={dropCtaComplemento}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="tmcpctadeb"
              control={control}
              label="Tamanho do Complemento"
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography {...titleProps}>Validade</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              type="date"
              name="inivalid"
              control={control}
              label="Data de Início"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              type="date"
              name="fimvalid"
              control={control}
              label="Data de Término"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default ManutençãoModal;
