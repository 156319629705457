import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  sigla: '',
  descricao: '',
};

export const MoedaContext = createContext();

export const MoedaProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [moeda, setMoeda] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getMoeda = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: moeda?.filter,
        page: moeda?.page,
        size: moeda?.size,
        order: moeda?.order,
        ...payload,
      });
      setMoeda((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getCotacoes = async ({ id, cb }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Financeiro/Formas/Moedas/${id}`);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <MoedaContext.Provider
      value={{
        getLoading,
        moeda,
        getMoeda,
        getCotacoes,
      }}
    >
      {children}
    </MoedaContext.Provider>
  );
};
