import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  full: [],
  titulo: '',
  descricao: '',
  status: [],
  estoque: '',
  vendas: '',
  sku: '',
  logistica: [],
  listagem: [],
};

export const ProdutosMlContext = createContext();

export const ProdutosMlProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [putLoading, setPutLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [produtoMl, setProdutoMl] = useState(null);
  const [produtosMl, setProdutosMl] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getProdutosMl = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: produtosMl?.filter,
        page: produtosMl?.page,
        size: produtosMl?.size,
        order: produtosMl?.order,
        ...payload,
      });
      setProdutosMl((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getProdutoMl = async (params) => {
    try {
      setGetLoading(true);
      setProdutoMl(null);
      const { data } = await api.get(`/Integracoes/MercadoLivre/Anuncio`, {
        params,
      });
      setProdutoMl(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const putProdutoMl = async ({ data, cb }) => {
    try {
      setPutLoading(true);
      await api.put(`/Integracoes/MercadoLivre/Anuncio`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPutLoading(false);
    }
  };

  const syncProdutoMl = async ({ data, cb }) => {
    try {
      setSyncLoading(true);
      await api.post(`/Integracoes/MercadoLivre/Sync`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setSyncLoading(false);
    }
  };

  const getConfig = async ({ cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Integracoes/MercadoLivre/Config`);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postConfig = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Integracoes/MercadoLivre/Config`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <ProdutosMlContext.Provider
      value={{
        getLoading,
        getProdutosMl,
        syncLoading,
        syncProdutoMl,
        produtosMl,
        getProdutoMl,
        produtoMl,
        putProdutoMl,
        putLoading,
        getConfig,
        postLoading,
        postConfig,
      }}
    >
      {children}
    </ProdutosMlContext.Provider>
  );
};
