const mock = [
  {
    field: 'status',
    renderCell: ({ value }) =>
      value === 'NORMAL'
        ? 'NORMAL'
        : value === 'BANNED'
        ? 'BANIDO'
        : value === 'SELLER_DELETE'
        ? 'EXCLUIDO PELO VENDEDOR'
        : value === 'SHOPEE_DELETE'
        ? 'EXCLUIDO PELA SHOPEE'
        : value === 'REVIEWING'
        ? 'EM REVISÃO'
        : '',
  },
];

export default mock;
