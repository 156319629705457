import React from 'react';
import { Grid } from '@mui/material';
import Input from 'components/Input';
import InputMask from 'components/InputMask';

const Filter = ({ grid, control }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('codigo') && (
        <Grid item xs={12}>
          <Input name="codigo" control={control} label="Código da Integração" />
        </Grid>
      )}
      {checkDisplay('updated_at') && (
        <Grid item xs={6}>
          <InputMask
            name="updated_at.i"
            control={control}
            label="Data de Atualização Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('updated_at') && (
        <Grid item xs={6}>
          <InputMask
            name="updated_at.f"
            control={control}
            label="Data de Atualização Final"
            type="date"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
