import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from '../styles';
import moment from 'moment';

const PendênciasDeVendas = ({ indicadores }) => {
  const lineValue = indicadores?.Cliente?.pendenciasLimites;
  const lines = [
    {
      label: 'Última Venda:',
      value:
        lineValue?.ultima_venda &&
        `${moment()
          .subtract(lineValue?.ultima_venda, 'days')
          .format('DD/MM/YYYY')} (${lineValue?.ultima_venda} Dias)`,
      limit:
        lineValue?.ultima_venda_limite &&
        `${moment()
          .subtract(lineValue?.ultima_venda_limite, 'days')
          .format('DD/MM/YYYY')} (${lineValue?.ultima_venda_limite} Dias)`,
    },
    {
      label: 'Valor Pendente:',
      value: lineValue?.valor_pendente?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      limit: lineValue?.valor_pendente_limite?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Valor em Atraso:',
      value: lineValue?.atraso_valor?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      limit: lineValue?.atraso_valor_limite?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Dias em Atraso:',
      value: lineValue?.atraso_dias && `${lineValue?.atraso_dias} Dias`,
      limit:
        lineValue?.atraso_dias_limite &&
        `${lineValue?.atraso_dias_limite} Dias`,
    },
    {
      label: 'Quantidade em Atraso:',
      value: lineValue?.atraso_qtd,
      limit: lineValue?.atraso_qtd_limite,
    },
    {
      label: 'Prazo Máximo de Venda:',
      value: lineValue?.prazo_maximo && `${lineValue?.prazo_maximo} Dias`,
    },
    {
      label: 'Valor Máximo de Venda:',
      value: lineValue?.valor_maximo?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
  ];
  return lines?.map((l, i) => (
    <Box key={i?.toString()} sx={{ my: '1em' }}>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="body2" sx={styles?.lineLabel}>
          {l?.label}
        </Typography>
        <Typography variant="body2" sx={styles?.lineValue}>
          {l?.value || '-'}
        </Typography>
      </Box>
      {Boolean(l?.limit) && (
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2" sx={styles?.lineLabel}>
            Limite:
          </Typography>
          <Typography variant="body2" sx={styles?.lineValue}>
            {l?.limit || '-'}
          </Typography>
        </Box>
      )}
    </Box>
  ));
};

export default PendênciasDeVendas;
