const styles = {
  card: {
    marginTop: '1em',
  },
  image: {
    objectFit: 'contain',
    maxHeight: '300px',
    overflow: 'hidden',
    width: '100%',
  },
  icon: {
    color: 'primary.white',
  },
  pdfContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '150px',
  },
  pdfTitle: {
    display: 'flex',
  },
};

export default styles;
