import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  linha: '',
  descricao: '',
  servico: null,
  processo: null,
  estrutura: [],
  apontamento: null,
  referencia: null,
  obreferencia: null,
  sefazean: null,
  idsped: [],
  custo: [],
  tipoitem: [],
  ativo: null,
};

export const LinhaProdutoContext = createContext();

export const LinhaProdutoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [linhaProduto, setLinhaProduto] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getLinhaProduto = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: linhaProduto?.filter,
        page: linhaProduto?.page,
        size: linhaProduto?.size,
        order: linhaProduto?.order,
        ...payload,
      });
      setLinhaProduto((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <LinhaProdutoContext.Provider
      value={{
        getLoading,
        linhaProduto,
        getLinhaProduto,
      }}
    >
      {children}
    </LinhaProdutoContext.Provider>
  );
};
