import React from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Grid, Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDialog } from 'components/Modals';

const Shopee = ({ produto }) => {
  const navigate = useNavigate();
  const { closeDialog } = useDialog();

  const onShowDetails = (id) => {
    navigate(`/app/Integrações/Shopee/Detalhes/${id}`);
    closeDialog();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DataGrid
          rows={produto?.ProdutoShopees}
          columns={[
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              flex: 1,
              getActions: ({ row }) => [
                <GridActionsCellItem
                  icon={<Icon>visibility</Icon>}
                  label="Visualizar"
                  onClick={() => onShowDetails(row?.id)}
                />,
              ],
            },
            {
              field: 'status',
              headerName: 'Status',
              flex: 1,
              sortable: false,
              renderCell: ({ value }) =>
                value === 'NORMAL'
                  ? 'NORMAL'
                  : value === 'BANNED'
                  ? 'BANIDO'
                  : value === 'SELLER_DELETE'
                  ? 'EXCLUIDO PELO VENDEDOR'
                  : value === 'SHOPEE_DELETE'
                  ? 'EXCLUIDO PELA SHOPEE'
                  : value === 'REVIEWING'
                  ? 'EM REVISÃO'
                  : '',
            },
            {
              field: 'titulo',
              headerName: 'Título do Anúncio',
              flex: 4,
              sortable: false,
            },
            {
              field: 'estoque',
              headerName: 'Estoque',
              type: 'number',
              flex: 1,
              sortable: false,
            },
            {
              field: 'sku',
              headerName: 'SKU',
              type: 'number',
              flex: 1,
              sortable: false,
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
        />
      </Grid>
    </Grid>
  );
};

export default Shopee;
