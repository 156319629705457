import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { dropBoolean, dropTipoMovimentacao } from 'utils/drops';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import styles from '../styles';

const Produto = ({
  drops,
  loading,
  onSubmit,
  produto = {},
  disableActions,
}) => {
  const defaultValues = {
    id: produto?.id,
    celinha_id: produto?.celinha_id || null,
    cegrupo_id: produto?.cegrupo_id || null,
    cemarca_id: produto?.cemarca_id || null,
    cfiscal_id: produto?.cfiscal_id || null,
    origem_tributaria_id: produto?.origem_tributaria_id || null,
    anp_id: produto?.anp_id || null,
    unidade_medida_id: produto?.unidade_medida_id || null,
    referencia: produto?.referencia || '',
    codigo: produto?.codigo || '',
    cdbarra: produto?.cdbarra || '',
    entidade_id: produto?.entidade_id || null,
    lotemin: produto?.lotemin || '',
    lotemult: produto?.lotemult || '',
    loterepos: produto?.loterepos || '',
    temporepos: produto?.temporepos || '',
    estmin: produto?.estmin || '',
    estmax: produto?.estmax || '',
    custo: produto?.custo_compra || '',
    preco: produto?.preco || '',
    margem: produto?.margem || '',
    peso: produto?.peso || '',
    volume: produto?.volume || '',
    altura: produto?.altura || '',
    largura: produto?.largura || '',
    comprimento: produto?.comprimento || '',
    qtrib: produto?.qtrib || '',
    cest: produto?.cest || '',
    complemento1: produto?.complemento1 || '',
    complemento2: produto?.complemento2 || '',
    localizador: produto?.localizador || '',
    fci: produto?.fci || '',
    movimentacao: produto?.movimentacao || null,
    rastreavel: produto?.rastreavel || null,
    variacao: produto?.variacao || null,
    retornavel: produto?.retornavel || null,
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  const onConfirm = ({ preco, custo, margem, ...rest }) => {
    let Preco = {};
    let Custo = {};
    if (preco && preco !== produto?.preco) {
      Preco = { ...Preco, preco };
    }
    if (margem && margem !== produto?.margem) {
      Preco = { ...Preco, margem };
    }
    if (custo && custo !== produto?.custo_compra) {
      Custo = { ...Custo, custo };
    }
    const Produto = { id: produto?.id, ...rest };
    let data = { Produto };
    if (Boolean(Object.keys(Preco)?.length)) {
      data = { ...data, Preco };
    }
    if (Boolean(Object.keys(Custo)?.length)) {
      data = { ...data, Custo };
    }
    onSubmit(data);
  };

  return (
    <Card title="Produto" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="celinha_id"
            control={control}
            label="Linha"
            options={drops?.LinhaProduto}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="cegrupo_id"
            control={control}
            label="Grupo"
            options={drops?.GrupoProduto}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="cemarca_id"
            control={control}
            label="Marca"
            options={drops?.Marca}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VirtualDrop
            name="cfiscal_id"
            control={control}
            label="Classificação Fiscal"
            options={drops?.Cfiscal}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="origem_tributaria_id"
            control={control}
            label="Origem Tributária"
            options={drops?.OrigemTributaria}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="anp_id"
            control={control}
            label="ANP"
            options={drops?.Anp}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="unidade_medida_id"
            control={control}
            label="Unidade de Medida"
            options={drops?.UnidadeMedida}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="referencia" control={control} label="Referência" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="codigo" control={control} label="Código" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="cdbarra"
            control={control}
            label="Código de Barras"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <VirtualDrop
            name="entidade_id"
            control={control}
            label="Proprietário"
            options={drops?.Entidade}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="estmin" control={control} label="Estoque Mínimo" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="estmax" control={control} label="Estoque Máximo" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="lotemin" control={control} label="Lote Mínimo" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="lotemult" control={control} label="Lote Múltiplo" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="loterepos"
            control={control}
            label="Lote Reposição"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="temporepos"
            control={control}
            label="Tempo Reposição"
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} sm={4}>
            <InputMask name="custo" control={control} label="Custo" />
          </Grid>
        )}
        <Grid item xs={12} sm={disableActions ? 6 : 4}>
          <InputMask name="preco" control={control} label="Preço de Venda" />
        </Grid>
        <Grid item xs={12} sm={disableActions ? 6 : 4}>
          <InputMask name="margem" control={control} label="Margem" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="peso" control={control} label="Peso" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="volume" control={control} label="Volume" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="altura" control={control} label="Altura" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="largura" control={control} label="Largura" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="comprimento" control={control} label="Comprimento" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="qtrib"
            control={control}
            label="Fator Unidade Tributária"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="cest" control={control} label="CEST" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="complemento1" control={control} label="Complemento 1" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="complemento2" control={control} label="Complemento 2" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="localizador" control={control} label="Localizador" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="fci" control={control} label="FCI" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="movimentacao"
            control={control}
            label="Tipo de Movimentação"
            options={dropTipoMovimentacao}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="rastreavel"
            control={control}
            label="Rastreável"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="variacao"
            control={control}
            label="Variação"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="retornavel"
            control={control}
            label="Retornável"
            options={dropBoolean}
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={handleSubmit(onConfirm)}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Produto;
