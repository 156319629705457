const styles = {
  drawerPaper: {
    width: { xs: '100%', md: '75%', lg: '40%' },
    backgroundColor: 'primary.white',
    display: 'flex',
  },
  lineContainer: {
    display: 'flex',
    my: '1em',
  },
  lineLabel: {
    flex: 2,
    fontWeight: 'bold',
  },
  lineValue: {
    flex: 3,
  },
  title: {
    flexGrow: 1,
    ml: '.5em',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1em',
  },
  fullWidth: {
    width: '100%',
  },
  chip: {
    mr: '1em',
    mt: '1em',
  },
};

export default styles;
