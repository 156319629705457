import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  descricao: '',
  codigo: '',
};

export const SecaoCnaeContext = createContext();

export const SecaoCnaeProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [secaoCnae, setSecaoCnae] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getSecaoCnae = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: secaoCnae?.filter,
        page: secaoCnae?.page,
        size: secaoCnae?.size,
        order: secaoCnae?.order,
        ...payload,
      });
      setSecaoCnae((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <SecaoCnaeContext.Provider
      value={{
        getLoading,
        secaoCnae,
        getSecaoCnae,
      }}
    >
      {children}
    </SecaoCnaeContext.Provider>
  );
};
