import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from '../styles';

const InformaçõesComerciais = ({ indicadores }) =>
  Boolean(indicadores?.EntidadeRegs?.length) ? (
    indicadores?.EntidadeRegs?.map((r, i) => (
      <Box key={i?.toString()} sx={styles?.lineContainer}>
        <Typography variant="body2" sx={styles?.lineValue}>
          {r?.registro || '-'}
        </Typography>
      </Box>
    ))
  ) : (
    <Typography variant="body2" sx={styles?.lineValue} textAlign="center">
      Entidade não possui registros comerciais
    </Typography>
  );

export default InformaçõesComerciais;
