import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { EntidadesContext } from 'contexts/EntidadesContext';
import Container from 'components/Container';
import Button from 'components/Button';
import Header from 'components/Header';
import Loader from 'components/Loader';
import mock from './mock';
import styles from './styles';

const Permissões = () => {
  const titulo = 'Permissões';
  const navigate = useNavigate();
  const { state } = useLocation();
  const [permissions, setPermissions] = useState([]);
  const {
    getLoading,
    postLoading,
    getEntidadePermissoes,
    postEntidadePermissoes,
  } = useContext(EntidadesContext);

  useEffect(() => {
    if (Boolean(state?.id)) {
      getEntidadePermissoes({
        params: { id: state?.id },
        cb: (data) => setPermissions(data || []),
      });
    } else {
      navigate(-1);
    }
  }, []);

  const onSubmit = () => {
    postEntidadePermissoes({
      data: { permissions, entidade_id: state?.id },
      cb: () =>
        getEntidadePermissoes({
          params: { id: state?.id },
          cb: (data) => setPermissions(data || []),
        }),
    });
  };

  const renderPermissions = ({ modulo, perms = [] }) => {
    const handleChange = ({ value, checked }) => {
      if (checked) {
        setPermissions((prev) =>
          prev?.map((p) => {
            if (p?.modulo === modulo && p?.permissoes?.[value]) {
              const permissoes = { ...p?.permissoes };
              delete permissoes[value];
              return { ...p, permissoes };
            }
            return p;
          })
        );
      } else {
        setPermissions((prev) =>
          prev?.map((p) => {
            if (p?.modulo === modulo) {
              return { ...p, permissoes: { ...p?.permissoes, [value]: true } };
            }
            return p;
          })
        );
      }
    };
    return perms?.map(({ value, label }, i) => {
      const checked = permissions?.some(
        (p) => p?.modulo === modulo && p?.permissoes?.[value]
      );

      return (
        <Box key={value}>
          <Divider sx={{ display: i > 0 ? 'flex' : 'none' }} />
          <Box sx={styles?.permissionContainer}>
            <Typography sx={styles?.permissionsText} variant="body2">
              {label}
            </Typography>
            <Switch
              checked={checked}
              onChange={() => handleChange({ value, checked })}
            />
          </Box>
        </Box>
      );
    });
  };

  const renderModulos = () => {
    const handleChange = ({ value: modulo, expanded }) => {
      if (expanded) {
        setPermissions((prev) => prev?.filter((f) => f?.modulo !== modulo));
      } else {
        setPermissions((prev) => [...prev, { modulo, permissoes: null }]);
      }
    };

    return mock?.map(({ value, label, permissions: perms }) => {
      const expanded = permissions?.some((p) => p?.modulo === value);
      return (
        <Grid key={value} item xs={12}>
          <Accordion
            expanded={expanded}
            onChange={() => handleChange({ value, expanded })}
          >
            <AccordionSummary>
              <Box sx={styles?.accordionHeader}>
                <Typography variant="button">{label}</Typography>
                <Switch checked={expanded} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {renderPermissions({ modulo: value, perms })}
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });
  };

  if (getLoading) {
    return <Loader />;
  }
  return (
    <Container>
      <Header titulo={titulo} subtitulo={state?.nome} />
      <Grid container spacing={2}>
        {renderModulos()}
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Permissões;
