import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import Container from 'components/Container';

const Produção = ({ indicadores }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [tab, setTab] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetWidth / 2);
  }, []);

  const chart = indicadores?.indicadores?.fabricados;

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs variant="fullWidth" value={tab} onChange={(_, v) => setTab(v)}>
            <Tab value={0} label="Quantidade" />
            <Tab value={1} label="Valor" />
            <Tab value={2} label="Peso" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Box ref={ref}>
            {tab === 0 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#22267B',
                    label: 'Quantidade Produzida (Últimos 12 meses)',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.quantidade)
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
            {tab === 1 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#22267B',
                    label: 'Valor Produzido (Últimos 12 meses)',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.valor)
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
            {tab === 2 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#22267B',
                    label: 'Peso Produzido (Últimos 12 meses)',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.peso)
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Produção;
