const styles = {
  button: {
    margin: '1em 0',
  },
  cardCategory: {
    textAlign: 'left',
    flexGrow: 1,
  },
  baseButton: {
    width: '100%',
  },
};

export default styles;
