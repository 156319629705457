import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from '../styles';
import moment from 'moment';

const ÚltimaVenda = ({ indicadores }) => {
  const lineValue = indicadores?.Cliente?.ultima_venda;
  const lines = [
    {
      label: 'Data:',
      value: lineValue?.data && moment(lineValue?.data).format('DD/MM/YYYY'),
    },
    {
      label: 'Vendedor:',
      value: lineValue?.vendedor,
    },
    {
      label: 'Forma de Pagamento:',
      value: lineValue?.forma_pagto,
    },
    {
      label: 'Condição de Pagamento:',
      value: lineValue?.condicao_pagamento,
    },
    {
      label: 'Frete por Conta:',
      value: lineValue?.fretepc,
    },
    {
      label: 'Transportador:',
      value: lineValue?.transportador,
    },
  ];
  return lines?.map((l, i) => (
    <Box key={i?.toString()} sx={styles?.lineContainer}>
      <Typography variant="body2" sx={styles?.lineLabel}>
        {l?.label}
      </Typography>
      <Typography variant="body2" sx={styles?.lineValue}>
        {l?.value || '-'}
      </Typography>
    </Box>
  ));
};

export default ÚltimaVenda;
