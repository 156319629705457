import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { dropTipoDescricao } from 'utils/drops';
import { useDialog } from 'components/Modals';
import { ProdutosContext } from 'contexts/ProdutosContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import ButtonMenu from 'components/ButtonMenu';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Descrições = ({ onSubmit, onDelete, produto = {}, disableActions }) => {
  const { openDialog } = useDialog();
  const rotina = 'ProdutoDescricao';

  const options = [
    {
      name: 'Adicionar Descrição',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Descrição'),
    },
    {
      name: 'Adicionar Especificação',
      icon: 'add',
      action: () =>
        openDialog(<EspecificaçãoModal />, 'Adicionar Especificação'),
    },
  ];

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      produto_id: produto?.id,
      descricao: item?.descricao || '',
      tipo: item?.tipo || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(ProdutosContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Input
            name="descricao"
            control={control}
            label="Descrição do Produto"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="tipo"
            control={control}
            label="Tipo"
            options={dropTipoDescricao}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Descricoes: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const EspecificaçãoModal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      produto_id: produto?.id,
      descricao: item?.descricao || '',
      tipo: item?.tipo || 'ESPECIFICACAO',
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(ProdutosContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            name="descricao"
            control={control}
            label="Especificação do Produto"
            rows={20}
            multiline
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Descricoes: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Descrições" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined' }}
              options={options}
            />
          </Grid>
        )}
        {!!produto?.ProdutoDescricaos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição do Produto</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {produto?.ProdutoDescricaos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>
                        <Typography variant="body2" whiteSpace="pre-wrap">
                          {item?.descricao}
                        </Typography>
                      </TableCell>
                      <TableCell>{item?.tipo}</TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              item?.tipo === 'ESPECIFICACAO'
                                ? openDialog(
                                    <EspecificaçãoModal item={item} />,
                                    'Editar Especificação'
                                  )
                                : openDialog(
                                    <Modal item={item} />,
                                    'Editar Descrição'
                                  )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Descrições;
