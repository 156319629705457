import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useModal } from 'components/Modals';
import Button from 'components/Button';

const ResumoModal = ({ colunas, onSubmit }) => {
  const { closeModal } = useModal();
  const [selected, setSelected] = useState(null);

  return (
    <>
      <DialogTitle>Agrupar por...</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup onChange={(_, v) => setSelected(v)}>
            {colunas?.map((c) => {
              if (c?.visible) {
                return (
                  <FormControlLabel
                    key={c?.field}
                    value={c?.field}
                    label={c?.headerName}
                    control={<Radio />}
                  />
                );
              }
            })}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onSubmit(selected);
            closeModal();
          }}
        >
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default ResumoModal;
