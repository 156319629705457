import { createContext, useState } from 'react';
import api from 'services/api';
import moment from 'moment';

const filter = {
  like: '',
  id: '',
  ativo: 'SIM',
  nome: '',
  pessoa: [],
  documento: '',
  perfil: null,
  logradouro: '',
  localidade: [],
  sigla: [],
  telefone: '',
  email: '',
  atividade: [],
  fantasia: '',
  dtcad: { i: null, f: null },
  vendedor: [],
  regiao: [],
  codigo: '',
};

export const EntidadesContext = createContext();

export const EntidadesProvider = ({ children }) => {
  const [entidade, setEntidade] = useState(null);
  const [entidadeIndicadores, setEntidadeIndicadores] = useState(null);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [putLoading, setPutLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [entidades, setEntidades] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getEntidades = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: entidades?.filter,
        page: entidades?.page,
        size: entidades?.size,
        order: entidades?.order,
        ...payload,
      });
      setEntidades((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getEntidade = async (id) => {
    try {
      setGetLoading(true);
      setEntidade(null);
      const { data } = await api.get(`/Cadastros/Entidade/${id}`, {
        params: {
          Documentos: true,
          Contatos: true,
          Enderecos: true,
          Perfis: true,
          Fisica: true,
          Vinculos: true,
          Registros: true,
          Filial: true,
          Operadoras: true,
          Anexos: true,
          Aprovacoes: true,
        },
      });
      setEntidade(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postEntidade = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Entidade', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const putEntidadeFilial = async ({ data, cb }) => {
    try {
      setPutLoading(true);
      await api.put(`/Cadastros/Entidade/Filial`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPutLoading(false);
    }
  };

  const putEntidadeStatus = async ({ id, cb }) => {
    try {
      setPutLoading(true);
      await api.put(`/Cadastros/Entidade/Status/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPutLoading(false);
    }
  };

  const deleteEntidadePerfil = async ({ id, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Cadastros/Entidade/Perfil/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const postAnexo = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Entidade/Anexo', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteAnexo = async ({ id, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Cadastros/Entidade/Anexo/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const putEntidadeClientes = async ({ data, cb }) => {
    try {
      setPutLoading(true);
      await api.put(`/Cadastros/Entidade/Clientes`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPutLoading(false);
    }
  };

  const getEntidadeIndicadores = async (id) => {
    try {
      setGetLoading(true);
      setEntidadeIndicadores(null);
      const { data } = await api.get(
        `/Graficos/CadastrosEntidadeIndicadores/${id}`
      );
      setEntidadeIndicadores(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getEntidadePermissoes = async ({ params, cb }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Cadastros/Entidade/Permissoes`, {
        params,
      });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postEntidadePermissoes = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Cadastros/Entidade/Permissoes`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const checkEntidade = async (params) => {
    try {
      await api.get(`/Cadastros/Entidade/Check`, { params });
    } catch (error) {
    } finally {
    }
  };

  return (
    <EntidadesContext.Provider
      value={{
        postEntidade,
        postLoading,
        getLoading,
        getEntidade,
        getEntidades,
        entidade,
        entidades,
        putEntidadeStatus,
        putLoading,
        putEntidadeFilial,
        deleteLoading,
        deleteEntidadePerfil,
        postAnexo,
        deleteAnexo,
        putEntidadeClientes,
        getEntidadeIndicadores,
        entidadeIndicadores,
        getEntidadePermissoes,
        postEntidadePermissoes,
        checkEntidade,
      }}
    >
      {children}
    </EntidadesContext.Provider>
  );
};
