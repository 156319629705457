import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  localidade: '',
  sigla: [],
  cepbase: '',
  codigo_ibge: '',
  livrecom: null,
  codmunicipio: '',
};

export const CidadeContext = createContext();

export const CidadeProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [cidade, setCidade] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getCidade = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: cidade?.filter,
        page: cidade?.page,
        size: cidade?.size,
        order: cidade?.order,
        ...payload,
      });
      setCidade((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <CidadeContext.Provider
      value={{
        getLoading,
        cidade,
        getCidade,
      }}
    >
      {children}
    </CidadeContext.Provider>
  );
};
