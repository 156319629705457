import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';
import { QueryStats } from '@mui/icons-material';
import { EntidadesContext } from 'contexts/EntidadesContext';
import Container from 'components/Container';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Card from 'components/Card';
import InformaçõesComerciais from './Indices/InformaçõesComerciais';
import PendênciasDeVendas from './Indices/PendênciasDeVendas';
import PendênciasDeCompras from './Indices/PendênciasDeCompras';
import HistóricoDeVendas from './Indices/HistóricoDeVendas';
import HistóricoDeCompras from './Indices/HistóricoDeCompras';
import ÚltimaCompra from './Indices/ÚltimaCompra';
import ÚltimaVenda from './Indices/ÚltimaVenda';
import styles from './styles';
import OptionsDrawer from './Drawer';
import moment from 'moment';

const Indicadores = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  const { entidadeIndicadores, getLoading, getEntidadeIndicadores } =
    useContext(EntidadesContext);

  useEffect(() => {
    getEntidadeIndicadores(id);
  }, []);

  const options = [
    { value: 0, title: 'Informações Comerciais' },
    {
      title: 'Cliente',
      show: entidadeIndicadores?.tipos?.includes('CLIENTE'),
      childrens: [
        { value: 1, title: 'Última Venda' },
        { value: 2, title: 'Histórico de Vendas' },
        { value: 3, title: 'Pendências / Limites de Vendas' },
      ],
    },
    {
      title: 'Fornecedor',
      show: entidadeIndicadores?.tipos?.includes('FORNECEDOR'),
      childrens: [
        { value: 4, title: 'Última Compra' },
        { value: 5, title: 'Histórico de Compras' },
        { value: 6, title: 'Pendências de Compras' },
      ],
    },
  ];

  const RenderOption = () => {
    switch (tab) {
      case 0:
        return {
          title: 'Informações Comerciais',
          component: (
            <InformaçõesComerciais indicadores={entidadeIndicadores} />
          ),
        };
      case 1:
        return {
          title: 'Última Venda',
          component: <ÚltimaVenda indicadores={entidadeIndicadores} />,
        };
      case 2:
        return {
          title: 'Histórico de Vendas',
          component: <HistóricoDeVendas indicadores={entidadeIndicadores} />,
        };
      case 3:
        return {
          title: 'Pendências / Limites de Vendas',
          component: <PendênciasDeVendas indicadores={entidadeIndicadores} />,
        };
      case 4:
        return {
          title: 'Última Compra',
          component: <ÚltimaCompra indicadores={entidadeIndicadores} />,
        };
      case 5:
        return {
          title: 'Histórico de Compras',
          component: <HistóricoDeCompras indicadores={entidadeIndicadores} />,
        };
      case 6:
        return {
          title: 'Pendências de Compras',
          component: <PendênciasDeCompras indicadores={entidadeIndicadores} />,
        };
      default:
        return null;
    }
  };

  const handleDrawerToggle = () => setOpenDrawer(!openDrawer);

  const InfosEntidade = () => {
    const lines = [
      ...(entidadeIndicadores?.EntidadeDocumentos || [])?.map((d) => ({
        label: d?.TipoDocumento?.descricao,
        value: d?.documento,
      })),
      {
        label: 'Cidade:',
        value: entidadeIndicadores?.cidade,
      },
      {
        label: 'Data de Cadastro:',
        value:
          entidadeIndicadores?.dtcad &&
          moment(entidadeIndicadores?.dtcad).isValid() &&
          moment(entidadeIndicadores?.dtcad).format('DD/MM/YYYY'),
      },
    ];

    return (
      <Grid item xs={12} md={4}>
        <Card>
          {entidadeIndicadores?.bloqueado === 'SIM' && (
            <Chip label="BLOQUEADO" sx={styles?.fullWidth} color="secondary" />
          )}
          {lines?.map((l, i) => (
            <Box key={i?.toString()} sx={styles?.lineContainer}>
              <Typography variant="body2" sx={styles?.lineLabel}>
                {l?.label}
              </Typography>
              <Typography variant="body2" sx={styles?.lineValue}>
                {l?.value || '-'}
              </Typography>
            </Box>
          ))}
          {entidadeIndicadores?.tipos?.map((t, i) => (
            <Chip
              key={i?.toString}
              label={t}
              color="primary"
              variant="outlined"
              sx={styles?.chip}
            />
          ))}
        </Card>
      </Grid>
    );
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={entidadeIndicadores?.nome} />
      <OptionsDrawer
        options={options}
        open={openDrawer}
        onClose={handleDrawerToggle}
        onChange={(v) => {
          setTab(v);
          handleDrawerToggle();
        }}
      />
      <Grid container spacing={2}>
        <InfosEntidade />
        <Grid item xs={12} md={8}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" alignItems="center">
                <IconButton color="primary" onClick={handleDrawerToggle}>
                  <QueryStats color="primary" />
                </IconButton>
                <Typography variant="h6" sx={styles?.title}>
                  {RenderOption()?.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {RenderOption()?.component}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Indicadores;
