import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  descricao: '',
  formato_regex: '',
};

export const TipoDocumentoContext = createContext();

export const TipoDocumentoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getTipoDocumento = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: tipoDocumento?.filter,
        page: tipoDocumento?.page,
        size: tipoDocumento?.size,
        order: tipoDocumento?.order,
        ...payload,
      });
      setTipoDocumento((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <TipoDocumentoContext.Provider
      value={{
        getLoading,
        tipoDocumento,
        getTipoDocumento,
      }}
    >
      {children}
    </TipoDocumentoContext.Provider>
  );
};
