import React from 'react';
import Loader from 'components/Loader';
import Anúncio from './Anúncio';
import Detalhes from './Detalhes';
import Variações from './Variações';
import Imagens from './Imagens';

const Tabs = ({ tab, getLoading, ...props }) => {
  if (getLoading) {
    return <Loader />;
  }
  switch (tab) {
    case 0:
      return <Anúncio {...props} />;
    case 1:
      return <Detalhes {...props} />;
    case 2:
      return <Variações {...props} />;
    case 3:
      return <Imagens {...props} />;

    default:
      return null;
  }
};

export default Tabs;
