const styles = {
  image: {
    objectFit: 'cover',
    height: 200,
    width: '100%',
    borderRadius: '10px',
  },
  bar: {
    borderRadius: '10px 10px 0px 0px',
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  checkbox: {
    color: 'white',
    '&.Mui-checked': {
      color: 'white',
    },
  },
  categoryBox: {
    display: 'flex',
    alignItems: 'center',
  },
  category: {
    fontWeight: 'bold',
    py: '6px',
    ml: 1,
  },
};

export default styles;
