import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { SituacaoTributariaContext } from 'contexts/SituacaoTributariaContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';

const SituacaoTributaria = () => {
  const rotina = 'SituacaoTributaria';
  const titulo = 'Situação Tributária';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { deleteGrid, exportPdf, exportXls } = useContext(GridContext);
  const { situacaoTributaria, getSituacaoTributaria, getLoading } = useContext(
    SituacaoTributariaContext
  );
  const { control, getValues, reset } = useForm({
    defaultValues: situacaoTributaria?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getSituacaoTributaria({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      action: () => navigate('/app/Tabelas/SituacaoTributaria/Manutencao'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: situacaoTributaria?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: situacaoTributaria?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={situacaoTributaria?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
  ];
  const rowOptions = [
    {
      name: 'Editar',
      icon: 'edit',
      action: ({ row: values }) =>
        navigate('/app/Tabelas/SituacaoTributaria/Manutencao', {
          state: { values },
        }),
    },
    {
      name: 'Deletar',
      icon: 'delete',
      action: ({ row }) =>
        openModal(
          <DeleteModal
            onSubmit={() =>
              deleteGrid({
                params: { rotina, id: row?.id },
                cb: () => {
                  loadGrid();
                  closeModal();
                },
              })
            }
          />
        ),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={situacaoTributaria?.colunas}
      />
      <Card>
        <Grid
          grid={situacaoTributaria}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={situacaoTributaria} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default SituacaoTributaria;
