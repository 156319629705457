import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormaDePagamentoContext } from 'contexts/FormaDePagamentoContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal, useDialog } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import CondicoesModal from './Modals/CondicoesModal';

const FormaDePagamento = () => {
  const rotina = 'FormaDePagamento';
  const titulo = 'Formas de Pagamento';
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { deleteGrid, exportPdf, exportXls } = useContext(GridContext);
  const { formaDePagamento, getFormaDePagamento, getLoading } = useContext(
    FormaDePagamentoContext
  );
  const { control, getValues, reset } = useForm({
    defaultValues: formaDePagamento?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getFormaDePagamento({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      action: () => navigate('/app/Tabelas/FormasDePagamento/Manutencao'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: formaDePagamento?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: formaDePagamento?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={formaDePagamento?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
  ];
  const rowOptions = [
    {
      name: 'Editar',
      icon: 'edit',
      action: ({ row: values }) =>
        navigate('/app/Tabelas/FormasDePagamento/Manutencao', {
          state: { values },
        }),
    },
    {
      name: 'Deletar',
      icon: 'delete',
      action: ({ row }) =>
        openModal(
          <DeleteModal
            onSubmit={() =>
              deleteGrid({
                params: { rotina, id: row?.id },
                cb: () => {
                  loadGrid();
                  closeModal();
                },
              })
            }
          />
        ),
    },
    {
      name: 'Condições de Pagamento',
      icon: 'currency_exchange',
      menu: true,
      action: ({ row }) =>
        openDialog(
          <CondicoesModal item={row} />,
          'Vínculos entre Formas e Condições'
        ),
    },
    {
      name: 'Boleto',
      icon: 'request_page',
      menu: true,
      action: ({ row }) =>
        navigate('/app/Tabelas/FormasDePagamento/Boleto', { state: row }),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={formaDePagamento?.colunas}
      />
      <Card>
        <Grid
          grid={formaDePagamento}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={formaDePagamento} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default FormaDePagamento;
