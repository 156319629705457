import React from 'react';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';

const App = () => {
  const titulo = 'Dashboard';

  return (
    <Container>
      <Header titulo={titulo} />
      {/* <Card></Card> */}
    </Container>
  );
};

export default App;
