import React from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';
import Card from 'components/Card';
import styles from '../styles';
import moment from 'moment';

const FormaçãoDoCusto = ({ indicadores }) => {
  const custo = indicadores?.indicadores?.formacaoCusto;

  const header = [
    {
      label: 'Data de Entrada:',
      value:
        Boolean(custo?.dtentrada) &&
        moment(custo?.dtentrada).isValid() &&
        moment(custo?.dtentrada).format('DD/MM/YYYY'),
    },
    {
      label: 'Fornecedor:',
      value: custo?.fornecedor,
    },
    {
      label: 'Origem do Produto:',
      value: custo?.origem,
    },
    {
      label: 'Moeda:',
      value: custo?.moeda,
    },
    {
      label: 'Cotação da Moeda na Compra:',
      value:
        custo?.cotacao_compra &&
        custo?.cotacao_compra?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      label: 'Cotação da Moeda Atual:',
      value:
        custo?.cotacao_atual &&
        custo?.cotacao_atual?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
  ];

  const lines = [
    {
      label: 'Preço:',
      value: (custo?.custo || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Custo Médio:',
      value: (custo?.medio || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      block: true,
    },
    {
      label: 'Desconto:',
      value: (custo?.desconto_vlr || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Comissão de Venda:',
      value: (custo?.comissao_vlr || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Adicional (Vínculo):',
      value: (custo?.adicional || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Frete:',
      value: (custo?.frete || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Seguro:',
      value: (custo?.seguro || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Despesas Acessórias:',
      value: (custo?.despac || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Custo Financeiro:',
      value: (custo?.cstfin || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Eventuais:',
      value: (custo?.eventuais || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Outros:',
      value: (custo?.outros || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Internacionalização:',
      value: (custo?.internacional || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Frete (Não Destacado):',
      value: (custo?.fretend || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Comissão (Não Destacada):',
      value: (custo?.comissaond || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Processo de Fabricação:',
      value: (custo?.processo || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      show: custo?.origem === 'FABRICADO',
    },
    {
      label: 'ICMS:',
      value: (custo?.icms || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      block: !Boolean(custo?.icms_calc),
      hint: 'Será somado ao custo se for uma importação. Será subtraido do custo se estiver configurado no sistema ou se a empresa não for optante pelo simples nacional e tiver direito ao crédito do imposto.',
    },
    {
      label: 'ICMS ST:',
      value: (custo?.icms_st || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      block: !Boolean(custo?.icms_st_calc),
      hint: 'Será somado ao custo se estiver configurado no sistema ou se a empresa não tiver direito ao crédito do imposto.',
    },
    {
      label: 'Diferença de ICMS ST:',
      value: (custo?.diferenca_icms_st || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      block: !Boolean(custo?.diferenca_icms_st_calc),
      hint: 'Será somado ao custo se estiver configurado no sistema.',
    },
    {
      label: 'IPI:',
      value: (custo?.ipi || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      block: !Boolean(custo?.ipi_calc),
      hint: 'Será somado ao custo se estiver configurado no sistema ou se a empresa não tiver direito ao crédito do imposto.',
    },
    {
      label: 'PIS:',
      value: (custo?.pis || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      block: !Boolean(custo?.pis_calc),
      hint: 'Será subtraido do custo se estiver configurado no sistema ou se o regime tributário da empresa for LUCRO REAL e a empresa tiver direito ao crédito do imposto.',
    },
    {
      label: 'COFINS:',
      value: (custo?.cofins || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      block: !Boolean(custo?.cofins_calc),
      hint: 'Será subtraido do custo se estiver configurado no sistema ou se o regime tributário da empresa for LUCRO REAL e a empresa tiver direito ao crédito do imposto.',
    },
    {
      label: 'Impostos Federais:',
      value: (custo?.impostos_federais || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      block: !Boolean(custo?.impostos_federais_calc),
      hint: 'Será subtraido do custo se estiver configurado no sistema ou se o regime tributário da empresa for LUCRO REAL e a empresa tiver direito ao crédito do imposto.',
    },
  ];

  const getCusto = () => {
    let total =
      (custo?.custo || 0) +
      (custo?.desconto_vlr || 0) +
      (custo?.comissao_vlr || 0) +
      (custo?.adicional || 0) +
      (custo?.frete || 0) +
      (custo?.seguro || 0) +
      (custo?.despac || 0) +
      (custo?.cstfin || 0) +
      (custo?.eventuais || 0) +
      (custo?.outros || 0) +
      (custo?.internacional || 0) +
      (custo?.fretend || 0) +
      (custo?.comissaond || 0) +
      (custo?.processo || 0);
    if (Boolean(custo?.icms_st_calc)) {
      total = total + (custo?.icms_st || 0);
    }
    if (Boolean(custo?.icms_calc)) {
      total = total + (custo?.icms || 0);
    }
    if (Boolean(custo?.ipi_calc)) {
      total = total + (custo?.ipi || 0);
    }
    if (Boolean(custo?.impostos_federais_calc)) {
      total = total + (custo?.impostos_federais || 0);
    }
    if (Boolean(custo?.pis_calc)) {
      total = total + (custo?.pis || 0);
    }
    if (Boolean(custo?.cofins_calc)) {
      total = total + (custo?.cofins || 0);
    }
    if (Boolean(custo?.diferenca_icms_st_calc)) {
      total = total + (custo?.diferenca_icms_st || 0);
    }
    if (custo?.cotacao_atual && custo?.cotacao_compra) {
      total = (total * custo?.cotacao_compra) / custo?.cotacao_atual;
    }
    return total;
  };

  if (!custo) {
    return (
      <Typography variant="body1" textAlign="center">
        Produto sem informações de custo
      </Typography>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          {header?.map((l, i) => (
            <Box key={i?.toString()} sx={styles?.lineContainer}>
              <Typography variant="body2" sx={styles?.lineLabel}>
                {l?.label}
              </Typography>
              <Typography variant="body2" sx={styles?.lineValue}>
                {l?.value || '-'}
              </Typography>
            </Box>
          ))}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" color="secondary" textAlign="center">
          * Valores não considerados na formação do custo.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {lines?.map(
          ({ show = true, ...l }, i) =>
            show && (
              <Box key={i?.toString()} sx={styles?.lineContainer}>
                <Typography
                  variant="body2"
                  sx={styles?.lineLabel}
                  color={l?.block && 'secondary'}
                >
                  {l?.label}
                </Typography>
                <Typography
                  variant="body2"
                  sx={styles?.lineValue}
                  color={l?.block && 'secondary'}
                >
                  {l?.value || '-'}
                </Typography>
                {Boolean(l?.hint) && (
                  <Tooltip title={l?.hint}>
                    <Info fontSize="small" color="disabled" />
                  </Tooltip>
                )}
              </Box>
            )
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary" textAlign="center">
          Total:{' '}
          {getCusto()?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FormaçãoDoCusto;
