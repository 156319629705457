import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from '../styles';
import moment from 'moment';

const PendênciasDeCompras = ({ indicadores }) => {
  const lineValue = indicadores?.Fornecedor?.pendenciasLimites;
  const lines = [
    {
      label: 'Última Compra:',
      value:
        lineValue?.ultima_compra &&
        `${moment()
          .subtract(lineValue?.ultima_compra, 'days')
          .format('DD/MM/YYYY')} (${lineValue?.ultima_compra} Dias)`,
    },
    {
      label: 'Valor Pendente:',
      value: lineValue?.valor_pendente?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Valor em Atraso:',
      value: lineValue?.atraso_valor?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Dias em Atraso:',
      value: lineValue?.atraso_dias && `${lineValue?.atraso_dias} Dias`,
    },
    {
      label: 'Quantidade em Atraso:',
      value: lineValue?.atraso_qtd,
    },
  ];
  return lines?.map((l, i) => (
    <Box key={i?.toString()} sx={styles?.lineContainer}>
      <Typography variant="body2" sx={styles?.lineLabel}>
        {l?.label}
      </Typography>
      <Typography variant="body2" sx={styles?.lineValue}>
        {l?.value || '-'}
      </Typography>
    </Box>
  ));
};

export default PendênciasDeCompras;
