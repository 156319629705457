import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { findOnArray } from 'utils/functions';
import { dropEstadoCivil, dropSexo } from 'utils/drops';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import styles from '../styles';

const Física = ({
  drops,
  loading,
  onSubmit,
  entidade = {},
  disableActions,
}) => {
  const defaultValues = {
    entidade_id: entidade?.id,
    dtnasc: entidade?.EntidadeFisica?.dtnasc || null,
    estado_civil: entidade?.EntidadeFisica?.estado_civil || null,
    sexo: entidade?.EntidadeFisica?.sexo || null,
    uf_id: entidade?.EntidadeFisica?.Cidade?.uf_id || null,
    nascimento_id: entidade?.EntidadeFisica?.nascimento_id || null,
    loctrabalho: entidade?.EntidadeFisica?.loctrabalho || '',
  };
  const { control, handleSubmit, setValue, watch } = useForm({ defaultValues });

  return (
    <Card title="Informações para Pessoa Física" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="dtnasc"
            control={control}
            label="Data de Nascimento"
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="estado_civil"
            control={control}
            label="Estado Civil"
            options={dropEstadoCivil}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="sexo"
            control={control}
            label="Sexo"
            options={dropSexo}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="uf_id"
            control={control}
            label="Estado de Nascimento"
            options={drops?.UF}
            onValueChange={() => setValue('nascimento_id', null)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="nascimento_id"
            control={control}
            label="Cidade de Nascimento"
            options={drops?.Cidade?.filter((c) => {
              const estado = findOnArray(watch('uf_id'), drops?.UF);
              return estado?.sigla === c?.sigla;
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="loctrabalho"
            control={control}
            label="Local de Trabalho"
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={handleSubmit((Fisica) => onSubmit({ Fisica }))}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Física;
