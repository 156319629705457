import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  dropBoolean,
  dropNTributado,
  dropOperacaoTd,
  dropBaseTeto,
} from 'utils/drops';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';

const Manutenção = () => {
  const rotina = 'SituacaoTributaria';
  const defaultValues = {
    tributo_id: null,
    cst: '',
    descricao: '',
    ntributado: null,
    operacaotd: null,
    cstemitente_id: null,
    cstteto_id: null,
    vlrteto: '',
    baseteto: null,
    obrigabc: null,
    obrigapc: null,
    obrigavl: null,
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getDrops, drops } = useContext(DropsContext);
  const { postLoading, postGrid } = useContext(GridContext);
  const { control, handleSubmit, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(state?.values);
  }, []);

  const onSubmit = (data) =>
    postGrid({
      data: { data, rotina },
      cb: () => {
        navigate(-1);
        getDrops(['SituacaoTributaria']);
      },
    });

  return (
    <Container>
      <Header
        titulo={
          (Boolean(state) ? 'Editar' : 'Cadastrar') + ' Situação Tributária'
        }
      />

      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="tributo_id"
              control={control}
              label="Tributo"
              options={drops?.Tributo}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="cst" control={control} label="CST" />
          </Grid>
          <Grid item xs={12}>
            <Input name="descricao" control={control} label="Descrição" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="ntributado"
              control={control}
              label="Não Tributado"
              options={dropNTributado}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="operacaotd"
              control={control}
              label="Operação para Totalizar"
              options={dropOperacaoTd}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="cstemitente_id"
              control={control}
              label="Situação Tributária do Emitente"
              options={drops?.SituacaoTributaria}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="cstteto_id"
              control={control}
              label="Teto da Situação Tributária"
              options={drops?.SituacaoTributaria}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask name="vlrteto" control={control} label="Valor do Teto" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="baseteto"
              control={control}
              label="Base de Cálculo do Teto"
              options={dropBaseTeto}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="obrigabc"
              control={control}
              label="Base de Cálculo do Tributo Obrigatória"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="obrigapc"
              control={control}
              label="Percentual do Tributo Obrigatório"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="obrigavl"
              control={control}
              label="Valor do Tributo Obrigatório"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Manutenção;
