import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  descricao: '',
  modulo: [],
  ativo: null,
};

export const FormaDePagamentoContext = createContext();

export const FormaDePagamentoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [boleto, setBoleto] = useState(null);
  const [formaDePagamento, setFormaDePagamento] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getFormaDePagamento = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: formaDePagamento?.filter,
        page: formaDePagamento?.page,
        size: formaDePagamento?.size,
        order: formaDePagamento?.order,
        ...payload,
      });
      setFormaDePagamento((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getFormaCondicoes = async ({ id, cb }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Financeiro/Formas/${id}`);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getBoletoConfig = async ({ id }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Financeiro/Formas/BoletoConfig/${id}`);
      setBoleto(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <FormaDePagamentoContext.Provider
      value={{
        boleto,
        getLoading,
        formaDePagamento,
        getFormaDePagamento,
        getFormaCondicoes,
        getBoletoConfig,
      }}
    >
      {children}
    </FormaDePagamentoContext.Provider>
  );
};
