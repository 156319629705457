import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  frota: '',
  descricao: '',
  placa: '',
  marca: '',
  modelo: '',
  anofab: '',
  anoveic: '',
};

export const FrotaContext = createContext();

export const FrotaProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [frota, setFrota] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getFrota = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: frota?.filter,
        page: frota?.page,
        size: frota?.size,
        order: frota?.order,
        ...payload,
      });
      setFrota((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <FrotaContext.Provider
      value={{
        getLoading,
        frota,
        getFrota,
      }}
    >
      {children}
    </FrotaContext.Provider>
  );
};
