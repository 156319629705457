import React from 'react';
import { Grid } from '@mui/material';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import { dropStatusShopee } from 'utils/drops';

const Filter = ({ grid, control }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('status') && (
        <Grid item xs={12}>
          <Dropdown
            name="status"
            control={control}
            label="Status"
            options={dropStatusShopee}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('titulo') && (
        <Grid item xs={12}>
          <Input name="titulo" control={control} label="Título" />
        </Grid>
      )}
      {checkDisplay('estoque') && (
        <Grid item xs={12}>
          <InputMask name="estoque" control={control} label="Estoque" />
        </Grid>
      )}
      {checkDisplay('sku') && (
        <Grid item xs={12}>
          <Input name="sku" control={control} label="SKU" />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Produto" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
