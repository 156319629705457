const styles = {
  card: {
    marginTop: '1em',
  },
  cardContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    flexGrow: 1,
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '150px',
  },
  icon: {
    color: 'primary.main',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid gray',
    pt: '.5em',
    mt: '1em',
  },
  text: {
    flexGrow: 1,
  },
};

export default styles;
