import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  conta: '',
  descricao: '',
  tipo: [],
  ativo: null,
};

export const ContasContext = createContext();

export const ContasProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [contas, setContas] = useState({
    data: [],
    colunas: [],
    order: { field: 'conta', sort: 'asc' },
    filter,
    page: 0,
    size: 10,
  });

  const getContas = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: contas?.filter,
        page: contas?.page,
        size: contas?.size,
        order: contas?.order,
        ...payload,
      });
      setContas((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <ContasContext.Provider
      value={{
        getLoading,
        getContas,
        contas,
      }}
    >
      {children}
    </ContasContext.Provider>
  );
};
