import React from 'react';
import { Grid } from '@mui/material';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('ncm') && (
        <Grid item xs={12}>
          <Input name="ncm" control={control} label="NCM" type="number" />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('produto_codigo') && (
        <Grid item xs={12}>
          <Input
            name="produto_codigo"
            control={control}
            label="Código do Produto"
          />
        </Grid>
      )}
      {checkDisplay('produto_descricao') && (
        <Grid item xs={12}>
          <Input
            name="produto_descricao"
            control={control}
            label="Descrição do Produto"
          />
        </Grid>
      )}
      {checkDisplay('utributaria_id') && (
        <Grid item xs={12}>
          <Dropdown
            multiple
            name="utributaria_id"
            control={control}
            label="Unidade de Medida"
            options={drops?.UnidadeMedida}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
