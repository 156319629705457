import React from 'react';
import { Grid } from '@mui/material';
import { dropStatus } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';

const Filter = ({ grid, drops, control }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('status') && (
        <Grid item xs={12}>
          <Dropdown
            name="status"
            control={control}
            label="Status"
            options={dropStatus}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('produto') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="produto"
            control={control}
            label="Produto"
            options={drops?.Produto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('linha') && (
        <Grid item xs={12}>
          <Dropdown
            name="linha"
            control={control}
            label="Linha"
            options={drops?.LinhaProduto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('grupo') && (
        <Grid item xs={12}>
          <Dropdown
            name="grupo"
            control={control}
            label="Grupo"
            options={drops?.GrupoProduto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('cliente') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="cliente"
            control={control}
            label="Cliente"
            options={drops?.Entidade}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('vendedor') && (
        <Grid item xs={12}>
          <Dropdown
            name="vendedor"
            control={control}
            label="Vendedor"
            options={drops?.EntidadeTipo?.filter(
              (t) => t?.tipo === 'VENDEDOR' || t?.tipo === 'REPRESENTANTE'
            )}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('grupo_entidade') && (
        <Grid item xs={12}>
          <Dropdown
            name="grupo_entidade"
            control={control}
            label="Grupo da Entidade"
            options={drops?.GrupoEntidade}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('condicao_pagamento') && (
        <Grid item xs={12}>
          <Dropdown
            name="condicao_pagamento"
            control={control}
            label="Condição de Pagamento"
            options={drops?.CondicaoPagamento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('tabpreco') && (
        <Grid item xs={12}>
          <Dropdown
            name="tabpreco"
            control={control}
            label="Tabela de Preço"
            options={drops?.TabelaPreco}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('preco') && (
        <Grid item xs={12}>
          <InputMask name="preco" control={control} label="Preço" />
        </Grid>
      )}
      {checkDisplay('margem') && (
        <Grid item xs={12}>
          <InputMask name="margem" control={control} label="Margem" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
