import React, { useState } from 'react';
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  Close,
  ExpandLess,
  ExpandMore,
  Insights,
  Menu,
} from '@mui/icons-material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styles from './styles';

const OptionsDrawer = ({ open, onClose, options = [], onChange }) => {
  const RenderItem = ({ item }) => {
    const [open, setOpen] = useState(false);

    const RenderChild = ({ child }) => (
      <ListItemButton onClick={() => onChange(child?.value)} sx={{ pl: 4 }}>
        <ListItemIcon>
          <Insights fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={child?.title} />
      </ListItemButton>
    );

    if (item?.childrens && item?.childrens?.length) {
      return (
        <Box>
          <ListItemButton onClick={() => setOpen(!open)}>
            <ListItemIcon>
              <Menu fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item?.title} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List disablePadding>
              {item?.childrens?.map((child, i) => (
                <RenderChild child={child} key={i?.toString()} />
              ))}
            </List>
          </Collapse>
        </Box>
      );
    }
    return (
      <ListItemButton onClick={() => onChange(item?.value)}>
        <ListItemIcon>
          <Insights fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={item?.title} />
      </ListItemButton>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      variant="temporary"
      ModalProps={{ keepMounted: true }}
      PaperProps={{ sx: styles?.drawerPaper }}
    >
      <Scrollbars>
        <Box sx={styles?.box}>
          <Typography variant="h5" component="h1">
            Indicadores da Entidade
          </Typography>
          <IconButton size="small" aria-label="Fechar" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <List>
          {options?.map(
            ({ show = true, ...item }, i) =>
              show && <RenderItem item={item} key={i?.toString()} />
          )}
        </List>
      </Scrollbars>
    </Drawer>
  );
};

export default OptionsDrawer;
