import React from 'react';
import { Grid } from '@mui/material';
import { dropAtivo, dropTipoPessoa, dropTipoPerfil } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('ativo') && (
        <Grid item xs={12}>
          <Dropdown
            name="ativo"
            control={control}
            label="Ativo"
            options={dropAtivo}
          />
        </Grid>
      )}
      {checkDisplay('nome') && (
        <Grid item xs={12}>
          <Input name="nome" control={control} label="Nome" />
        </Grid>
      )}
      {checkDisplay('pessoa') && (
        <Grid item xs={12}>
          <Dropdown
            name="pessoa"
            control={control}
            label="Pessoa"
            options={dropTipoPessoa}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={12}>
          <Input name="documento" control={control} label="Nº do Documento" />
        </Grid>
      )}
      {checkDisplay('perfil') && (
        <Grid item xs={12}>
          <Dropdown
            name="perfil"
            control={control}
            label="Perfil"
            options={dropTipoPerfil}
          />
        </Grid>
      )}
      {checkDisplay('logradouro') && (
        <Grid item xs={12}>
          <Input name="logradouro" control={control} label="Endereço" />
        </Grid>
      )}
      {checkDisplay('localidade') && (
        <Grid item xs={12}>
          <Dropdown
            name="localidade"
            control={control}
            label="Cidade"
            options={drops?.Cidade}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('sigla') && (
        <Grid item xs={12}>
          <Dropdown
            name="sigla"
            control={control}
            label="UF"
            options={drops?.UF}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('telefone') && (
        <Grid item xs={12}>
          <Input name="telefone" control={control} label="Telefone" />
        </Grid>
      )}
      {checkDisplay('email') && (
        <Grid item xs={12}>
          <Input name="email" control={control} label="E-mail" />
        </Grid>
      )}
      {checkDisplay('atividade') && (
        <Grid item xs={12}>
          <Dropdown
            name="atividade"
            control={control}
            label="Atividade"
            options={drops?.AtividadeEntidade}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('fantasia') && (
        <Grid item xs={12}>
          <Input name="fantasia" control={control} label="Fantasia" />
        </Grid>
      )}
      {checkDisplay('dtcad') && (
        <Grid item xs={6}>
          <InputMask
            name="dtcad.i"
            control={control}
            label="Data de Cadastro Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtcad') && (
        <Grid item xs={6}>
          <InputMask
            name="dtcad.f"
            control={control}
            label="Data de Cadastro Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('vendedor') && (
        <Grid item xs={12}>
          <Dropdown
            name="vendedor"
            control={control}
            label="Vendedor"
            options={drops?.EntidadeTipo?.filter(
              (t) => t?.tipo === 'VENDEDOR' || t?.tipo === 'REPRESENTANTE'
            )}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('regiao') && (
        <Grid item xs={12}>
          <Dropdown
            name="regiao"
            control={control}
            label="Região"
            options={drops?.RegiaoEntidade}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('codigo') && (
        <Grid item xs={12}>
          <Input name="codigo" control={control} label="Código" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
