import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  ncm: '',
  descricao: '',
  produto_codigo: '',
  produto_descricao: '',
  utributaria_id: [],
};

export const CfiscalContext = createContext();

export const CfiscalProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [cfiscal, setCfiscal] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getCfiscal = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: cfiscal?.filter,
        page: cfiscal?.page,
        size: cfiscal?.size,
        order: cfiscal?.order,
        ...payload,
      });
      setCfiscal((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <CfiscalContext.Provider
      value={{
        getLoading,
        cfiscal,
        getCfiscal,
      }}
    >
      {children}
    </CfiscalContext.Provider>
  );
};
