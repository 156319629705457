import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { DataGrid } from '@mui/x-data-grid';
import Container from 'components/Container';
import styles from '../styles';
import moment from 'moment';

const HistoricoDeCompras = ({ indicadores }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [tab, setTab] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetWidth / 3);
  }, []);

  const historico_compras = indicadores?.Fornecedor?.compras?.historico;
  const historico_valores = indicadores?.Fornecedor?.valores?.historico;
  const historico_prazos = indicadores?.Fornecedor?.prazos?.historico;
  const rows = indicadores?.Fornecedor?.detalheCompras || [];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs variant="fullWidth" value={tab} onChange={(_, v) => setTab(v)}>
            <Tab value={0} label="Quantidade" />
            <Tab value={1} label="Valor" />
            <Tab value={2} label="Prazo" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Box ref={ref}>
            {tab === 0 && (
              <Box>
                <BarChart
                  xAxis={[
                    {
                      data: Boolean(historico_compras?.length)
                        ? historico_compras?.map((g) => g?.label)
                        : [''],
                      scaleType: 'band',
                    },
                  ]}
                  series={[
                    {
                      color: '#22267B',
                      label: 'Quantidade de Compras (Últimos 12 meses)',
                      data: Boolean(historico_compras?.length)
                        ? historico_compras?.map((g) => g?.value)
                        : [0],
                    },
                  ]}
                  width={width}
                  height={height}
                />
                <Box sx={styles?.lineContainer}>
                  <Typography variant="body2" sx={styles?.lineLabel}>
                    Total de Compras:
                  </Typography>
                  <Typography variant="body2" sx={styles?.lineValue}>
                    {indicadores?.Fornecedor?.compras?.resumo?.quantidade_total}
                  </Typography>
                </Box>
                <Box sx={styles?.lineContainer}>
                  <Typography variant="body2" sx={styles?.lineLabel}>
                    Ciclo Médio:
                  </Typography>
                  <Typography variant="body2" sx={styles?.lineValue}>
                    {indicadores?.Fornecedor?.compras?.resumo?.ciclo_medio
                      ? `${indicadores?.Fornecedor?.compras?.resumo?.ciclo_medio?.toLocaleString(
                          'pt-br',
                          { maximumFractionDigits: 2 }
                        )} Dias`
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            )}
            {tab === 1 && (
              <Box>
                <LineChart
                  xAxis={[
                    {
                      scaleType: 'point',
                      valueFormatter: (v) =>
                        Boolean(historico_valores?.length)
                          ? moment(historico_valores[v - 1]?.dtemissao).format(
                              'DD/MM/YY'
                            )
                          : [0],
                      data: Boolean(historico_valores?.length)
                        ? historico_valores?.map((_, i) => i + 1)
                        : [0],
                    },
                  ]}
                  series={[
                    {
                      curve: 'linear',
                      color: '#22267B',
                      label: 'Valor em R$ (Últimas 10 Compras)',
                      data: Boolean(historico_valores?.length)
                        ? historico_valores?.map((g) => g?.valor)
                        : [0],
                    },
                  ]}
                  width={width}
                  height={height}
                />
                <Box sx={styles?.lineContainer}>
                  <Typography variant="body2" sx={styles?.lineLabel}>
                    Valor Total:
                  </Typography>
                  <Typography variant="body2" sx={styles?.lineValue}>
                    {indicadores?.Fornecedor?.valores?.resumo?.valor_total?.toLocaleString(
                      'pt-br',
                      { style: 'currency', currency: 'BRL' }
                    )}
                  </Typography>
                </Box>
                <Box sx={styles?.lineContainer}>
                  <Typography variant="body2" sx={styles?.lineLabel}>
                    Valor Médio:
                  </Typography>
                  <Typography variant="body2" sx={styles?.lineValue}>
                    {indicadores?.Fornecedor?.valores?.resumo?.valor_medio?.toLocaleString(
                      'pt-br',
                      { style: 'currency', currency: 'BRL' }
                    )}
                  </Typography>
                </Box>
              </Box>
            )}
            {tab === 2 && (
              <Box>
                <LineChart
                  xAxis={[
                    {
                      scaleType: 'point',
                      valueFormatter: (v) =>
                        Boolean(historico_prazos?.length)
                          ? moment(historico_prazos[v - 1]?.dtemissao).format(
                              'DD/MM/YY'
                            )
                          : [0],
                      data: Boolean(historico_prazos?.length)
                        ? historico_prazos?.map((_, i) => i + 1)
                        : [0],
                    },
                  ]}
                  series={[
                    {
                      curve: 'linear',
                      color: '#22267B',
                      label: 'Prazo de Pagamento em Dias (Últimas 10 Compras)',
                      data: Boolean(historico_prazos?.length)
                        ? historico_prazos?.map((g) => g?.prazo_pagamento)
                        : [0],
                    },
                  ]}
                  width={width}
                  height={height}
                />
                <Box sx={styles?.lineContainer}>
                  <Typography variant="body2" sx={styles?.lineLabel}>
                    Prazo Médio:
                  </Typography>
                  <Typography variant="body2" sx={styles?.lineValue}>
                    {indicadores?.Fornecedor?.prazos?.resumo?.prazo_medio
                      ? `${indicadores?.Fornecedor?.prazos?.resumo?.prazo_medio?.toLocaleString(
                          'pt-br',
                          { maximumFractionDigits: 2 }
                        )} Dias`
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            columns={[
              {
                field: 'dtemissao',
                headerName: 'Data de Emissão',
                width: 200,
                sortable: false,
                valueGetter: ({ value }) =>
                  value && moment(value).format('DD/MM/YYYY'),
              },
              {
                field: 'documento',
                headerName: 'Documento',
                width: 150,
                sortable: false,
              },
              {
                field: 'referencia',
                headerName: 'Referência',
                width: 150,
                sortable: false,
              },
              {
                field: 'descricao',
                headerName: 'Produto',
                width: 400,
                sortable: false,
              },
              {
                field: 'quantidade',
                headerName: 'Quantidade',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ row }) =>
                  row?.quantidade && row?.unidade_medida
                    ? `${row?.quantidade} ${row?.unidade_medida}`
                    : row?.quantidade || 0,
              },
              {
                field: 'preco',
                headerName: 'Preço Unitário',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ value }) =>
                  value &&
                  value?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
              {
                field: 'desconto1_pc',
                headerName: '% Desconto',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ value }) => value && value + '%',
              },
              {
                field: 'desconto_vlr',
                headerName: 'Valor Desconto',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ value }) =>
                  value &&
                  value?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
              {
                field: 'subtotal',
                headerName: 'Valor Total',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ row }) =>
                  (
                    (row?.preco || 0) * (row?.quantidade || 0) +
                    (row?.desconto_vlr || 0)
                  )?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
              {
                field: 'saldo',
                headerName: 'Saldo',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ row }) =>
                  row?.saldo && row?.unidade_medida
                    ? `${row?.saldo} ${row?.unidade_medida}`
                    : row?.saldo || 0,
              },
            ]}
            hideFooter
            autoHeight
            density="compact"
            disableSelectionOnClick
            disableColumnMenu
            showCellRightBorder
            showColumnRightBorder
            localeText={{ noRowsLabel: 'Nenhum Registro.' }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HistoricoDeCompras;
