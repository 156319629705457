import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Add, Create, Delete, Refresh, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import LogoBling from 'assets/bling.svg';
import LogoMercadoLivre from 'assets/mercado_livre.svg';
import LogoShopee from 'assets/shopee.svg';
import LogoMagalu from 'assets/magalu.svg';
import LogoWoo from 'assets/woo.svg';
import LogoTray from 'assets/tray.svg';
import ModalBling from './Modals/Bling';
import ModalMercadoLivre from './Modals/MercadoLivre';
import ModalShopee from './Modals/Shopee';
import ModalMagalu from './Modals/Magalu';
import ModalWoo from './Modals/Woo';
import ModalTray from './Modals/Tray';
import styles from './styles';
import moment from 'moment';

const Integrações = ({
  produto = {},
  disableActions,
  onSubmitIntegracao,
  onDeleteIntegracao,
}) => {
  const navigate = useNavigate();
  const { openDialog } = useDialog();

  const Bling = () => {
    const subtitle = Boolean(produto?.ProdutoBlings?.length)
      ? `${produto?.ProdutoBlings?.length} Produto(s) publicado(s)`
      : 'Não publicado';

    return (
      <Grid item xs={12} sm={6} md={3} display="flex">
        <Card style={styles?.cardContainer}>
          <img src={LogoBling} style={styles?.image} />
          <Box sx={styles?.iconContainer}>
            <Typography variant="subtitle2" sx={styles?.text}>
              {subtitle}
            </Typography>
            {!disableActions && Boolean(produto?.ProdutoBlings?.length) && (
              <IconButton
                size="small"
                disabled={disableActions}
                onClick={() =>
                  openDialog(
                    <ModalBling
                      produto={produto}
                      onDeleteIntegracao={onDeleteIntegracao}
                    />,
                    'Integração Bling'
                  )
                }
              >
                <Visibility fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
            {!disableActions && (
              <IconButton
                size="small"
                disabled={disableActions}
                onClick={() =>
                  navigate('/app/Integrações/Bling/Manutencao', {
                    state: { produto_id: produto?.id },
                  })
                }
              >
                <Add fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
          </Box>
        </Card>
      </Grid>
    );
  };

  const MercadoLivre = () => {
    const subtitle = Boolean(produto?.ProdutoMls?.length)
      ? `${produto?.ProdutoMls?.length} Anúncio(s) publicado(s)`
      : 'Não publicado';

    return (
      <Grid item xs={12} sm={6} md={3} display="flex">
        <Card style={styles?.cardContainer}>
          <img src={LogoMercadoLivre} style={styles?.image} />
          <Box sx={styles?.iconContainer}>
            <Typography variant="subtitle2" sx={styles?.text}>
              {subtitle}
            </Typography>
            {!disableActions && Boolean(produto?.ProdutoMls?.length) && (
              <IconButton
                size="small"
                disabled={disableActions}
                onClick={() =>
                  openDialog(
                    <ModalMercadoLivre produto={produto} />,
                    'Integração Mercado Livre'
                  )
                }
              >
                <Visibility fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
            {!disableActions && (
              <IconButton
                size="small"
                disabled={disableActions}
                onClick={() =>
                  navigate('/app/Integrações/MercadoLivre/Manutencao', {
                    state: { id: produto?.id },
                  })
                }
              >
                <Add fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
          </Box>
        </Card>
      </Grid>
    );
  };

  const Shopee = () => {
    const subtitle = Boolean(produto?.ProdutoShopees?.length)
      ? `${produto?.ProdutoShopees?.length} Anúncio(s) publicado(s)`
      : 'Não publicado';

    return (
      <Grid item xs={12} sm={6} md={3} display="flex">
        <Card style={styles?.cardContainer}>
          <img src={LogoShopee} style={styles?.image} />
          <Box sx={styles?.iconContainer}>
            <Typography variant="subtitle2" sx={styles?.text}>
              {subtitle}
            </Typography>
            {!disableActions && Boolean(produto?.ProdutoShopees?.length) && (
              <IconButton
                size="small"
                disabled={disableActions}
                onClick={() =>
                  openDialog(
                    <ModalShopee produto={produto} />,
                    'Integração Shopee'
                  )
                }
              >
                <Visibility fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
            {!disableActions && (
              <IconButton
                size="small"
                disabled={disableActions}
                onClick={() =>
                  navigate(`/app/Integrações/Shopee/Manutencao/${produto?.id}`)
                }
              >
                <Add fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
          </Box>
        </Card>
      </Grid>
    );
  };

  const Magalu = () => {
    const path = 'Magalu/Produto';
    const subtitle = Boolean(produto?.ProdutoMagalu)
      ? `Atualizado em ${moment(produto?.ProdutoMagalu?.updatedAt).format(
          'DD/MM/YYYY'
        )}`
      : 'Não publicado';

    const onRefresh = () => {
      const data = {
        produto_id: produto?.ProdutoMagalu?.produto_id,
        codigo: produto?.ProdutoMagalu?.codigo,
      };

      onSubmitIntegracao({ data, path });
    };

    const onDelete = () =>
      onDeleteIntegracao({ id: produto?.ProdutoMagalu?.produto_id, path });

    return (
      <Grid item xs={12} sm={6} md={3} display="flex">
        <Card style={styles?.cardContainer}>
          <img src={LogoMagalu} style={styles?.image} />
          <Box sx={styles?.iconContainer}>
            <Typography variant="subtitle2" sx={styles?.text}>
              {subtitle}
            </Typography>
            {!disableActions && Boolean(produto?.ProdutoMagalu) && (
              <IconButton size="small" onClick={onRefresh}>
                <Refresh fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
            {!disableActions && (
              <IconButton
                size="small"
                onClick={() =>
                  openDialog(
                    <ModalMagalu
                      produto={produto}
                      onSubmitIntegracao={onSubmitIntegracao}
                    />,
                    'Integração Magalu'
                  )
                }
              >
                {Boolean(produto?.ProdutoMagalu) ? (
                  <Create fontSize="small" sx={styles?.icon} />
                ) : (
                  <Add fontSize="small" sx={styles?.icon} />
                )}
              </IconButton>
            )}
            {!disableActions && Boolean(produto?.ProdutoMagalu) && (
              <IconButton size="small" onClick={onDelete}>
                <Delete fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
          </Box>
        </Card>
      </Grid>
    );
  };

  const Woo = () => {
    const path = 'Woo/Produto';
    const subtitle = Boolean(produto?.ProdutoWoo)
      ? `Atualizado em ${moment(produto?.ProdutoWoo?.updatedAt).format(
          'DD/MM/YYYY'
        )}`
      : 'Não publicado';

    const onRefresh = () => {
      const data = {
        produto_id: produto?.ProdutoWoo?.produto_id,
        codigo: produto?.ProdutoWoo?.codigo,
        informacoes: produto?.ProdutoWoo?.informacoes,
        destaque: produto?.ProdutoWoo?.destaque,
        status: produto?.ProdutoWoo?.status,
      };

      onSubmitIntegracao({ data, path });
    };

    const onDelete = () =>
      onDeleteIntegracao({ id: produto?.ProdutoWoo?.produto_id, path });

    return (
      <Grid item xs={12} sm={6} md={3} display="flex">
        <Card style={styles?.cardContainer}>
          <img src={LogoWoo} style={styles?.image} />
          <Box sx={styles?.iconContainer}>
            <Typography variant="subtitle2" sx={styles?.text}>
              {subtitle}
            </Typography>
            {!disableActions && Boolean(produto?.ProdutoWoo) && (
              <IconButton size="small" onClick={onRefresh}>
                <Refresh fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
            {!disableActions && (
              <IconButton
                size="small"
                onClick={() =>
                  openDialog(
                    <ModalWoo
                      produto={produto}
                      onSubmitIntegracao={onSubmitIntegracao}
                    />,
                    'Integração Woo'
                  )
                }
              >
                {Boolean(produto?.ProdutoWoo) ? (
                  <Create fontSize="small" sx={styles?.icon} />
                ) : (
                  <Add fontSize="small" sx={styles?.icon} />
                )}
              </IconButton>
            )}
            {!disableActions && Boolean(produto?.ProdutoWoo) && (
              <IconButton size="small" onClick={onDelete}>
                <Delete fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
          </Box>
        </Card>
      </Grid>
    );
  };

  const Tray = () => {
    const path = 'Tray/Produto';
    const subtitle = Boolean(produto?.ProdutoTray)
      ? `Atualizado em ${moment(produto?.ProdutoTray?.updatedAt).format(
          'DD/MM/YYYY'
        )}`
      : 'Não publicado';

    const onRefresh = () => {
      const data = {
        produto_id: produto?.ProdutoTray?.produto_id,
        codigo: produto?.ProdutoTray?.codigo,
        variacao: produto?.ProdutoTray?.variacao,
      };

      onSubmitIntegracao({ data, path });
    };

    const onDelete = () =>
      onDeleteIntegracao({ id: produto?.ProdutoTray?.produto_id, path });

    return (
      <Grid item xs={12} sm={6} md={3} display="flex">
        <Card style={styles?.cardContainer}>
          <img src={LogoTray} style={styles?.image} />
          <Box sx={styles?.iconContainer}>
            <Typography variant="subtitle2" sx={styles?.text}>
              {subtitle}
            </Typography>
            {!disableActions && Boolean(produto?.ProdutoTray) && (
              <IconButton size="small" onClick={onRefresh}>
                <Refresh fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
            {!disableActions && (
              <IconButton
                size="small"
                onClick={() =>
                  openDialog(
                    <ModalTray
                      produto={produto}
                      onSubmitIntegracao={onSubmitIntegracao}
                    />,
                    'Integração Tray'
                  )
                }
              >
                {Boolean(produto?.ProdutoTray) ? (
                  <Create fontSize="small" sx={styles?.icon} />
                ) : (
                  <Add fontSize="small" sx={styles?.icon} />
                )}
              </IconButton>
            )}
            {!disableActions && Boolean(produto?.ProdutoTray) && (
              <IconButton size="small" onClick={onDelete}>
                <Delete fontSize="small" sx={styles?.icon} />
              </IconButton>
            )}
          </Box>
        </Card>
      </Grid>
    );
  };

  return (
    <Card title="Integrações" style={styles?.card}>
      <Grid container spacing={2}>
        <Bling />
        <MercadoLivre />
        <Shopee />
        {/* <Magalu />
        <Woo /> 
        <Tray />*/}
      </Grid>
    </Card>
  );
};

export default Integrações;
