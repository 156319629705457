import moment from 'moment';

const mock = [
  {
    field: 'dtcad',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'ativo',
    renderCell: ({ value }) => (
      <div style={colorContainer(value)}>
        <span>{value}</span>
      </div>
    ),
  },
];

const colorContainer = (value) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor:
    value === 'SIM' ? '#baffc9' : value === 'NAO' ? '#ffb3ba' : '#ffffba',
});

export default mock;
