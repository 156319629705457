import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid, Tooltip } from '@mui/material';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import styles from '../styles';

const Detalhes = ({ loading, onSubmit, produtoMl = {}, disableActions }) => {
  const renderValues = () => {
    let defaultValues = {};
    produtoMl?.allAttributes?.map((a) => {
      defaultValues[a.id] =
        a?.value_type === 'list' || a?.value_type === 'boolean' ? null : '';
    });
    produtoMl?.attributes?.map((a) => {
      defaultValues[a.id] =
        a?.value_type === 'list' || a?.value_type === 'boolean'
          ? a?.value_name
          : a?.value_name;
    });
    return defaultValues;
  };
  const { control, handleSubmit } = useForm({ defaultValues: renderValues() });

  const onConfirm = (values) => {
    let data = {
      id: produtoMl?.id,
      attributes: Object.keys(values).map((id) => ({
        id,
        value_name: values[id],
      })),
    };
    onSubmit(data);
  };

  const RenderInputs = ({ inputs = [] }) => {
    return inputs.map((input) => {
      if (input?.value_type === 'string') {
        return (
          <Grid item xs={12} key={input?.id}>
            <Tooltip title={input?.hint} placement="top">
              <Box>
                <Input name={input?.id} control={control} label={input?.name} />
              </Box>
            </Tooltip>
          </Grid>
        );
      }
      if (input?.value_type === 'number') {
        return (
          <Grid item xs={12} key={input?.id}>
            <Tooltip title={input?.hint} placement="top">
              <Box>
                <InputMask
                  name={input?.id}
                  control={control}
                  label={input?.name}
                />
              </Box>
            </Tooltip>
          </Grid>
        );
      }
      if (input?.value_type === 'list' || input?.value_type === 'boolean') {
        return (
          <Grid item xs={12} key={input?.id}>
            <Tooltip title={input?.hint} placement="top">
              <Box>
                <Dropdown
                  name={input?.id}
                  control={control}
                  label={input?.name}
                  options={input?.values?.map((v) => ({
                    value: v?.name,
                    label: v?.name,
                  }))}
                />
              </Box>
            </Tooltip>
          </Grid>
        );
      }
      return null;
    });
  };

  return (
    <Card title="Informações Adicionais" style={styles?.card}>
      <Grid container spacing={2}>
        <RenderInputs inputs={produtoMl?.allAttributes} />
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={handleSubmit(onConfirm)}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Detalhes;
