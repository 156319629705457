import React from 'react';
import { Paper, Box } from '@mui/material';

const TableContainer = ({ size, children }) => {
  return (
    <Box sx={{ overflow: 'auto', maxHeight: size }} component={Paper}>
      <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
        {children}
      </Box>
    </Box>
  );
};

export default TableContainer;
