import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ButtonBase, Grid, Icon, Typography } from '@mui/material';
import { AuthContext } from 'contexts/AuthContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';

const Tabelas = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const titulo = 'Tabelas';
  const { control, watch } = useForm({ defaultValues: { search: '' } });

  const filtered = () =>
    tables
      .filter(
        ({ name, show = true }) =>
          show && name?.toLowerCase()?.includes(watch('search')?.toLowerCase())
      )
      ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  const Item = ({ item }) => (
    <Grid item xs={12} sm={6} lg={4} display="flex">
      <ButtonBase sx={{ width: '100%' }} onClick={item?.action}>
        <Card style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ fontSize: 50, mr: '.5em' }}>{item?.icon}</Icon>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'left' }}>
            {item?.name}
          </Typography>
        </Card>
      </ButtonBase>
    </Grid>
  );

  const tables = [
    {
      name: 'ANP',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Anp'),
    },
    {
      name: 'Aplicação',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Aplicacao'),
    },
    {
      name: 'Atividade da Entidade',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/AtividadeEntidade'),
    },
    {
      name: 'Centro de Custo',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Ccusto'),
    },
    {
      name: 'CFOP',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Cfop'),
    },
    {
      name: 'Cidade',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Cidade'),
    },
    {
      name: 'Classificação Fiscal',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Cfiscal'),
    },
    {
      name: 'Classe CNAE',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/ClasseCnae'),
    },
    {
      name: 'Condição de Pagamento',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/CondicoesDePagamento'),
    },
    {
      name: 'Contas',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Contas'),
    },
    {
      name: 'Contribuinte',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Contribuinte'),
    },
    {
      name: 'Divisão CNAE',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/DivisaoCnae'),
    },
    {
      name: 'Forma de Pagamento',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/FormasDePagamento'),
    },
    {
      name: 'Frota',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Frota'),
    },
    {
      name: 'Grupo CNAE',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/GrupoCnae'),
    },
    {
      name: 'Grupo da Entidade',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/GrupoEntidade'),
    },
    {
      name: 'Grupo do Produto',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/GrupoProduto'),
    },
    {
      name: 'Linha do Produto',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/LinhaProduto'),
    },
    {
      name: 'Local de Estoque',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/LocalEstoque'),
    },
    {
      name: 'Marca',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Marca'),
    },
    {
      name: 'Modelo',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Modelo'),
    },
    {
      name: 'Moeda',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Moeda'),
    },
    {
      name: 'Origem Tributária',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/OrigemTributaria'),
    },
    {
      name: 'Pais',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Pais'),
    },
    {
      name: 'Plano de Conta',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Planoconta'),
    },
    {
      name: 'Região da Entidade',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/RegiaoEntidade'),
    },
    {
      name: 'Seção CNAE',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/SecaoCnae'),
    },
    {
      name: 'Situação Tributária',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/SituacaoTributaria'),
      show: !Boolean(user?.permissoes?.block_tabela_situacao_tributaria),
    },
    {
      name: 'Tipo de Contato',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/TipoContato'),
    },
    {
      name: 'Tipo de Documento',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/TipoDocumento'),
    },
    {
      name: 'Tipo de Endereço',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/TipoEndereco'),
    },
    {
      name: 'Tipo de Vínculo',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/TipoVinculo'),
    },
    {
      name: 'UF',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/Uf'),
    },
    {
      name: 'Unidade de Medida',
      icon: 'apps',
      action: () => navigate('/app/Tabelas/UnidadeMedida'),
    },
  ];

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input name="search" label="Buscar" control={control} />
        </Grid>
        {filtered()?.map((item, i) => (
          <Item item={item} key={i?.toString()} />
        ))}
      </Grid>
    </Container>
  );
};

export default Tabelas;
