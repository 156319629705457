import { Buffer } from 'buffer';
import Blank from 'assets/blank.png';

export const findOnArray = (value, arr = [], field) => {
  const rec = arr.find((i) => i.value === value);
  if (field) {
    return rec?.[field] || null;
  }
  return rec || null;
};

export const getImageFromBuffer = (buffer) => {
  if (buffer) {
    return (
      'data:image/png;base64,' + new Buffer.from(buffer).toString('base64')
    );
  }
  return Blank;
};
