import { useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import InputMask from 'components/InputMask';

const PrecoModal = ({ sum = 0, onSubmit }) => {
  const { closeModal } = useModal();
  const { postLoading } = useContext(ProdutosContext);
  const { control, handleSubmit } = useForm({ defaultValues: { preco: '' } });

  return (
    <>
      <DialogTitle>Confirmação da alteração</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogContentText>
              Você está alterando o preço de {sum} produto(s)
            </DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <InputMask
              name="preco"
              control={control}
              label="Preço"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Confirmar
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default PrecoModal;
