import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  modelo: '',
  marca: [],
  anofab: '',
  ativo: null,
};

export const ModeloContext = createContext();

export const ModeloProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [modelo, setModelo] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getModelo = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: modelo?.filter,
        page: modelo?.page,
        size: modelo?.size,
        order: modelo?.order,
        ...payload,
      });
      setModelo((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <ModeloContext.Provider
      value={{
        getLoading,
        modelo,
        getModelo,
      }}
    >
      {children}
    </ModeloContext.Provider>
  );
};
