import { createContext, useState } from 'react';
import api from 'services/api';

export const TributacaoContext = createContext();

export const TributacaoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);

  const getRegras = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Fiscal/Regras/Tributacao', { params });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <TributacaoContext.Provider value={{ getLoading, getRegras }}>
      {children}
    </TributacaoContext.Provider>
  );
};
