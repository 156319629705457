import React, { useContext } from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { useModal } from 'components/Modals';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import { EntidadesContext } from 'contexts/EntidadesContext';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

const MigrarCarteiraModal = ({ entidade }) => {
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({
    defaultValues: { entidade_id: entidade?.id, entidade_id_vinc: null },
  });
  const { drops } = useContext(DropsContext);
  const { putLoading, putEntidadeClientes } = useContext(EntidadesContext);

  const onSubmit = (data) => {
    putEntidadeClientes({
      data,
      cb: () => closeModal(),
    });
  };

  return (
    <>
      <DialogTitle>Migrar Carteira de Vendedor</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Dropdown
              name="entidade_id_vinc"
              control={control}
              label="Vendedor"
              options={drops?.EntidadeTipo?.filter(
                (t) => t?.tipo === 'VENDEDOR' || t?.tipo === 'REPRESENTANTE'
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} loading={putLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default MigrarCarteiraModal;
