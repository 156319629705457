import React from 'react';
import { Grid } from '@mui/material';
import { dropBoolean, dropOrgDest } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('cfo') && (
        <Grid item xs={12}>
          <Input name="cfo" control={control} label="CFOP" type="number" />
        </Grid>
      )}
      {checkDisplay('orgdest') && (
        <Grid item xs={12}>
          <Dropdown
            name="orgdest"
            control={control}
            label="Origem / Destino"
            options={dropOrgDest}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('orientacao') && (
        <Grid item xs={12}>
          <Input name="orientacao" control={control} label="Orientação" />
        </Grid>
      )}
      {checkDisplay('servico') && (
        <Grid item xs={12}>
          <Dropdown
            name="servico"
            control={control}
            label="Serviço"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('planoconta') && (
        <Grid item xs={12}>
          <Dropdown
            name="planoconta"
            control={control}
            label="Plano de Contas"
            options={drops?.Planoconta?.filter((p) => p?.operacional === 'SIM')}
            multiple
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
