import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  codigo: '',
  descricao: '',
  origem: [],
};

export const OrigemTributariaContext = createContext();

export const OrigemTributariaProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [origemTributaria, setOrigemTributaria] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getOrigemTributaria = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: origemTributaria?.filter,
        page: origemTributaria?.page,
        size: origemTributaria?.size,
        order: origemTributaria?.order,
        ...payload,
      });
      setOrigemTributaria((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <OrigemTributariaContext.Provider
      value={{
        getLoading,
        origemTributaria,
        getOrigemTributaria,
      }}
    >
      {children}
    </OrigemTributariaContext.Provider>
  );
};
