import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  dropTipoPessoa,
  dropTipoPerfil,
  dropEstadoCivil,
  dropSexo,
} from 'utils/drops';
import { findOnArray } from 'utils/functions';
import { EntidadesContext } from 'contexts/EntidadesContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import PesquisaEnderecoModal from 'components/Modals/PesquisaEnderecoModal';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';

const Manutenção = () => {
  const defaultValues = {
    pessoa: 'FISICA',
    estrangeiro: false,
    cpf: '',
    rg: '',
    cnpj: '',
    ie: '',
    rne: '',
    nome: '',
    fantasia: '',
    codigo: '',
    atividade_entidade_id: null,
    dtnasc: null,
    estado_civil: null,
    sexo: null,
    estado_nascimento: null,
    nascimento_id: null,
    loctrabalho: '',
    residencial: '',
    comercial: '',
    celular: '',
    email: '',
    cep: '',
    estado: null,
    cidade_id: null,
    bairro: '',
    logradouro: '',
    numero: '',
    complemento: '',
    perfil: null,
    perfis: [],
    tipo_vinculo: null,
    entidade_vinculo: null,
    vinculos: [],
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { openModal, closeModal } = useModal();
  const { drops, getDrops } = useContext(DropsContext);
  const { deleteGrid, deleteLoading } = useContext(GridContext);
  const {
    postEntidade,
    postLoading,
    entidade,
    getLoading,
    getEntidade,
    checkEntidade,
  } = useContext(EntidadesContext);
  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (state?.id) {
      getEntidade(state?.id);
    }
  }, []);

  useEffect(() => {
    if (entidade && state?.id) {
      const rne = entidade?.EntidadeDocumentos?.find(
        (d) => d?.tipo_documento_id === 8
      )?.documento;
      setValue('estrangeiro', Boolean(rne));
      setValue('rne', rne || '');
      setValue('pessoa', entidade?.pessoa || null);
      if (entidade?.pessoa === 'FISICA') {
        const documento = entidade?.EntidadeDocumentos?.find(
          (d) => d?.tipo_documento_id === 2
        )?.documento;
        setValue('cpf', documento || '');
      }
      if (entidade?.pessoa === 'FISICA') {
        const documento = entidade?.EntidadeDocumentos?.find(
          (d) => d?.tipo_documento_id === 4
        )?.documento;
        setValue('rg', documento || '');
      }
      if (entidade?.pessoa === 'JURIDICA') {
        const documento = entidade?.EntidadeDocumentos?.find(
          (d) => d?.tipo_documento_id === 1
        )?.documento;
        setValue('cnpj', documento || '');
      }
      if (entidade?.pessoa === 'JURIDICA') {
        const documento = entidade?.EntidadeDocumentos?.find(
          (d) => d?.tipo_documento_id === 3
        )?.documento;
        setValue('ie', documento || '');
      }
      setValue('nome', entidade?.nome || '');
      setValue('fantasia', entidade?.fantasia || '');
      setValue('codigo', entidade?.codigo || '');
      setValue(
        'atividade_entidade_id',
        entidade?.atividade_entidade_id || null
      );
      setValue('dtnasc', entidade?.EntidadeFisica?.dtnasc || null);
      setValue('estado_civil', entidade?.EntidadeFisica?.estado_civil || null);
      setValue('sexo', entidade?.EntidadeFisica?.sexo || null);
      setValue(
        'estado_nascimento',
        entidade?.EntidadeFisica?.Cidade?.uf_id || null
      );
      setValue(
        'nascimento_id',
        entidade?.EntidadeFisica?.nascimento_id || null
      );
      setValue('loctrabalho', entidade?.EntidadeFisica?.loctrabalho || '');
      const residencial = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 1
      )?.conteudo;
      setValue('residencial', residencial || '');
      const comercial = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 4
      )?.conteudo;
      setValue('comercial', comercial || '');
      const celular = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 2
      )?.conteudo;
      setValue('celular', celular || '');
      const email = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 3
      )?.conteudo;
      setValue('email', email) || '';
      const endereco = entidade?.EntidadeEnderecos?.find(
        (e) => e?.tipo_endereco_id === 1
      );
      setValue('cep', endereco?.cep || '');
      setValue('estado', endereco?.Cidade?.uf_id || null);
      setValue('cidade_id', endereco?.cidade_id || null);
      setValue('bairro', endereco?.bairro || '');
      setValue('logradouro', endereco?.logradouro || '');
      setValue('numero', endereco?.numero || '');
      setValue('complemento', endereco?.complemento || '');
      setValue(
        'perfis',
        entidade?.EntidadeTipos?.map(({ id, tipo }) => ({ id, tipo }))
      );
      setValue(
        'vinculos',
        entidade?.EntidadeVinculos?.map((v) => {
          const vinculo = findOnArray(v?.tipo_vinculo_id, drops?.TipoVinculo);
          const entidade = findOnArray(v?.entidade_id_vinc, drops?.Entidade);
          return { id: v?.id, vinculo, entidade };
        })
      );
    }
  }, [entidade]);

  const onSearchAddress = (endereco) => {
    const estado = drops?.UF?.find((u) => u?.sigla === endereco?.uf)?.value;
    const cidade = drops?.Cidade?.find(
      (u) => u?.codigo_ibge === Number(endereco?.ibge)
    )?.value;
    if (Boolean(endereco?.cep)) {
      setValue('cep', endereco?.cep);
    }
    if (Boolean(estado)) {
      setValue('estado', estado);
    }
    if (Boolean(cidade)) {
      setValue('cidade_id', cidade);
    }
    if (Boolean(endereco?.bairro)) {
      setValue('bairro', endereco?.bairro);
    }
    if (Boolean(endereco?.logradouro)) {
      setValue('logradouro', endereco?.logradouro);
    }
    if (Boolean(endereco?.complemento)) {
      setValue('complemento', endereco?.complemento);
    }
    closeModal();
  };

  const onAddVinculo = (values) => {
    if (Boolean(values?.tipo_vinculo) && Boolean(values?.entidade_vinculo)) {
      const vinculo = findOnArray(values?.tipo_vinculo, drops?.TipoVinculo);
      const entidade = findOnArray(values?.entidade_vinculo, drops?.Entidade);
      setValue('vinculos', [...watch('vinculos'), { vinculo, entidade }]);
      resetField('tipo_vinculo');
      resetField('entidade_vinculo');
    }
  };

  const onSubVinculo = (value, index) => {
    const cb = () => {
      const vinculos = watch('vinculos')?.filter((_, i) => i !== index);
      setValue('vinculos', vinculos);
    };
    if (value?.id) {
      deleteGrid({
        params: {
          rotina: 'EntidadeVinculo',
          id: value?.id,
        },
        cb,
      });
    } else {
      cb();
    }
  };

  const onAddPerfil = (values) => {
    if (Boolean(values?.perfil)) {
      setValue('perfis', [...watch('perfis'), { tipo: values?.perfil }]);
      resetField('perfil');
    }
  };

  const onSubPerfil = (value, index) => {
    const cb = () => {
      const perfis = watch('perfis')?.filter((_, i) => i !== index);
      setValue('perfis', perfis);
    };
    if (value?.id) {
      deleteGrid({
        params: {
          rotina: 'EntidadeTipo',
          id: value?.id,
        },
        cb,
      });
    } else {
      cb();
    }
  };

  const onPost = (values) => {
    const Documentos = [];
    const Contatos = [];
    const Enderecos = [];
    const Perfis = values?.perfis;
    const Vinculos = values?.vinculos?.map((v) => ({
      tipo_vinculo_id: v?.vinculo?.value,
      entidade_id_vinc: v?.entidade?.value,
    }));
    if (Boolean(values?.estrangeiro) && Boolean(values?.rne)) {
      Documentos?.push({
        tipo_documento_id: 8,
        documento: values?.rne,
      });
    }
    if (Boolean(values?.pessoa === 'FISICA') && Boolean(values?.cpf)) {
      Documentos?.push({
        tipo_documento_id: 2,
        documento: values?.cpf,
      });
    }
    if (Boolean(values?.pessoa === 'FISICA') && Boolean(values?.rg)) {
      Documentos?.push({
        tipo_documento_id: 4,
        documento: values?.rg,
      });
    }
    if (Boolean(values?.pessoa === 'JURIDICA') && Boolean(values?.cnpj)) {
      Documentos?.push({
        tipo_documento_id: 1,
        documento: values?.cnpj,
      });
    }
    if (Boolean(values?.pessoa === 'JURIDICA') && Boolean(values?.ie)) {
      Documentos?.push({
        tipo_documento_id: 3,
        documento: values?.ie,
      });
    }
    if (Boolean(values?.residencial)) {
      Contatos?.push({
        tipo_contato_id: 1,
        conteudo: values?.residencial,
      });
    }
    if (Boolean(values?.comercial)) {
      Contatos?.push({
        tipo_contato_id: 4,
        conteudo: values?.comercial,
      });
    }
    if (Boolean(values?.celular)) {
      Contatos?.push({
        tipo_contato_id: 2,
        conteudo: values?.celular,
      });
    }
    if (Boolean(values?.email)) {
      Contatos?.push({
        tipo_contato_id: 3,
        conteudo: values?.email,
      });
    }
    if (
      Boolean(values?.cep) ||
      Boolean(values?.cidade_id) ||
      Boolean(values?.bairro) ||
      Boolean(values?.logradouro) ||
      Boolean(values?.numero) ||
      Boolean(values?.complemento)
    ) {
      Enderecos?.push({
        tipo_endereco_id: 1,
        cep: values?.cep,
        cidade_id: values?.cidade_id,
        bairro: values?.bairro,
        logradouro: values?.logradouro,
        numero: values?.numero,
        complemento: values?.complemento,
        principal: 'SIM',
      });
    }

    let data = {
      Entidade: {
        pessoa: values?.pessoa,
        nome: values?.nome,
        fantasia: values?.fantasia,
        dtcad: moment().format('YYYY-MM-DD'),
        codigo: values?.codigo,
        atividade_entidade_id: values?.atividade_entidade_id,
      },
    };
    if (Boolean(values?.pessoa === 'FISICA')) {
      const Fisica = {
        dtnasc: values?.dtnasc,
        estado_civil: values?.estado_civil,
        sexo: values?.sexo,
        nascimento_id: values?.nascimento_id,
        loctrabalho: values?.loctrabalho,
      };
      data = { ...data, Fisica };
    }
    if (Boolean(Documentos?.length)) {
      data = { ...data, Documentos };
    }
    if (Boolean(Contatos?.length)) {
      data = { ...data, Contatos };
    }
    if (Boolean(Enderecos?.length)) {
      data = { ...data, Enderecos };
    }
    if (Boolean(Perfis?.length)) {
      data = { ...data, Perfis };
    }
    if (Boolean(Vinculos?.length)) {
      data = { ...data, Vinculos };
    }
    return data;
  };

  const onPut = (values) => {
    const Documentos = [];
    const Contatos = [];
    const Enderecos = [];
    const Perfis = values?.perfis
      ?.filter((f) => !Boolean(f?.id))
      ?.map((p) => ({ ...p, entidade_id: state?.id }));
    const Vinculos = values?.vinculos
      ?.filter((f) => !Boolean(f?.id))
      ?.map((v) => ({
        entidade_id: state?.id,
        tipo_vinculo_id: v?.vinculo?.value,
        entidade_id_vinc: v?.entidade?.value,
      }));
    if (Boolean(values?.estrangeiro) && Boolean(values?.rne)) {
      const id = entidade?.EntidadeDocumentos?.find(
        (d) => d?.tipo_documento_id === 8
      )?.id;
      Documentos?.push({
        id,
        entidade_id: state?.id,
        tipo_documento_id: 8,
        documento: values?.rne,
      });
    }
    if (Boolean(values?.pessoa === 'FISICA') && Boolean(values?.cpf)) {
      const id = entidade?.EntidadeDocumentos?.find(
        (d) => d?.tipo_documento_id === 2
      )?.id;
      Documentos?.push({
        id,
        entidade_id: state?.id,
        tipo_documento_id: 2,
        documento: values?.cpf,
      });
    }
    if (Boolean(values?.pessoa === 'FISICA') && Boolean(values?.rg)) {
      const id = entidade?.EntidadeDocumentos?.find(
        (d) => d?.tipo_documento_id === 4
      )?.id;
      Documentos?.push({
        id,
        entidade_id: state?.id,
        tipo_documento_id: 4,
        documento: values?.rg,
      });
    }
    if (Boolean(values?.pessoa === 'JURIDICA') && Boolean(values?.cnpj)) {
      const id = entidade?.EntidadeDocumentos?.find(
        (d) => d?.tipo_documento_id === 1
      )?.id;
      Documentos?.push({
        id,
        entidade_id: state?.id,
        tipo_documento_id: 1,
        documento: values?.cnpj,
      });
    }
    if (Boolean(values?.pessoa === 'JURIDICA') && Boolean(values?.ie)) {
      const id = entidade?.EntidadeDocumentos?.find(
        (d) => d?.tipo_documento_id === 3
      )?.id;
      Documentos?.push({
        id,
        entidade_id: state?.id,
        tipo_documento_id: 3,
        documento: values?.ie,
      });
    }
    if (Boolean(values?.residencial)) {
      const id = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 1
      )?.id;
      Contatos?.push({
        id,
        entidade_id: state?.id,
        tipo_contato_id: 1,
        conteudo: values?.residencial,
      });
    }
    if (Boolean(values?.comercial)) {
      const id = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 4
      )?.id;
      Contatos?.push({
        id,
        entidade_id: state?.id,
        tipo_contato_id: 4,
        conteudo: values?.comercial,
      });
    }
    if (Boolean(values?.celular)) {
      const id = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 2
      )?.id;
      Contatos?.push({
        id,
        entidade_id: state?.id,
        tipo_contato_id: 2,
        conteudo: values?.celular,
      });
    }
    if (Boolean(values?.email)) {
      const id = entidade?.EntidadeContatos?.find(
        (c) => c?.tipo_contato_id === 3
      )?.id;
      Contatos?.push({
        id,
        entidade_id: state?.id,
        tipo_contato_id: 3,
        conteudo: values?.email,
      });
    }
    if (
      Boolean(values?.cep) ||
      Boolean(values?.cidade_id) ||
      Boolean(values?.bairro) ||
      Boolean(values?.logradouro) ||
      Boolean(values?.numero) ||
      Boolean(values?.complemento)
    ) {
      const endereco = entidade?.EntidadeEnderecos?.find(
        (e) => e?.tipo_endereco_id === 1
      );
      Enderecos?.push({
        id: endereco?.id,
        entidade_id: state?.id,
        tipo_endereco_id: 1,
        cep: values?.cep,
        cidade_id: values?.cidade_id,
        bairro: values?.bairro,
        logradouro: values?.logradouro,
        numero: values?.numero,
        complemento: values?.complemento,
        principal: 'SIM',
      });
    }

    let data = {
      Entidade: {
        id: state?.id,
        pessoa: values?.pessoa,
        nome: values?.nome,
        fantasia: values?.fantasia,
        dtcad: entidade?.dtcad,
        codigo: values?.codigo,
        atividade_entidade_id: values?.atividade_entidade_id,
      },
    };
    if (Boolean(values?.pessoa === 'FISICA')) {
      const Fisica = {
        entidade_id: state?.id,
        dtnasc: values?.dtnasc,
        estado_civil: values?.estado_civil,
        sexo: values?.sexo,
        nascimento_id: values?.nascimento_id,
        loctrabalho: values?.loctrabalho,
      };
      data = { ...data, Fisica };
    }
    if (Boolean(Documentos?.length)) {
      data = { ...data, Documentos };
    }
    if (Boolean(Contatos?.length)) {
      data = { ...data, Contatos };
    }
    if (Boolean(Enderecos?.length)) {
      data = { ...data, Enderecos };
    }
    if (Boolean(Perfis?.length)) {
      data = { ...data, Perfis };
    }
    if (Boolean(Vinculos?.length)) {
      data = { ...data, Vinculos };
    }
    return data;
  };

  const onSubmit = (values) => {
    const data = state?.id ? onPut(values) : onPost(values);
    postEntidade({
      data,
      cb: () => {
        navigate(-1);
        getDrops(['Entidade']);
      },
    });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header
        titulo={(Boolean(state?.id) ? 'Editar' : 'Cadastrar') + ' Entidade'}
      />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} display="flex" alignItems="center">
            <Checkbox
              checked={watch('estrangeiro')}
              id="estrangeiro"
              label="Estrangeiro"
              color="primary"
              onChange={(_, v) => {
                setValue('estrangeiro', v);
                resetField('cpf');
                resetField('rg');
                resetField('cnpj');
                resetField('ie');
                resetField('rne');
              }}
            />
            <InputLabel htmlFor="estrangeiro">Estrangeiro</InputLabel>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="pessoa"
              control={control}
              label="Tipo de Pessoa"
              options={dropTipoPessoa}
              onValueChange={() => {
                resetField('cpf');
                resetField('rg');
                resetField('cnpj');
                resetField('ie');
                resetField('rne');
              }}
            />
          </Grid>
          {watch('estrangeiro') && (
            <Grid item xs={12} sm={6}>
              <Input name="rne" control={control} label="RNE" />
            </Grid>
          )}
          {!watch('pessoa') && <Grid item xs={12} sm={6} />}
          {watch('pessoa') === 'FISICA' && (
            <Grid item xs={12} sm={3}>
              <InputMask
                name="cpf"
                control={control}
                label="CPF"
                mask="999.999.999-99"
                onKeyUp={(e) => {
                  const documento = e?.target?.value;
                  if (documento?.length === 14) {
                    checkEntidade({ documento });
                  }
                }}
              />
            </Grid>
          )}
          {watch('pessoa') === 'FISICA' && (
            <Grid item xs={12} sm={3}>
              <InputMask
                name="rg"
                control={control}
                label="RG"
                mask="99.999.999-*"
              />
            </Grid>
          )}
          {watch('pessoa') === 'JURIDICA' && (
            <Grid item xs={12} sm={3}>
              <InputMask
                name="cnpj"
                control={control}
                label="CNPJ"
                mask="99.999.999/9999-99"
                onKeyUp={(e) => {
                  const documento = e?.target?.value;
                  if (documento?.length === 18) {
                    checkEntidade({ documento });
                  }
                }}
              />
            </Grid>
          )}
          {watch('pessoa') === 'JURIDICA' && (
            <Grid item xs={12} sm={3}>
              <Input name="ie" control={control} label="Inscrição Estadual" />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Input name="nome" control={control} label="Razão Social" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="fantasia" control={control} label="Nome Fantasia" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="codigo"
              control={control}
              label="Código"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="atividade_entidade_id"
              control={control}
              label="Atividade"
              options={drops?.AtividadeEntidade}
            />
          </Grid>
        </Grid>
      </Card>
      {watch('pessoa') === 'FISICA' && (
        <Card title="Informações para Pessoa Física" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="dtnasc"
                control={control}
                label="Data de Nascimento"
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="estado_civil"
                control={control}
                label="Estado Civil"
                options={dropEstadoCivil}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="sexo"
                control={control}
                label="Sexo"
                options={dropSexo}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="estado_nascimento"
                control={control}
                label="Estado de Nascimento"
                options={drops?.UF}
                onValueChange={() => resetField('nascimento_id')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="nascimento_id"
                control={control}
                label="Cidade de Nascimento"
                options={drops?.Cidade?.filter((c) => {
                  const estado = findOnArray(
                    watch('estado_nascimento'),
                    drops?.UF
                  );
                  return estado?.sigla === c?.sigla;
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="loctrabalho"
                control={control}
                label="Local de Trabalho"
              />
            </Grid>
          </Grid>
        </Card>
      )}
      <Card title="Contatos" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="residencial"
              control={control}
              label="Residencial"
              mask="(99) 9999-9999"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="comercial"
              control={control}
              label="Comercial"
              mask="(99) 9999-9999"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="celular"
              control={control}
              label="Celular"
              mask="(99) 99999-9999"
            />
          </Grid>
          <Grid item xs={12}>
            <Input name="email" control={control} label="E-mail" />
          </Grid>
        </Grid>
      </Card>
      <Card title="Endereço" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                openModal(
                  <PesquisaEnderecoModal onSubmit={onSearchAddress} />,
                  90
                )
              }
            >
              Pesquisar Endereço
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="cep"
              control={control}
              label="CEP"
              mask="99999-999"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="estado"
              control={control}
              label="Estado"
              options={drops?.UF}
              onValueChange={() => resetField('cidade_id')}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="cidade_id"
              control={control}
              label="Cidade"
              options={drops?.Cidade?.filter((c) => {
                const estado = findOnArray(watch('estado'), drops?.UF);
                return estado?.sigla === c?.sigla;
              })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="bairro" control={control} label="Bairro" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="logradouro" control={control} label="Endereço" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="numero" control={control} label="Número" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="complemento" control={control} label="Complemento" />
          </Grid>
        </Grid>
      </Card>
      <Card title="Perfis" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dropdown
              name="perfil"
              control={control}
              label="Perfil"
              options={dropTipoPerfil}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSubmit(onAddPerfil)}
            >
              Adicionar
            </Button>
          </Grid>
          {!!watch('perfis')?.length && (
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Perfil</TableCell>
                      <TableCell>Ação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {watch('perfis')?.map((p, i) => (
                      <TableRow key={i?.toString()}>
                        <TableCell>{p?.tipo}</TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => onSubPerfil(p, i)}
                            disabled={deleteLoading}
                          >
                            {deleteLoading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Delete />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Card>
      <Card title="Vínculos" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="tipo_vinculo"
              control={control}
              label="Tipo de Vínculo"
              options={drops?.TipoVinculo}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VirtualDrop
              name="entidade_vinculo"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSubmit(onAddVinculo)}
            >
              Adicionar
            </Button>
          </Grid>
          {!!watch('vinculos')?.length && (
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Ação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {watch('vinculos')?.map((v, i) => (
                      <TableRow key={i?.toString()}>
                        <TableCell>{v?.vinculo?.label}</TableCell>
                        <TableCell>{v?.entidade?.label}</TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => onSubVinculo(v, i)}
                            disabled={deleteLoading}
                          >
                            {deleteLoading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Delete />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Container>
  );
};

export default Manutenção;
