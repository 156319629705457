import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  codigo: '',
  descricao: '',
  ativo: null,
};

export const AnpContext = createContext();

export const AnpProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [anp, setAnp] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getAnp = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: anp?.filter,
        page: anp?.page,
        size: anp?.size,
        order: anp?.order,
        ...payload,
      });
      setAnp((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <AnpContext.Provider
      value={{
        getLoading,
        anp,
        getAnp,
      }}
    >
      {children}
    </AnpContext.Provider>
  );
};
