import { createContext, useState } from 'react';
import api from 'services/api';

export const ContabilidadeContext = createContext();

export const ContabilidadeProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);

  const getRegras = async ({ params, cb }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Contabil/Integracao/Regras', { params });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <ContabilidadeContext.Provider value={{ getLoading, getRegras }}>
      {children}
    </ContabilidadeContext.Provider>
  );
};
