import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import { ProdutosShopeeContext } from 'contexts/ProdutosShopeeContext';
import { toastWarning } from 'utils/toast';
import Card from 'components/Card';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Button from 'components/Button';
import Container from 'components/Container';
import VirtualDrop from 'components/VirtualDrop';
import styles from './styles';

const Manutenção = () => {
  const steps = [
    'Identificar Produto',
    'Selecionar Imagens',
    'Escolher Categoria',
    'Selecionar Marca',
    'Adicionais / Envio',
  ];
  const defaultValues = {
    produto_id: null,
    images: [],
    category_id: null,
    marca: null,
    page: 1,
    has_next_page: false,
    is_mandatory: false,
  };
  const [tab, setTab] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [imagens, setImagens] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const { id } = useParams();
  const { drops } = useContext(DropsContext);
  const { getImgCategorias, getMarcas, getEnvios, getLoading } = useContext(
    ProdutosShopeeContext
  );
  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues,
  });
  const listCols = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (Boolean(id)) {
      setValue('produto_id', id);
      setTab(1);
    }
  }, []);

  useEffect(() => {
    if (tab === 0) {
      setValue('images', []);
    }
    if (tab === 1) {
      const { produto_id } = getValues();
      setValue('category_id', null);
      getImgCategorias({
        params: { produto_id },
        cb: (data) => {
          setImagens(data?.imagens || []);
          setCategorias(data?.categorias || []);
        },
      });
    }
    if (tab === 2) {
      setValue('marca', null);
      setValue('page', 1);
      setValue('has_next_page', false);
      setValue('is_mandatory', false);
    }
    if (tab === 3) {
      // resetar a tab 4
      loadMarcas();
    }
    if (tab === 4) {
      const { category_id } = getValues();
      const params = { category_id };
      getEnvios({ params });
    }
  }, [tab]);

  const loadMarcas = () => {
    const { page, category_id } = getValues();
    const params = { page, category_id };
    getMarcas({
      params,
      cb: (data) => {
        setMarcas(data?.brand_list || []);
        setValue('has_next_page', data?.has_next_page);
        setValue('is_mandatory', data?.is_mandatory);
      },
    });
  };

  const onSubmit = (values) => {
    if (tab === 0) {
      if (Boolean(values?.produto_id)) {
        setTab(1);
      } else {
        toastWarning('Produto obrigatório');
      }
    }
    if (tab === 1) {
      if (Boolean(values?.images?.length)) {
        setTab(2);
      } else {
        toastWarning('Selecione ao menos uma imagem');
      }
    }
    if (tab === 2) {
      if (Boolean(values?.category_id)) {
        setTab(3);
      } else {
        toastWarning('Selecione uma categoria');
      }
    }
    if (tab === 3) {
      if (Boolean(values?.is_mandatory) && !Boolean(values?.marca)) {
        toastWarning('Selecione uma marca');
      } else {
        setTab(4);
      }
    }
    if (tab === 4) {
    }
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={`${nodes?.category_id}`}
      nodeId={`${nodes?.category_id}`}
      label={
        <Box sx={styles?.categoryBox}>
          {!Array.isArray(nodes?.children) && (
            <Checkbox
              size="small"
              checked={nodes?.category_id === watch('category_id')}
              onChange={(_, v) => {
                if (v) {
                  setValue('category_id', nodes?.category_id);
                } else {
                  setValue('category_id', null);
                }
              }}
            />
          )}
          <Typography sx={styles?.category}>
            {nodes?.display_category_name}
          </Typography>
        </Box>
      }
    >
      {Array.isArray(nodes?.children) ? nodes?.children?.map(renderTree) : null}
    </TreeItem>
  );

  return (
    <Container>
      <Header titulo="Cadastrar Anúncio" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Stepper activeStep={tab} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Card>
        </Grid>
        <Grid item xs={12} display={tab === 0 ? 'block' : 'none'}>
          <Card title="Identificar Produto">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <VirtualDrop
                  name="produto_id"
                  control={control}
                  label="Produto"
                  options={drops?.Produto}
                  disabled={Boolean(id)}
                />
              </Grid>
              <Grid item xs={12} textAlign="end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Próximo
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} display={tab === 1 ? 'block' : 'none'}>
          {getLoading ? (
            <Loader />
          ) : (
            <Card title="Selecionar Imagens">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ImageList cols={listCols ? 2 : 5} gap={10}>
                    {imagens?.map((item) => {
                      const checked = watch('images')?.some(
                        (s) => s?.id === item?.id
                      );
                      return (
                        <ImageListItem key={item.url}>
                          <Box
                            component="img"
                            sx={styles?.image}
                            srcSet={`${item.url}?fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.url}?fit=crop&auto=format`}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            sx={styles?.bar}
                            position="top"
                            actionIcon={
                              <Checkbox
                                checked={checked}
                                sx={styles?.checkbox}
                                onChange={(_, v) => {
                                  let arr = [...watch('images')];
                                  if (v) {
                                    arr = [...arr, item];
                                  } else {
                                    arr = arr?.filter(
                                      (f) => f?.id !== item?.id
                                    );
                                  }
                                  setValue('images', arr);
                                }}
                              />
                            }
                            actionPosition="left"
                          />
                        </ImageListItem>
                      );
                    })}
                  </ImageList>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setTab(0)}
                  >
                    Voltar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} display={tab === 2 ? 'block' : 'none'}>
          <Card title="Escolher Categoria">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TreeView
                  defaultCollapseIcon={<ArrowDropDown />}
                  defaultExpandIcon={<ArrowRight />}
                >
                  {categorias?.map(renderTree)}
                </TreeView>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setTab(1)}
                >
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Próximo
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} display={tab === 3 ? 'block' : 'none'}>
          <Card title="Selecionar Marca">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell>Marca</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {marcas?.map((m, i) => (
                        <TableRow key={`${i}`}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={m?.id === watch('marca')}
                              onChange={() => {
                                if (m?.id === watch('marca')) {
                                  setValue('marca', null);
                                } else {
                                  setValue('marca', m?.id);
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>{m?.display_brand_name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={watch('has_next_page') ? 100 : null}
                  rowsPerPage={10}
                  page={watch('page') - 1}
                  onPageChange={(_, p) => {
                    setValue('page', p + 1);
                    loadMarcas();
                  }}
                  labelRowsPerPage=""
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={() => ''}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setTab(2)}
                >
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Próximo
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} display={tab === 4 ? 'block' : 'none'}>
          <Card title="Adicionais / Envio">
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setTab(3)}
                >
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Concluir
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Manutenção;
