import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import Container from 'components/Container';
import moment from 'moment';

const EvoluçãoDoPreço = ({ indicadores }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetWidth / 2);
  }, []);

  const chart = indicadores?.indicadores?.preco;

  return (
    <Container>
      <Box ref={ref}>
        <LineChart
          xAxis={[
            {
              scaleType: 'point',
              valueFormatter: (v) =>
                Boolean(chart?.length)
                  ? moment(chart[v - 1]?.inicio).format('DD/MM/YY')
                  : [0],
              data: Boolean(chart?.length) ? chart?.map((_, i) => i + 1) : [0],
            },
          ]}
          series={[
            {
              curve: 'linear',
              color: '#22267B',
              label: 'Valor em R$ (Últimas 10 Modificações)',
              data: Boolean(chart?.length)
                ? chart?.map((g) => g?.preco || 0)
                : [0],
            },
          ]}
          width={width}
          height={height}
        />
      </Box>
    </Container>
  );
};

export default EvoluçãoDoPreço;
