export const dropBoolean = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

export const dropAtivo = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
  { value: 'PENDENTE', label: 'PENDENTE' },
];

export const dropTipoPessoa = [
  { value: 'FISICA', label: 'FÍSICA' },
  { value: 'JURIDICA', label: 'JURÍDICA' },
];

export const dropTipoPerfil = [
  { value: 'CLIENTE', label: 'CLIENTE' },
  { value: 'FORNECEDOR', label: 'FORNECEDOR' },
  { value: 'TRANSPORTADOR', label: 'TRANSPORTADOR' },
  { value: 'FILIAL', label: 'FILIAL' },
  { value: 'USUARIO', label: 'USUARIO' },
  { value: 'FUNCIONARIO', label: 'FUNCIONARIO' },
  { value: 'MOTORISTA', label: 'MOTORISTA' },
  { value: 'VENDEDOR', label: 'VENDEDOR' },
  { value: 'REPRESENTANTE', label: 'REPRESENTANTE' },
  { value: 'INSTITUICAO FINANCEIRA', label: 'INSTITUICAO FINANCEIRA' },
  { value: 'OPERADORA DE CARTAO', label: 'OPERADORA DE CARTAO' },
  { value: 'CONTATO', label: 'CONTATO' },
];

export const dropEstadoCivil = [
  { value: 'SOLTEIRO', label: 'SOLTEIRO' },
  { value: 'CASADO', label: 'CASADO' },
  { value: 'DIVORCIADO', label: 'DIVORCIADO' },
  { value: 'DESQUITADO', label: 'DESQUITADO' },
  { value: 'VIÚVO', label: 'VIÚVO' },
];

export const dropSexo = [
  { value: 'MASCULINO', label: 'MASCULINO' },
  { value: 'FEMININO', label: 'FEMININO' },
];

export const dropRegimeTributario = [
  { value: 'LUCRO REAL', label: 'LUCRO REAL' },
  { value: 'LUCRO PRESUMIDO', label: 'LUCRO PRESUMIDO' },
  { value: 'SIMPLES NACIONAL', label: 'SIMPLES NACIONAL' },
];

export const dropRegimeEspecial = [
  { value: 'E-COMMERCE NÃO VINCULADO', label: 'E-COMMERCE NÃO VINCULADO' },
];

export const dropClassificaçãoTributária = [
  {
    value: '00-Industrial - Transformação',
    label: '00-Industrial - Transformação',
  },
  {
    value: '01-Industrial - Beneficiamento',
    label: '01-Industrial - Beneficiamento',
  },
  { value: '03-Industrial - Montagem', label: '03-Industrial - Montagem' },
  {
    value: '04-Industrial - Renovação ou Recondicionamento',
    label: '04-Industrial - Renovação ou Recondicionamento',
  },
  {
    value: '05-Equiparado a industria - Por opção',
    label: '05-Equiparado a industria - Por opção',
  },
  {
    value: '06-Equiparado a industria - Importação direta',
    label: '06-Equiparado a industria - Importação direta',
  },
  {
    value: '07-Equiparado a industria - por lei específica',
    label: '07-Equiparado a industria - por lei específica',
  },
  {
    value: '08-Equiparado a industria - Não enquadrado',
    label: '08-Equiparado a industria - Não enquadrado',
  },
  { value: '09-Outros', label: '09-Outros' },
];

export const dropTipoCartao = [
  { value: 'DEBITO', label: 'DEBITO' },
  { value: 'CREDITO', label: 'CREDITO' },
  { value: 'TICKET', label: 'TICKET' },
];

export const dropTipoMascara = [
  { value: 'FIXO', label: 'FIXO' },
  { value: 'MOVEL', label: 'MOVEL' },
];

export const dropTipoMovimentacao = [
  { value: 'UNIDADE', label: 'UNIDADE' },
  { value: 'ESTRUTURA', label: 'ESTRUTURA' },
];

export const dropTipoDescricao = [
  { value: 'REDUZIDA', label: 'REDUZIDA' },
  { value: 'COMPLETA', label: 'COMPLETA' },
  { value: 'INGLES', label: 'INGLES' },
  { value: 'ESPANHOL', label: 'ESPANHOL' },
];

export const dropStatusIntegracao = [
  { value: 'PUBLICADO', label: 'PUBLICADO' },
];

export const dropEstruturaLinhaProduto = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
  { value: 'CLIENTE', label: 'CLIENTE' },
];

export const dropIdSped = [
  { value: 'ID', label: 'ID' },
  { value: 'REFERENCIA', label: 'REFERENCIA' },
];

export const dropCusto = [
  { value: 'PADRAO', label: 'PADRAO' },
  { value: 'ESTRUTURA', label: 'ESTRUTURA' },
];

export const dropTipoItem = [
  { value: '00-Mercadoria para revenda', label: '00-Mercadoria para revenda' },
  { value: '01-Matéria prima', label: '01-Matéria prima' },
  { value: '02-Embalagem', label: '02-Embalagem' },
  { value: '03-Produtos em processo', label: '03-Produtos em processo' },
  { value: '04-Produto acabado', label: '04-Produto acabado' },
  { value: '05-Subproduto', label: '05-Subproduto' },
  { value: '06-Produto intermediário', label: '06-Produto intermediário' },
  {
    value: '07-Material de uso e consumo',
    label: '07-Material de uso e consumo',
  },
  { value: '08-Ativo imobilizado', label: '08-Ativo imobilizado' },
  { value: '09-Serviços', label: '09-Serviços' },
  { value: '10-Outros insumos', label: '10-Outros insumos' },
  { value: '99-Outras', label: '99-Outras' },
];

export const dropOrigem = [
  { value: 'FABRICADO', label: 'FABRICADO' },
  { value: 'COMPRADO', label: 'COMPRADO' },
];

export const dropCondicaoMl = [
  { value: 'new', label: 'Novo' },
  { value: 'used', label: 'Usado' },
];

export const dropStatusMl = [
  { value: 'active', label: 'ATIVO' },
  { value: 'paused', label: 'PAUSADO' },
  { value: 'closed', label: 'FECHADO' },
];

export const dropStatusShopee = [
  { value: 'NORMAL', label: 'NORMAL' },
  { value: 'BANNED', label: 'BANIDO' },
  { value: 'SELLER_DELETE', label: 'EXCLUIDO PELO VENDEDOR' },
  { value: 'SHOPEE_DELETE', label: 'EXCLUIDO PELA SHOPEE' },
  { value: 'REVIEWING', label: 'EM REVISÃO' },
];

export const dropLogisticaMl = [
  { value: 'fulfillment', label: 'Full' },
  { value: 'cross_docking', label: 'Coleta' },
  { value: 'drop_off', label: 'Comum' },
  { value: 'self_service', label: 'Flex' },
  { value: 'xd_drop_off', label: 'Places' },
];

export const dropListagemMl = [
  { value: 'Premium', label: 'Premium' },
  { value: 'Diamante', label: 'Diamante' },
  { value: 'Clássico', label: 'Clássico' },
  { value: 'Ouro', label: 'Ouro' },
  { value: 'Prata', label: 'Prata' },
];

export const dropTipoConta = [
  { value: 'APLICAÇÃO', label: 'APLICAÇÃO' },
  { value: 'CAIXA', label: 'CAIXA' },
  { value: 'CORRENTE', label: 'CORRENTE' },
  { value: 'DESCONTO', label: 'DESCONTO' },
];

export const dropDivisao = [
  { value: 'QUANTIDADE', label: 'QUANTIDADE' },
  { value: 'VALOR', label: 'VALOR' },
];

export const dropModulos = [
  { value: 'COMPRAS', label: 'COMPRAS' },
  { value: 'VENDAS', label: 'VENDAS' },
];

export const dropModulo = [
  { value: 'COMPRAS', label: 'COMPRAS' },
  { value: 'VENDAS', label: 'VENDAS' },
  { value: 'GERAL', label: 'GERAL' },
];

export const dropIndPag = [
  { value: '0-A VISTA', label: '0-A VISTA' },
  { value: '1-A PRAZO', label: '1-A PRAZO' },
];

export const dropModalidade = [
  { value: '01-DINHEIRO', label: '01-DINHEIRO' },
  { value: '02-CHEQUE', label: '02-CHEQUE' },
  { value: '03-CARTAO DE CREDITO', label: '03-CARTAO DE CREDITO' },
  { value: '04-CARTAO DE DEBITO', label: '04-CARTAO DE DEBITO' },
  { value: '05-CRÉDITO LOJA', label: '05-CRÉDITO LOJA' },
  { value: '10-VALE ALIMENTAÇÃO', label: '10-VALE ALIMENTAÇÃO' },
  { value: '11-VALE REFEIÇÃO', label: '11-VALE REFEIÇÃO' },
  { value: '12-VALE PRESENTE', label: '12-VALE PRESENTE' },
  { value: '13-VALE COMBUSTIVEL', label: '13-VALE COMBUSTIVEL' },
  { value: '15-BOLETO BANCÁRIO', label: '15-BOLETO BANCÁRIO' },
  { value: '90-SEM PAGAMENTO', label: '90-SEM PAGAMENTO' },
  { value: '98-REFINANCIAMENTO', label: '98-REFINANCIAMENTO' },
  { value: '99-OUTROS', label: '99-OUTROS' },
];

export const dropTipoRodado = [
  { value: '01 - TRUCK', label: '01 - TRUCK' },
  { value: '02 - TOCO', label: '02 - TOCO' },
  { value: '03 - CAVALO MECÂNICO', label: '03 - CAVALO MECÂNICO' },
  { value: '04 - VAN', label: '04 - VAN' },
  { value: '05 - UTILITÁRIO', label: '05 - UTILITÁRIO' },
  { value: '06 - OUTROS', label: '06 - OUTROS' },
];

export const dropTipoCarroceria = [
  { value: '00 - NÃO APLICÁVEL', label: '00 - NÃO APLICÁVEL' },
  { value: '01 - ABERTA', label: '01 - ABERTA' },
  { value: '02 - FECHADA/BAÚ', label: '02 - FECHADA/BAÚ' },
  { value: '03 - GRANELERA', label: '03 - GRANELERA' },
  { value: '04 - PORTA CONTAINER', label: '04 - PORTA CONTAINER' },
  { value: '05 - SIDER', label: '05 - SIDER' },
];

export const dropTipoCarga = [
  { value: 'VIVA', label: 'VIVA' },
  { value: 'PERECIVEL', label: 'PERECIVEL' },
  { value: 'LIQUIDA', label: 'LIQUIDA' },
  { value: 'FRIGORIFICA', label: 'FRIGORIFICA' },
  { value: 'DIVERSOS', label: 'DIVERSOS' },
];

export const dropTipoUnidadeTransporte = [
  { value: '1 - RODOVIÁRIO TRAÇÃO', label: '1 - RODOVIÁRIO TRAÇÃO' },
  { value: '2 - RODOVIÁRIO REBOQUE', label: '2 - RODOVIÁRIO REBOQUE' },
  { value: '3 - NAVIO', label: '3 - NAVIO' },
  { value: '4 - BALSA', label: '4 - BALSA' },
  { value: '5 - AERONAVE', label: '5 - AERONAVE' },
  { value: '6 - VAGÃO', label: '6 - VAGÃO' },
  { value: '7 - OUTROS', label: '7 - OUTROS' },
];

export const dropTipoProprietario = [
  { value: '0 - TAC AGREGADO', label: '0 - TAC AGREGADO' },
  { value: '1 - TAC INDEPENDENTE', label: '1 - TAC INDEPENDENTE' },
  { value: '2 - OUTROS', label: '2 - OUTROS' },
];

export const dropOrgDest = [
  { value: 'MESMO ESTADO', label: 'MESMO ESTADO' },
  { value: 'OUTRO ESTADO', label: 'OUTRO ESTADO' },
  { value: 'OUTRO PAIS', label: 'OUTRO PAIS' },
];

export const dropStatus = [
  { value: 'ATIVO', label: 'ATIVO' },
  { value: 'INATIVO', label: 'INATIVO' },
];

export const dropOperacao = [
  { value: 'DEBITO', label: 'DÉBITO' },
  { value: 'CREDITO', label: 'CRÉDITO' },
];

export const dropTipoPlanoconta = [
  { value: 'ANALITICA', label: 'ANALÍTICA' },
  { value: 'SINTETICA', label: 'SINTÉTICA' },
];

export const dropTipoDespesa = [
  { value: 'FIXA', label: 'FIXA' },
  { value: 'VARIAVEL', label: 'VARIÁVEL' },
];

export const dropNTributado = [
  { value: 'ISENTO', label: 'ISENTO' },
  { value: 'OUTROS', label: 'OUTROS' },
  { value: 'NENHUM', label: 'NENHUM' },
];

export const dropOperacaoTd = [
  { value: 'SOMA', label: 'SOMA' },
  { value: 'SUBTRAI', label: 'SUBTRAI' },
  { value: 'NENHUMA', label: 'NENHUMA' },
];

export const dropBaseTeto = [
  { value: 'TRIBUTO', label: 'TRIBUTO' },
  { value: 'TOTAL DOCUMENTO', label: 'TOTAL DOCUMENTO' },
];

export const dropContaContra = [
  { value: 'CAIXA E BANCO', label: 'CAIXA E BANCO' },
  { value: 'CARTÕES', label: 'CARTÕES' },
  { value: 'CHEQUES', label: 'CHEQUES' },
  { value: 'COMPENSAÇÃO', label: 'COMPENSAÇÃO' },
  { value: 'GERAÇÃO DE HAVER', label: 'GERAÇÃO DE HAVER' },
  { value: 'OUTROS', label: 'OUTROS' },
  { value: 'TRANSFERÊNCIAS', label: 'TRANSFERÊNCIAS' },
  { value: 'UTILIZAÇÃO DE HAVER', label: 'UTILIZAÇÃO DE HAVER' },
];

export const dropCtaComplemento = [
  { value: 'ENTIDADE', label: 'ENTIDADE' },
  { value: 'CONTA', label: 'CONTA' },
  { value: 'CTABANCO', label: 'CTABANCO' },
  { value: 'NAO CONTABILIZAR', label: 'NAO CONTABILIZAR' },
];

export const dropParcelas = [
  { value: 'TODAS', label: 'TODAS' },
  { value: 'PRIMEIRA', label: 'PRIMEIRA' },
  { value: 'ULTIMA', label: 'ULTIMA' },
];

export const dropBcIcms = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
  { value: 'SUBTRAI', label: 'SUBTRAI' },
];
