import React, { useContext, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DropsContext } from 'contexts/DropsContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { NotificaçõesContext } from 'contexts/NotificaçõesContext';
import { useDialog } from 'components/Modals';
import RelatorioModal from 'components/Modals/RelatorioModal';
import Navbar from 'components/Navbar';
import Routes from 'routes';

const styles = {
  main: {
    height: '100vh',
  },
  container: {
    padding: '88px 24px 0 24px',
  },
};

const App = () => {
  const { openDialog } = useDialog();
  const { getDrops } = useContext(DropsContext);
  const { relatorio } = useContext(RelatoriosContext);
  const { getNotifications } = useContext(NotificaçõesContext);

  useEffect(() => {
    getNotifications();
    getDrops([
      'Anp',
      'AtividadeEntidade',
      'Ccusto',
      'Cfiscal',
      'Cfo',
      'Cidade',
      'ClasseCnae',
      'CondicaoPagamento',
      'Contribuinte',
      'Cxconta',
      'DivisaoCnae',
      'Entidade',
      'EntidadeTipo',
      'EntidadeTributacao',
      'EtiquetaModelo',
      'Filial',
      'FormaDePagamento',
      'GrupoCnae',
      'GrupoEntidade',
      'GrupoProduto',
      'Impressoraweb',
      'IntegraAccess',
      'LinhaProduto',
      'Logo',
      'Marca',
      'ModalidadeBc',
      'Moeda',
      'NaturezaOperacao',
      'OrigemTributaria',
      'Pais',
      'Plano',
      'Planoconta',
      'Planoentidade',
      'Produto',
      'RegiaoEntidade',
      'SecaoCnae',
      'SituacaoTributaria',
      'TabelaPreco',
      'TipoContato',
      'TipoDocumento',
      'TipoEndereco',
      'TipoVinculo',
      'Tributo',
      'UF',
      'UnidadeMedida',
      'Users',
    ]);
  }, []);

  useEffect(() => {
    if (relatorio) {
      openDialog(<RelatorioModal relatorio={relatorio} />, relatorio?.nome);
    }
  }, [relatorio]);

  return (
    <div style={styles.main}>
      <Navbar />
      <Scrollbars>
        <div style={styles.container}>
          <Routes />
        </div>
      </Scrollbars>
    </div>
  );
};

export default App;
