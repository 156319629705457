import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  descricao: '',
  modulo: [],
  ativo: null,
};

export const CondicaoDePagamentoContext = createContext();

export const CondicaoDePagamentoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [condicaoDePagamento, setCondicaoDePagamento] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getCondicaoDePagamento = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: condicaoDePagamento?.filter,
        page: condicaoDePagamento?.page,
        size: condicaoDePagamento?.size,
        order: condicaoDePagamento?.order,
        ...payload,
      });
      setCondicaoDePagamento((prev) => ({
        ...prev,
        ...payload,
        ...data,
      }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <CondicaoDePagamentoContext.Provider
      value={{
        getLoading,
        condicaoDePagamento,
        getCondicaoDePagamento,
      }}
    >
      {children}
    </CondicaoDePagamentoContext.Provider>
  );
};
