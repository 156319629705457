import React from 'react';
import { Grid } from '@mui/material';
import { dropBoolean } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('vlrmaxaprova') && (
        <Grid item xs={12}>
          <InputMask
            name="vlrmaxaprova"
            control={control}
            label="Valor Máximo Aprovado"
          />
        </Grid>
      )}
      {checkDisplay('aprova') && (
        <Grid item xs={12}>
          <Dropdown
            multiple
            name="aprova"
            control={control}
            label="Aprovação"
            options={drops?.Users}
          />
        </Grid>
      )}
      {checkDisplay('analisa') && (
        <Grid item xs={12}>
          <Dropdown
            multiple
            name="analisa"
            control={control}
            label="Análise"
            options={drops?.Users}
          />
        </Grid>
      )}
      {checkDisplay('ativo') && (
        <Grid item xs={12}>
          <Dropdown
            name="ativo"
            control={control}
            label="Ativo"
            options={dropBoolean}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
