import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDialog } from 'components/Modals';
import { dropTipoCartao } from 'utils/drops';
import { AuthContext } from 'contexts/AuthContext';
import { EntidadesContext } from 'contexts/EntidadesContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Operadoras = ({
  drops,
  onSubmit,
  onDelete,
  entidade = {},
  disableActions,
}) => {
  const { openDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { postLoading } = useContext(EntidadesContext);
  const rotina = 'EntidadeOperadora';

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      entidade_id: entidade?.id,
      descricao: item?.descricao || '',
      tipocartao: item?.tipocartao || null,
      parcelas: item?.parcelas || '',
      taxa: item?.taxa || '',
      diavenc: item?.diavenc || '',
      planoconta_id: item?.planoconta_id || null,
      forma_pagto_id: item?.forma_pagto_id || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="tipocartao"
            control={control}
            label="Tipo de Cartão"
            options={dropTipoCartao}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="parcelas"
            control={control}
            label="Parcelas"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="taxa" control={control} label="Taxa" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="diavenc"
            control={control}
            label="Dia do Vencimento"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="planoconta_id"
            control={control}
            label="Plano Conta"
            options={drops?.Planoconta?.filter(
              (p) =>
                p?.operacional === 'SIM' &&
                p?.filial ===
                  (entidade?.EntidadeTipos?.find((t) => t?.tipo === 'CLIENTE')
                    ?.entidade_filial_id || user?.filial_id)
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="forma_pagto_id"
            control={control}
            label="Forma de Pagamento"
            options={drops?.FormaDePagamento}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Operadoras: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Operadoras" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Operadora')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!entidade?.EntidadeOperadoras?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Tipo de Cartão</TableCell>
                    <TableCell>Parcelas</TableCell>
                    <TableCell>Taxa</TableCell>
                    <TableCell>Dia do Vencimento</TableCell>
                    <TableCell>Forma de Pagamento</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entidade?.EntidadeOperadoras?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.descricao}</TableCell>
                      <TableCell>{item?.tipocartao}</TableCell>
                      <TableCell>{item?.parcelas}</TableCell>
                      <TableCell>{item?.taxa}</TableCell>
                      <TableCell>{item?.diavenc}</TableCell>
                      <TableCell>{item?.FormaPagto?.descricao}</TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Operadora'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Operadoras;
