import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  ativo: 'SIM',
  descricao: '',
  referencia: '',
  preco: '',
  localizador: '',
  unidade: [],
  linha: [],
  grupo: [],
  marca: [],
  ncm: [],
  origem_tributaria: [],
  complemento1: '',
  complemento2: '',
  rastreavel: null,
  variacao: null,
  especificacao: '',
};

export const ProdutosContext = createContext();

export const ProdutosProvider = ({ children }) => {
  const [produto, setProduto] = useState(null);
  const [produtoIndicadores, setProdutoIndicadores] = useState(null);
  const [getLoading, setGetLoading] = useState(false);
  const [putLoading, setPutLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [produtos, setProdutos] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getProdutos = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: produtos?.filter,
        page: produtos?.page,
        size: produtos?.size,
        order: produtos?.order,
        ...payload,
      });
      setProdutos((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getProduto = async (id) => {
    try {
      setGetLoading(true);
      setProduto(null);
      const { data } = await api.get(`/Cadastros/Produto/${id}`, {
        params: {
          Midias: true,
          Similar: true,
          Variacao: true,
          Registros: true,
          Descricao: true,
          Estrutura: true,
          Processos: true,
          Integracao: true,
        },
      });
      setProduto(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postProduto = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Produto', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const putProdutoStatus = async ({ id, cb }) => {
    try {
      setPutLoading(true);
      await api.put(`/Cadastros/Produto/Status/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPutLoading(false);
    }
  };

  const putProdutoCusto = async ({ data, cb }) => {
    try {
      setPutLoading(true);
      await api.put(`/Cadastros/Produto/Custo`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPutLoading(false);
    }
  };

  const postCopiaEstrutura = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Produto/Copia/Estrutura', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postCopiaProcesso = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Produto/Copia/Processo', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postMidia = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Produto/Midia', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteMidia = async ({ id, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Cadastros/Produto/Midia/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const deleteIntegracao = async ({ id, path, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Integracoes/${path}/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const postIntegracao = async ({ data, path, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Integracoes/${path}`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postEtiquetas = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Produto/Etiquetas', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const getProdutoIndicadores = async (id) => {
    try {
      setGetLoading(true);
      setProdutoIndicadores(null);
      const { data } = await api.get(
        `/Graficos/CadastrosProdutoIndicadores/${id}`
      );
      setProdutoIndicadores(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postProdutoCopia = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Produto/Copia', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postProdutoPreco = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Produto/Preco', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <ProdutosContext.Provider
      value={{
        postProduto,
        postLoading,
        getLoading,
        getProdutos,
        produtos,
        putProdutoStatus,
        putLoading,
        getProduto,
        produto,
        postCopiaEstrutura,
        postCopiaProcesso,
        putProdutoCusto,
        deleteMidia,
        postMidia,
        deleteLoading,
        postIntegracao,
        postEtiquetas,
        deleteIntegracao,
        getProdutoIndicadores,
        produtoIndicadores,
        postProdutoCopia,
        postProdutoPreco,
      }}
    >
      {children}
    </ProdutosContext.Provider>
  );
};
