const styles = {
  card: {
    flex: 1,
    textAlign: 'center',
  },
  group: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
};

export default styles;
