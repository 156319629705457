import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { findOnArray } from 'utils/functions';
import { dropBoolean } from 'utils/drops';
import { EntidadesContext } from 'contexts/EntidadesContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Contatos = ({
  drops,
  onSubmit,
  onDelete,
  entidade = {},
  disableActions,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'EntidadeContato';

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      entidade_id: entidade?.id,
      tipo_contato_id: item?.tipo_contato_id || null,
      principal: item?.principal || 'NAO',
      conteudo: item?.conteudo || '',
      observacao: item?.observacao || '',
    };
    const { control, handleSubmit, watch, setValue } = useForm({
      defaultValues,
    });
    const { postLoading } = useContext(EntidadesContext);

    const InputContato = (props) => {
      const mascara = findOnArray(
        watch('tipo_contato_id'),
        drops?.TipoContato
      )?.tipo_mascara;

      switch (mascara) {
        case 'FIXO':
          return <InputMask {...props} mask="(99) 9999-9999" />;
        case 'MOVEL':
          return <InputMask {...props} mask="(99) 99999-9999" />;
        default:
          return <Input {...props} />;
      }
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="tipo_contato_id"
            control={control}
            label="Tipo de Contato"
            options={drops?.TipoContato}
            onValueChange={() => setValue('conteudo', '')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="principal"
            control={control}
            label="Principal"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputContato name="conteudo" control={control} label="Contato" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="observacao" control={control} label="Observação" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Contatos: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Contatos" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Contato')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!entidade?.EntidadeContatos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo de Contato</TableCell>
                    <TableCell>Contato</TableCell>
                    <TableCell>Observação</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entidade?.EntidadeContatos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.TipoContato?.descricao}</TableCell>
                      <TableCell>{item?.conteudo}</TableCell>
                      <TableCell>{item?.observacao}</TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Contato'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Contatos;
