const styles = {
  card: {
    marginTop: '1em',
  },
  button: {
    margin: '0 .5em',
  },
  icon: {
    color: 'primary.white',
  },
  pdfContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '150px',
  },
};

export default styles;
