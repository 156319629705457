const styles = {
  card: {
    marginTop: '1em',
  },
  icon: {
    color: 'primary.white',
  },
  checkbox: {
    color: 'white',
    '&.Mui-checked': {
      color: 'white',
    },
  },
};

export default styles;
