import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { dropBoolean } from 'utils/drops';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';

const Manutenção = () => {
  const rotina = 'GrupoEntidade';
  const defaultValues = {
    descricao: '',
    vlrmaxaprova: '',
    aprova_id: null,
    analisa_id: null,
    ativo: null,
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { drops, getDrops } = useContext(DropsContext);
  const { postLoading, postGrid } = useContext(GridContext);
  const { control, handleSubmit, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(state?.values);
  }, []);

  const onSubmit = (data) =>
    postGrid({
      data: { data, rotina },
      cb: () => {
        navigate(-1);
        getDrops([rotina]);
      },
    });

  return (
    <Container>
      <Header
        titulo={
          (Boolean(state) ? 'Editar' : 'Cadastrar') + ' Grupo da Entidade'
        }
      />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input name="descricao" control={control} label="Descrição" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="vlrmaxaprova"
              control={control}
              label="Valor Máximo Aprovado"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="ativo"
              control={control}
              label="Ativo"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="aprova_id"
              control={control}
              label="Aprovação"
              options={drops?.Users}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="analisa_id"
              control={control}
              label="Anãlise"
              options={drops?.Users}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Manutenção;
