import React from 'react';
import {
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from '@mui/material';
import {
  Delete,
  Download,
  OpenInFull,
  PictureAsPdf,
} from '@mui/icons-material';
import Button from 'components/Button';
import Card from 'components/Card';
import styles from '../styles';

const Midias = ({
  loading,
  entidade = {},
  onDeleteAnexo,
  onSubmitAnexo,
  disableActions,
}) => {
  const listCols = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const onConfirmMidia = (file) => {
    const formData = new FormData();
    formData.append('midia', file);
    formData.append('entidade_id', entidade?.id);
    onSubmitAnexo(formData);
  };

  const RenderMidias = () =>
    entidade?.EntidadeAnexos?.map((p, i) => (
      <ImageListItem key={i?.toString()}>
        {p?.Midium?.tipo === 'IMAGEM' ? (
          <img src={p?.Midium?.url} loading="lazy" />
        ) : (
          <div style={styles?.pdfContainer}>
            <PictureAsPdf fontSize="large" color="action" />
          </div>
        )}
        <ImageListItemBar
          title={p?.Midium?.descricao}
          actionIcon={
            <div style={styles?.pdfTitle}>
              <IconButton
                size="small"
                onClick={() => window.open(p?.Midium?.url)}
              >
                {p?.Midium?.tipo === 'IMAGEM' ? (
                  <OpenInFull fontSize="small" sx={styles?.icon} />
                ) : (
                  <Download fontSize="small" sx={styles?.icon} />
                )}
              </IconButton>
              <IconButton
                size="small"
                onClick={() => onDeleteAnexo(p?.Midium?.id)}
                disabled={loading}
              >
                <Delete fontSize="small" sx={styles?.icon} />
              </IconButton>
            </div>
          }
        />
      </ImageListItem>
    ));

  return (
    <Card title="Mídias" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => document.getElementById('upload-midia')?.click()}
              loading={loading}
            >
              <input
                id="upload-midia"
                type="file"
                hidden
                onChange={({ target }) => {
                  onConfirmMidia(target.files[0]);
                  target.value = '';
                }}
              />
              Adicionar
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          {!!entidade?.EntidadeAnexos?.length && (
            <ImageList cols={listCols ? 2 : 5}>
              <RenderMidias />
            </ImageList>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Midias;
