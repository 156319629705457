import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  descricao: '',
};

export const TipoEnderecoContext = createContext();

export const TipoEnderecoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [tipoEndereco, setTipoEndereco] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getTipoEndereco = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: tipoEndereco?.filter,
        page: tipoEndereco?.page,
        size: tipoEndereco?.size,
        order: tipoEndereco?.order,
        ...payload,
      });
      setTipoEndereco((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <TipoEnderecoContext.Provider
      value={{
        getLoading,
        tipoEndereco,
        getTipoEndereco,
      }}
    >
      {children}
    </TipoEnderecoContext.Provider>
  );
};
