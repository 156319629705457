import React from 'react';
import {
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  Delete,
  Download,
  OpenInFull,
  PictureAsPdf,
} from '@mui/icons-material';
import Button from 'components/Button';
import Card from 'components/Card';
import styles from '../styles';

const Midias = ({
  produto = {},
  loading,
  onSubmit,
  onDeleteMidia,
  onSubmitMidia,
  disableActions,
}) => {
  const listCols = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const onConfirmMidia = (file) => {
    const formData = new FormData();
    formData.append('midia', file);
    formData.append('produto_id', produto?.id);
    onSubmitMidia(formData);
  };

  const onConfirmMidias = (files = []) => {
    const formData = new FormData();
    Array.from(files).map((f) => {
      formData.append('midias', f);
    });
    formData.append('produto_id', produto?.id);
    onSubmitMidia(formData);
  };

  const onChangePosition = (index, move) => {
    const pictures = produto?.ProdutoImagems?.map((p) => ({
      id: p?.id,
      produto_id: produto?.id,
      midia_id: p?.midia_id || null,
    }));
    const element = pictures[index];
    pictures.splice(index, 1);
    pictures.splice(index + move, 0, element);
    const Imagens = pictures?.map((p, i) => ({ ...p, sequencia: i + 1 }));
    onSubmit({ Imagens });
  };

  const RenderMidias = () =>
    produto?.ProdutoImagems?.map((p, i) => (
      <ImageListItem key={i?.toString()}>
        {p?.Midium?.tipo === 'IMAGEM' ? (
          <img src={p?.Midium?.url} loading="lazy" />
        ) : (
          <div style={styles?.pdfContainer}>
            <PictureAsPdf fontSize="large" color="action" />
          </div>
        )}
        <ImageListItemBar
          title={p?.Midium?.descricao}
          actionIcon={
            <>
              <IconButton
                size="small"
                onClick={() => window.open(p?.Midium?.url)}
              >
                {p?.Midium?.tipo === 'IMAGEM' ? (
                  <OpenInFull fontSize="small" sx={styles?.icon} />
                ) : (
                  <Download fontSize="small" sx={styles?.icon} />
                )}
              </IconButton>
              {!disableActions && Boolean(i > 0) && (
                <IconButton
                  size="small"
                  onClick={() => onChangePosition(i, -1)}
                >
                  <ChevronLeft fontSize="small" sx={styles?.icon} />
                </IconButton>
              )}
              {!disableActions &&
                Boolean(i < produto?.ProdutoImagems?.length - 1) && (
                  <IconButton
                    size="small"
                    onClick={() => onChangePosition(i, 1)}
                  >
                    <ChevronRight fontSize="small" sx={styles?.icon} />
                  </IconButton>
                )}
              {!disableActions && (
                <IconButton
                  size="small"
                  onClick={() => onDeleteMidia(p?.Midium?.id)}
                  disabled={loading}
                >
                  <Delete fontSize="small" sx={styles?.icon} />
                </IconButton>
              )}
            </>
          }
        />
      </ImageListItem>
    ));

  return (
    <Card title="Mídias" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => document.getElementById('upload-midia')?.click()}
              loading={loading}
            >
              <input
                id="upload-midia"
                type="file"
                multiple
                hidden
                onChange={({ target }) => {
                  onConfirmMidias(target.files);
                  target.value = '';
                }}
              />
              Adicionar
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          {!!produto?.ProdutoImagems?.length && (
            <ImageList cols={listCols ? 2 : 5}>
              <RenderMidias />
            </ImageList>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Midias;
