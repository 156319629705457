import { useContext, useEffect, useState } from 'react';
import {
  Box,
  ButtonGroup,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Pagination,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { Check, Close, Delete, Edit } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { findOnArray } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { ContabilidadeContext } from 'contexts/ContabilidadeContext';
import { useModal } from 'components/Modals';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';
import DeleteModal from 'components/Modals/DeleteModal';
import FiltroModal from './Modals/FiltroModal';
import ManutençãoModal from './Modals/ManutençãoModal';
import styles from './styles';
import localeText from 'utils/localeText';
import moment from 'moment';

const IntegraçãoContábil = () => {
  const rotina = 'CgintegracaoRegra';
  const titulo = 'Regras de Integração Contábil';
  const defaultValues = {
    situacao_tributaria_id: null,
    natureza_operacao_id: null,
    planoentidade_id: null,
    forma_pagto_id: null,
    tributo_id: null,
    operacao: null,
  };
  const [steps, setSteps] = useState([
    { key: 'transacao', label: 'Transação', value: null, description: '' },
  ]);
  const [regras, setRegras] = useState([]);
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { deleteGrid, deleteLoading, postGrid, postLoading } =
    useContext(GridContext);
  const { getLoading, getRegras } = useContext(ContabilidadeContext);
  const { control, handleSubmit } = useForm({ defaultValues });
  const last = steps[steps?.length - 1];
  const filtros = [
    { key: 'tributo_id', label: 'Tributo' },
    { key: 'operacao', label: 'Operação' },
    { key: 'transacao', label: 'Transação' },
    { key: 'planoentidade_id', label: 'Plano / Entidade' },
    { key: 'situacao_tributaria_id', label: 'Situação Tributária' },
    { key: 'forma_pagto_id', label: 'Forma de Pagamento' },
    { key: 'natureza_operacao_id', label: 'Natureza da Operação' },
  ]?.filter((f) => !steps?.some((s) => s?.key === f?.key));

  const loadRegras = () => {
    setRegras([]);
    const params = {};
    steps?.map(({ key, value }) => {
      if (Boolean(key)) {
        params[key] = value;
      }
    });
    getRegras({ params, cb: setRegras });
  };

  useEffect(() => {
    if (!Boolean(last?.key)) {
      loadRegras();
    }
  }, [last]);

  const CardBlank = () => {
    const changeLastOccurrence = (str = '') => {
      const lastIndex = str?.lastIndexOf(',');
      if (lastIndex === -1) {
        return str;
      }
      return str?.slice(0, lastIndex) + ' e' + str?.slice(lastIndex + 1);
    };
    const rules = steps
      ?.map((s) => s?.description)
      ?.join(', ')
      ?.slice(0, -2);
    const title = `Regras para ${changeLastOccurrence(rules)}`?.toUpperCase();

    const StatusCell = ({ row }) => {
      let ativo = false;
      if (!Boolean(row?.inivalid) && !Boolean(row?.fimvalid)) {
        ativo = true;
      }
      if (Boolean(row?.inivalid) && !Boolean(row?.fimvalid)) {
        ativo = moment().isSameOrAfter(moment(row?.inivalid));
      }
      if (!Boolean(row?.inivalid) && Boolean(row?.fimvalid)) {
        ativo = moment().isSameOrBefore(moment(row?.fimvalid));
      }
      if (Boolean(row?.inivalid) && Boolean(row?.fimvalid)) {
        ativo =
          moment().isSameOrAfter(moment(row?.inivalid)) &&
          moment().isSameOrBefore(moment(row?.fimvalid));
      }
      const props = {
        true: {
          color: 'success',
          label: 'Ativo',
          icon: <Check />,
        },
        false: {
          color: 'error',
          label: 'Inativo',
          icon: <Close />,
        },
      };
      return <Chip size="small" variant="outlined" {...props[ativo]} />;
    };

    const ContaCell = ({ row, field }) => {
      const lines = [
        {
          label: 'Complemento',
          value: row['cp' + field],
        },
        {
          label: 'Tamanho do Complemento',
          value: row['tmcp' + field],
        },
      ];
      return (
        <Box flex={1}>
          <Typography fontWeight="bold" my={0.5} variant="body1">
            {row[field]}
          </Typography>
          {lines?.map(
            ({ label, value }, i) =>
              Boolean(value) && (
                <Box key={i?.toString()} display="flex">
                  <Typography flex={1} variant="caption" fontWeight="bold">
                    {label}:{' '}
                  </Typography>
                  <Typography flex={1} variant="caption">
                    {value}
                  </Typography>
                </Box>
              )
          )}
        </Box>
      );
    };

    return (
      <Grid item xs={12} display="flex">
        <Card title={title} style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  openModal(<ManutençãoModal onSubmit={onSubmitRegra} />)
                }
              >
                Adicionar Regra
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                rows={regras}
                columns={[
                  {
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Ações',
                    width: 100,
                    getActions: ({ id, row }) => {
                      return [
                        <GridActionsCellItem
                          icon={<Edit />}
                          label="Editar"
                          onClick={() =>
                            openModal(
                              <ManutençãoModal
                                regra={row}
                                onSubmit={onSubmitRegra}
                              />
                            )
                          }
                        />,
                        <GridActionsCellItem
                          icon={<Delete />}
                          label="Excluir"
                          onClick={() =>
                            openModal(
                              <DeleteModal
                                onSubmit={() =>
                                  deleteGrid({
                                    params: { rotina, id },
                                    cb: () => {
                                      closeModal();
                                      loadRegras();
                                    },
                                  })
                                }
                              />
                            )
                          }
                        />,
                      ];
                    },
                  },
                  {
                    field: 'status',
                    type: 'actions',
                    headerName: 'Status',
                    width: 100,
                    sortable: false,
                    renderCell: (props) => <StatusCell {...props} />,
                  },
                  {
                    field: 'cxconta',
                    headerName: 'Conta',
                    width: 300,
                    sortable: false,
                  },
                  {
                    field: 'bxcontra',
                    headerName: 'Contra Conta',
                    width: 200,
                    sortable: false,
                  },
                  {
                    field: 'ctacred',
                    headerName: 'Conta de Crédito',
                    width: 400,
                    sortable: false,
                    renderCell: (props) => <ContaCell {...props} />,
                  },
                  {
                    field: 'ctadeb',
                    headerName: 'Conta de Débito',
                    width: 400,
                    sortable: false,
                    renderCell: (props) => <ContaCell {...props} />,
                  },
                ]}
                autoHeight
                pagination
                pageSize={10}
                density="compact"
                disableColumnMenu
                showCellRightBorder
                showColumnRightBorder
                disableSelectionOnClick
                localeText={localeText}
                keepNonExistentRowsSelected
                getRowHeight={() => 'auto'}
                loading={getLoading || deleteLoading || postLoading}
                components={{
                  Pagination: CustomPagination,
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Grid>
            {Boolean(filtros?.length) && (
              <Grid item xs={12} textAlign="center">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    openModal(
                      <FiltroModal filtros={filtros} onSubmit={onFilter} />
                    )
                  }
                >
                  Continuar Filtrando
                </Button>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const CardTransacao = () => {
    const transacoes = [
      { value: 'BAIXA', label: 'BAIXA' },
      { value: 'CAIXA E BANCOS', label: 'CAIXA E BANCOS' },
      { value: 'COMERCIALIZACAO', label: 'COMERCIALIZAÇÃO' },
      { value: 'ESTOQUE', label: 'ESTOQUE' },
      { value: 'FINANCEIRO', label: 'FINANCEIRO' },
      { value: 'PESSOAL', label: 'PESSOAL' },
      { value: 'TRIBUTACAO', label: 'TRIBUTAÇÃO' },
    ];

    const onSubmit = ({ value, label: description }) => {
      setSteps((prev) => {
        const newArr = prev?.map((p) => {
          if (p?.key === 'transacao') {
            return { ...p, value, description };
          }
          return p;
        });
        newArr?.push({
          key: 'planoentidade_id',
          label: 'Plano / Entidade',
          value: null,
          description: '',
        });
        return newArr;
      });
    };

    return (
      <Grid item xs={12} display="flex">
        <Card title="Transação" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonGroup sx={styles?.btnGroup}>
                {transacoes?.map((t) => (
                  <Button
                    key={t?.value}
                    sx={styles?.btn}
                    onClick={() => onSubmit(t)}
                  >
                    {t?.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const CardPlanoEntidade = () => {
    const onSubmit = (values) => {
      onSubmitFiltro({
        key: 'planoentidade_id',
        value: values?.planoentidade_id,
        description: findOnArray(
          values?.planoentidade_id,
          drops?.Planoentidade,
          'label'
        ),
      });
    };

    return (
      <Grid item xs={12} display="flex">
        <Card title="Plano / Entidade" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Dropdown
                name="planoentidade_id"
                label="Plano / Entidade"
                control={control}
                options={drops?.Planoentidade}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const CardTributo = () => {
    const onSubmit = (values) => {
      onSubmitFiltro({
        key: 'tributo_id',
        value: values?.tributo_id,
        description: findOnArray(values?.tributo_id, drops?.Tributo, 'label'),
      });
    };

    return (
      <Grid item xs={12} display="flex">
        <Card title="Tributo" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Dropdown
                name="tributo_id"
                label="Tributo"
                control={control}
                options={drops?.Tributo}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const CardOperacao = () => {
    const [selected, setSelected] = useState(null);
    const operacoes = [
      { value: 'ENTRADA', label: 'ENTRADA' },
      { value: 'SAIDA', label: 'SAÍDA' },
    ];
    const onSubmit = () => {
      const op = operacoes?.find((f) => f?.value === selected);
      onSubmitFiltro({
        key: 'operacao',
        value: op?.value,
        description: op?.label,
      });
    };

    return (
      <Grid item xs={12} display="flex">
        <Card title="Operação" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  value={selected}
                  onChange={(_, v) => setSelected(v)}
                >
                  {operacoes?.map((f) => (
                    <FormControlLabel
                      key={f?.value}
                      value={f?.value}
                      label={f?.label}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const CardSitTrib = () => {
    const onSubmit = (values) => {
      onSubmitFiltro({
        key: 'situacao_tributaria_id',
        value: values?.situacao_tributaria_id,
        description: findOnArray(
          values?.situacao_tributaria_id,
          drops?.SituacaoTributaria,
          'label'
        ),
      });
    };

    return (
      <Grid item xs={12} display="flex">
        <Card title="Situação Tributária" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Dropdown
                name="situacao_tributaria_id"
                label="Situação Tributária"
                control={control}
                options={drops?.SituacaoTributaria}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const CardFormaPagto = () => {
    const onSubmit = (values) => {
      onSubmitFiltro({
        key: 'forma_pagto_id',
        value: values?.forma_pagto_id,
        description: findOnArray(
          values?.forma_pagto_id,
          drops?.FormaDePagamento,
          'label'
        ),
      });
    };

    return (
      <Grid item xs={12} display="flex">
        <Card title="Forma de Pagamento" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Dropdown
                name="forma_pagto_id"
                label="Forma de Pagamento"
                control={control}
                options={drops?.FormaDePagamento}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const CardNatureza = () => {
    const onSubmit = (values) => {
      onSubmitFiltro({
        key: 'natureza_operacao_id',
        value: values?.natureza_operacao_id,
        description: findOnArray(
          values?.natureza_operacao_id,
          drops?.NaturezaOperacao,
          'label'
        ),
      });
    };

    return (
      <Grid item xs={12} display="flex">
        <Card title="Natureza da Operação" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Dropdown
                name="natureza_operacao_id"
                label="Natureza da Operação"
                control={control}
                options={drops?.NaturezaOperacao}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const render = () => {
    switch (last?.key) {
      case 'transacao':
        return <CardTransacao />;
      case 'planoentidade_id':
        return <CardPlanoEntidade />;
      case 'tributo_id':
        return <CardTributo />;
      case 'operacao':
        return <CardOperacao />;
      case 'situacao_tributaria_id':
        return <CardSitTrib />;
      case 'forma_pagto_id':
        return <CardFormaPagto />;
      case 'natureza_operacao_id':
        return <CardNatureza />;
      case null || '':
        return <CardBlank />;

      default:
        return null;
    }
  };

  const onBack = () => {
    setSteps((prev) => {
      let newArr = [...prev];
      newArr?.pop();
      newArr[newArr?.length - 1]['value'] = null;
      newArr[newArr?.length - 1]['description'] = '';
      return newArr;
    });
  };

  const onFilter = (filtro) => {
    setSteps((prev) => {
      let newArr = [...prev];
      newArr[newArr?.length - 1] = {
        ...newArr[newArr?.length - 1],
        ...filtro,
      };
      return newArr;
    });
    closeModal();
  };

  const onSubmitRegra = (values) => {
    const data = { ...values };
    steps?.map(({ key, value }) => {
      if (Boolean(key)) {
        data[key] = value;
      }
    });
    postGrid({
      data: { data, rotina },
      cb: () => {
        closeModal();
        loadRegras();
      },
    });
  };

  const onSubmitFiltro = ({ key = '', value = null, description = '' }) => {
    if (!Boolean(value)) {
      return toastWarning('Preencha os campos');
    }
    setSteps((prev) => {
      const newArr = prev?.map((p) => {
        if (p?.key === key) {
          return { ...p, value, description };
        }
        return p;
      });
      newArr?.push({
        key: '',
        label: '',
        value: null,
        description: '',
      });
      return newArr;
    });
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        <Grid item xs={2} display="flex">
          <Card style={styles?.card}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stepper activeStep={steps?.length - 1} orientation="vertical">
                  {steps
                    ?.filter((f) => Boolean(f?.key))
                    ?.map(
                      ({
                        key = '',
                        label = '',
                        value = null,
                        description = '',
                      }) => (
                        <Step key={key}>
                          <StepLabel>
                            <Typography variant="subtitle2">{label}</Typography>
                            {Boolean(value && description) && (
                              <Typography variant="caption">
                                {description}
                              </Typography>
                            )}
                          </StepLabel>
                        </Step>
                      )
                    )}
                </Stepper>
              </Grid>
              {steps?.length > 1 && (
                <Grid item xs={12} textAlign="center">
                  <Button variant="outlined" color="secondary" onClick={onBack}>
                    Voltar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={10} display="flex">
          <Grid container spacing={2}>
            {render()}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default IntegraçãoContábil;
