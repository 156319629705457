import React, { useContext, useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Grid, Icon, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useModal } from 'components/Modals';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { FormaDePagamentoContext } from 'contexts/FormaDePagamentoContext';
import DeleteModal from 'components/Modals/DeleteModal';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Loader from 'components/Loader';

const CondicoesModal = ({ item }) => {
  const [rows, setRows] = useState([]);
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { postLoading, postGrid, deleteGrid } = useContext(GridContext);
  const { getFormaCondicoes, getLoading } = useContext(FormaDePagamentoContext);
  const rotina = 'FormaCondicao';
  const defaultValues = {
    forma_pagto_id: item?.id || null,
    condicao_pagamento_id: null,
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  const loadData = () =>
    getFormaCondicoes({ id: item?.id, cb: (data) => setRows(data) });

  useEffect(() => {
    loadData();
  }, []);

  const onSubmit = (data) =>
    postGrid({
      data: { data, rotina },
      cb: loadData,
    });

  const onDelete = (row) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params: { rotina, id: row?.id },
            cb: () => {
              loadData();
              closeModal();
            },
          })
        }
      />
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          {item?.descricao}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          name="condicao_pagamento_id"
          control={control}
          label="Condição de Pagamento"
          options={drops?.CondicaoPagamento}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Adicionar
        </Button>
      </Grid>
      <Grid item xs={12}>
        {getLoading ? (
          <Loader />
        ) : (
          <DataGrid
            rows={rows}
            columns={[
              {
                field: 'condicao',
                headerName: 'Condições de Pagamento',
                flex: 4,
                sortable: false,
              },
              {
                field: 'actions',
                headerName: 'Ações',
                type: 'actions',
                flex: 1,
                getActions: ({ row }) => [
                  <GridActionsCellItem
                    icon={<Icon>delete</Icon>}
                    label="Excluir"
                    onClick={() => onDelete(row)}
                  />,
                ],
              },
            ]}
            hideFooter
            autoHeight
            density="compact"
            disableSelectionOnClick
            disableColumnMenu
            showCellRightBorder
            showColumnRightBorder
            localeText={{ noRowsLabel: 'Nenhum Registro.' }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CondicoesModal;
