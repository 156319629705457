import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  dropBoolean,
  dropOperacao,
  dropTipoPlanoconta,
  dropTipoDespesa,
} from 'utils/drops';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import Container from 'components/Container';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';

const Manutenção = () => {
  const rotina = 'Planoconta';
  const defaultValues = {
    descricao: '',
    plano_id: null,
    conta: '',
    operacao: null,
    tipo: null,
    tpdesp: null,
    grau: '',
    operacional: null,
    conciliacao: null,
    cac: null,
    contasup_id: null,
    contacp_id: null,
    contaecd: '',
    orientacao: '',
    inivalid: null,
    fimvalid: null,
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getDrops, drops } = useContext(DropsContext);
  const { postLoading, postGrid } = useContext(GridContext);
  const { control, handleSubmit, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(state?.values);
  }, []);

  const onSubmit = (data) =>
    postGrid({
      data: { data, rotina },
      cb: () => {
        navigate(-1);
        getDrops([rotina]);
      },
    });

  return (
    <Container>
      <Header
        titulo={(Boolean(state) ? 'Editar' : 'Cadastrar') + ' Plano de Conta'}
      />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input name="descricao" control={control} label="Descrição" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="plano_id"
              control={control}
              label="Plano"
              options={drops?.Plano}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="conta" control={control} label="Conta" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="operacao"
              control={control}
              label="Operação"
              options={dropOperacao}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="tipo"
              control={control}
              label="Tipo"
              options={dropTipoPlanoconta}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="tpdesp"
              control={control}
              label="Tipo de Despesa"
              options={dropTipoDespesa}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="grau" control={control} label="Grau" type="number" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="operacional"
              control={control}
              label="Operacional"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="conciliacao"
              control={control}
              label="Conciliacao"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="cac"
              control={control}
              label="CAC"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="contasup_id"
              control={control}
              label="Conta Superior"
              options={drops?.Planoconta}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="contacp_id"
              control={control}
              label="Conta Contrapartida"
              options={drops?.Planoconta?.filter(
                (p) => p?.operacional === 'SIM'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="contaecd" control={control} label="Conta ECD" />
          </Grid>
          <Grid item xs={12}>
            <Input name="orientacao" control={control} label="Orientação" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="inivalid"
              control={control}
              label="Início da Validade"
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="fimvalid"
              control={control}
              label="Fim da Validade"
              type="date"
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Manutenção;
