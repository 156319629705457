import React, { useContext } from 'react';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import Container from 'components/Container';
import TableContainer from 'components/TableContainer';

const Estoque = ({ indicadores }) => {
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const chart = indicadores?.indicadores?.estoque;

  const getReserva = (filial) =>
    getURLRelatorio({
      data: { codigo: 'RES0000001', prd: indicadores?.id, filial },
    });

  const getEstoque = (filial, local) =>
    getURLRelatorio({
      data: { codigo: 'MVE0000001', prd: indicadores?.id, filial, local },
    });

  return (
    <Container>
      <Grid container spacing={5}>
        {chart?.map((c, i) => (
          <Grid item key={i?.toString()} xs={12}>
            <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
              {c?.filial}
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Local</TableCell>
                    <TableCell>Saldo</TableCell>
                    <TableCell>Disponibilidade</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {c?.locais?.map((l, i) => (
                    <TableRow key={i?.toString()}>
                      <TableCell>{l?.local}</TableCell>
                      <TableCell>{l?.saldo}</TableCell>
                      <TableCell>
                        {l?.disponibilizar === 'SIM'
                          ? 'Disponível'
                          : 'Não Disponível'}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Relatório" placement="left">
                          <IconButton
                            size="small"
                            onClick={() =>
                              getEstoque(c?.filial_id, l?.local_id)
                            }
                          >
                            <InfoOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>Reserva</TableCell>
                    <TableCell>{c?.reserva}</TableCell>
                    <TableCell>Não Disponível</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Relatório" placement="left">
                        <IconButton
                          size="small"
                          onClick={() => getReserva(c?.filial_id)}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Estoque;
