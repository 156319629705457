import { AuthProvider } from 'contexts/AuthContext';
import { NotificaçõesProvider } from 'contexts/NotificaçõesContext';
import { GridProvider } from 'contexts/GridContext';
import { DropsProvider } from 'contexts/DropsContext';
import { EntidadesProvider } from 'contexts/EntidadesContext';
import { ProdutosProvider } from 'contexts/ProdutosContext';
import { ProdutosBlingProvider } from 'contexts/ProdutosBlingContext';
import { ProdutosMlProvider } from 'contexts/ProdutosMlContext';
import { ProdutosShopeeProvider } from 'contexts/ProdutosShopeeContext';
import { AtividadeEntidadeProvider } from 'contexts/AtividadeEntidadeContext';
import { TipoDocumentoProvider } from 'contexts/TipoDocumentoContext';
import { TipoContatoProvider } from 'contexts/TipoContatoContext';
import { TipoEnderecoProvider } from 'contexts/TipoEnderecoContext';
import { TipoVinculoProvider } from 'contexts/TipoVinculoContext';
import { ContribuinteProvider } from 'contexts/ContribuinteContext';
import { GrupoEntidadeProvider } from 'contexts/GrupoEntidadeContext';
import { RegiaoEntidadeProvider } from 'contexts/RegiaoEntidadeContext';
import { CidadeProvider } from 'contexts/CidadeContext';
import { UFProvider } from 'contexts/UFContext';
import { PaisProvider } from 'contexts/PaisContext';
import { ClasseCnaeProvider } from 'contexts/ClasseCnaeContext';
import { GrupoCnaeProvider } from 'contexts/GrupoCnaeContext';
import { DivisaoCnaeProvider } from 'contexts/DivisaoCnaeContext';
import { SecaoCnaeProvider } from 'contexts/SecaoCnaeContext';
import { GrupoProdutoProvider } from 'contexts/GrupoProdutoContext';
import { LinhaProdutoProvider } from 'contexts/LinhaProdutoContext';
import { AnpProvider } from 'contexts/AnpContext';
import { MarcaProvider } from 'contexts/MarcaContext';
import { ModeloProvider } from 'contexts/ModeloContext';
import { OrigemTributariaProvider } from 'contexts/OrigemTributariaContext';
import { UnidadeMedidaProvider } from 'contexts/UnidadeMedidaContext';
import { CfiscalProvider } from 'contexts/CfiscalContext';
import { LocalEstoqueProvider } from 'contexts/LocalEstoqueContext';
import { ContasProvider } from 'contexts/ContasContext';
import { FormaDePagamentoProvider } from 'contexts/FormaDePagamentoContext';
import { CondicaoDePagamentoProvider } from 'contexts/CondicaoDePagamentoContext';
import { MoedaProvider } from 'contexts/MoedaContext';
import { PlanocontaProvider } from 'contexts/PlanocontaContext';
import { CcustoProvider } from 'contexts/CcustoContext';
import { FrotaProvider } from 'contexts/FrotaContext';
import { AplicacaoProvider } from 'contexts/AplicacaoContext';
import { CfoProvider } from 'contexts/CfoContext';
import { SituacaoTributariaProvider } from 'contexts/SituacaoTributariaContext';
import { PrecoProvider } from 'contexts/PrecoContext';
import { ContabilidadeProvider } from 'contexts/ContabilidadeContext';
import { TributacaoProvider } from 'contexts/TributaçãoContext';
import { RelatoriosProvider } from 'contexts/RelatoriosContext';
import {
  DialogProvider,
  ModalProvider,
  DrawerProvider,
} from 'components/Modals';

const Providers = ({ children }) => (
  <AuthProvider>
    <NotificaçõesProvider>
      <GridProvider>
        <DropsProvider>
          <RelatoriosProvider>
            <EntidadesProvider>
              <ProdutosProvider>
                <ProdutosBlingProvider>
                  <ProdutosMlProvider>
                    <ProdutosShopeeProvider>
                      <AtividadeEntidadeProvider>
                        <TipoDocumentoProvider>
                          <TipoContatoProvider>
                            <TipoEnderecoProvider>
                              <TipoVinculoProvider>
                                <ContribuinteProvider>
                                  <GrupoEntidadeProvider>
                                    <RegiaoEntidadeProvider>
                                      <CidadeProvider>
                                        <UFProvider>
                                          <PaisProvider>
                                            <ClasseCnaeProvider>
                                              <GrupoCnaeProvider>
                                                <DivisaoCnaeProvider>
                                                  <SecaoCnaeProvider>
                                                    <GrupoProdutoProvider>
                                                      <LinhaProdutoProvider>
                                                        <AnpProvider>
                                                          <MarcaProvider>
                                                            <ModeloProvider>
                                                              <OrigemTributariaProvider>
                                                                <UnidadeMedidaProvider>
                                                                  <CfiscalProvider>
                                                                    <LocalEstoqueProvider>
                                                                      <ContasProvider>
                                                                        <FormaDePagamentoProvider>
                                                                          <CondicaoDePagamentoProvider>
                                                                            <MoedaProvider>
                                                                              <PlanocontaProvider>
                                                                                <CcustoProvider>
                                                                                  <FrotaProvider>
                                                                                    <AplicacaoProvider>
                                                                                      <CfoProvider>
                                                                                        <SituacaoTributariaProvider>
                                                                                          <PrecoProvider>
                                                                                            <ContabilidadeProvider>
                                                                                              <TributacaoProvider>
                                                                                                <ModalProvider>
                                                                                                  <DialogProvider>
                                                                                                    <DrawerProvider>
                                                                                                      {
                                                                                                        children
                                                                                                      }
                                                                                                    </DrawerProvider>
                                                                                                  </DialogProvider>
                                                                                                </ModalProvider>
                                                                                              </TributacaoProvider>
                                                                                            </ContabilidadeProvider>
                                                                                          </PrecoProvider>
                                                                                        </SituacaoTributariaProvider>
                                                                                      </CfoProvider>
                                                                                    </AplicacaoProvider>
                                                                                  </FrotaProvider>
                                                                                </CcustoProvider>
                                                                              </PlanocontaProvider>
                                                                            </MoedaProvider>
                                                                          </CondicaoDePagamentoProvider>
                                                                        </FormaDePagamentoProvider>
                                                                      </ContasProvider>
                                                                    </LocalEstoqueProvider>
                                                                  </CfiscalProvider>
                                                                </UnidadeMedidaProvider>
                                                              </OrigemTributariaProvider>
                                                            </ModeloProvider>
                                                          </MarcaProvider>
                                                        </AnpProvider>
                                                      </LinhaProdutoProvider>
                                                    </GrupoProdutoProvider>
                                                  </SecaoCnaeProvider>
                                                </DivisaoCnaeProvider>
                                              </GrupoCnaeProvider>
                                            </ClasseCnaeProvider>
                                          </PaisProvider>
                                        </UFProvider>
                                      </CidadeProvider>
                                    </RegiaoEntidadeProvider>
                                  </GrupoEntidadeProvider>
                                </ContribuinteProvider>
                              </TipoVinculoProvider>
                            </TipoEnderecoProvider>
                          </TipoContatoProvider>
                        </TipoDocumentoProvider>
                      </AtividadeEntidadeProvider>
                    </ProdutosShopeeProvider>
                  </ProdutosMlProvider>
                </ProdutosBlingProvider>
              </ProdutosProvider>
            </EntidadesProvider>
          </RelatoriosProvider>
        </DropsProvider>
      </GridProvider>
    </NotificaçõesProvider>
  </AuthProvider>
);

export default Providers;
