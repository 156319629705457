import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { DataGrid } from '@mui/x-data-grid';
import Container from 'components/Container';
import moment from 'moment';
import styles from '../styles';

const Compras = ({ indicadores }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [tab, setTab] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetWidth / 2);
  }, []);

  const chart = indicadores?.indicadores?.compras;
  const rows = indicadores?.indicadores?.historicoCompras || [];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs variant="fullWidth" value={tab} onChange={(_, v) => setTab(v)}>
            <Tab value={0} label="Quantidade" />
            <Tab value={1} label="Valor" />
            <Tab value={2} label="Peso" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Box ref={ref}>
            {tab === 0 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#28518A',
                    label: 'Devolução',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.Devolucao?.quantidade)
                      : [0],
                  },
                  {
                    color: '#01005E',
                    label: 'Compra',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.Compra?.quantidade)
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
            {tab === 1 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#28518A',
                    label: 'Devolução',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.Devolucao?.valor)
                      : [0],
                  },
                  {
                    color: '#01005E',
                    label: 'Compra',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.Compra?.valor)
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
            {tab === 2 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#28518A',
                    label: 'Devolução',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.Devolucao?.peso)
                      : [0],
                  },
                  {
                    color: '#01005E',
                    label: 'Compra',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.Compra?.peso)
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={styles?.gridBox}>
            <DataGrid
              rows={rows}
              columns={[
                {
                  field: 'dtemissao',
                  headerName: 'Data de Emissão',
                  width: 200,
                  sortable: false,
                  valueGetter: ({ value }) =>
                    value && moment(value).format('DD/MM/YYYY'),
                  cellClassName: ({ row }) =>
                    row?.devolucao === 'SIM' && 'isDevolucao',
                },
                {
                  field: 'documento',
                  headerName: 'Documento',
                  width: 150,
                  sortable: false,
                  cellClassName: ({ row }) =>
                    row?.devolucao === 'SIM' && 'isDevolucao',
                },
                {
                  field: 'entidade',
                  headerName: 'Fornecedor',
                  width: 400,
                  sortable: false,
                  cellClassName: ({ row }) =>
                    row?.devolucao === 'SIM' && 'isDevolucao',
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  width: 150,
                  sortable: false,
                  valueGetter: ({ row }) =>
                    row?.quantidade && row?.unidade_medida
                      ? `${row?.quantidade} ${row?.unidade_medida}`
                      : row?.quantidade || 0,
                  cellClassName: ({ row }) =>
                    row?.devolucao === 'SIM' && 'isDevolucao',
                },
                {
                  field: 'preco',
                  headerName: 'Preço Unitário',
                  type: 'number',
                  width: 150,
                  sortable: false,
                  valueGetter: ({ value }) =>
                    value &&
                    value?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  cellClassName: ({ row }) =>
                    row?.devolucao === 'SIM' && 'isDevolucao',
                },
                {
                  field: 'desconto1_pc',
                  headerName: '% Desconto',
                  type: 'number',
                  width: 150,
                  sortable: false,
                  valueGetter: ({ value }) => value && value + '%',
                  cellClassName: ({ row }) =>
                    row?.devolucao === 'SIM' && 'isDevolucao',
                },
                {
                  field: 'desconto_vlr',
                  headerName: 'Valor Desconto',
                  type: 'number',
                  width: 150,
                  sortable: false,
                  valueGetter: ({ value }) =>
                    value &&
                    value?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  cellClassName: ({ row }) =>
                    row?.devolucao === 'SIM' && 'isDevolucao',
                },
                {
                  field: 'subtotal',
                  headerName: 'Valor Total',
                  type: 'number',
                  width: 150,
                  sortable: false,
                  valueGetter: ({ row }) =>
                    (
                      (row?.preco || 0) * (row?.quantidade || 0) +
                      (row?.desconto_vlr || 0)
                    )?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  cellClassName: ({ row }) =>
                    row?.devolucao === 'SIM' && 'isDevolucao',
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="secondary">
            * Devoluções
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Compras;
