import React, { useContext, useState } from 'react';
import {
  Checkbox,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  Delete,
  OpenInFull,
} from '@mui/icons-material';
import { ProdutosMlContext } from 'contexts/ProdutosMlContext';
import { useDialog } from 'components/Modals';
import Button from 'components/Button';
import Card from 'components/Card';
import styles from '../styles';

const Imagens = ({ loading, onSubmit, produtoMl = {}, disableActions }) => {
  const { openDialog } = useDialog();
  const listCols = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const onAddMidia = (source) => {
    if (Boolean(source)) {
      const pictures = produtoMl?.pictures?.map((p) => ({
        id: p?.id,
        url: p?.url,
      }));
      pictures.push({ source });
      onSubmit({ id: produtoMl?.id, pictures });
    }
  };

  const onDeleteMidia = (id) => {
    const pictures = produtoMl?.pictures
      ?.filter((f) => f?.id !== id)
      ?.map((p) => ({ id: p?.id, url: p?.url }));
    onSubmit({ id: produtoMl?.id, pictures });
  };

  const onChangePosition = (index, move) => {
    const pictures = produtoMl?.pictures?.map((p) => ({
      id: p?.id,
      url: p?.url,
    }));
    const element = pictures[index];
    pictures.splice(index, 1);
    pictures.splice(index + move, 0, element);

    onSubmit({ id: produtoMl?.id, pictures });
  };

  const Modal = () => {
    const [selected, setSelected] = useState(null);
    const { putLoading } = useContext(ProdutosMlContext);
    const RenderMidias = ({ images = [] }) =>
      images?.map((p, i) => (
        <ImageListItem key={i?.toString()}>
          <img src={p?.url} loading="lazy" />
          <ImageListItemBar
            actionIcon={
              <>
                <Checkbox
                  size="small"
                  checked={selected === p?.url}
                  sx={styles?.checkbox}
                  onChange={() => {
                    if (selected === p?.url) {
                      setSelected(null);
                    } else {
                      setSelected(p?.url);
                    }
                  }}
                />
                <IconButton size="small" onClick={() => window.open(p?.url)}>
                  <OpenInFull fontSize="small" sx={styles?.icon} />
                </IconButton>
              </>
            }
          />
        </ImageListItem>
      ));

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Selecione uma imagem para adicionar ao anúncio
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!!produtoMl?.imagens?.length && (
            <ImageList cols={listCols ? 2 : 5}>
              <RenderMidias images={produtoMl?.imagens} />
            </ImageList>
          )}
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => onAddMidia(selected)}
            loading={putLoading}
          >
            Adicionar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const RenderMidias = ({ images = [] }) =>
    images?.map((p, i) => (
      <ImageListItem key={i?.toString()}>
        <img src={p?.url} loading="lazy" />
        <ImageListItemBar
          actionIcon={
            <>
              <IconButton size="small" onClick={() => window.open(p?.url)}>
                <OpenInFull fontSize="small" sx={styles?.icon} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => onDeleteMidia(p?.id)}
                disabled={loading}
              >
                <Delete fontSize="small" sx={styles?.icon} />
              </IconButton>
              {Boolean(i > 0) && (
                <IconButton
                  size="small"
                  onClick={() => onChangePosition(i, -1)}
                >
                  <ChevronLeft fontSize="small" sx={styles?.icon} />
                </IconButton>
              )}
              {Boolean(i < images?.length - 1) && (
                <IconButton size="small" onClick={() => onChangePosition(i, 1)}>
                  <ChevronRight fontSize="small" sx={styles?.icon} />
                </IconButton>
              )}
            </>
          }
        />
      </ImageListItem>
    ));

  return (
    <Card title="Imagens" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Imagem')}
              loading={loading}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          {!!produtoMl?.pictures?.length && (
            <ImageList cols={listCols ? 2 : 5}>
              <RenderMidias images={produtoMl?.pictures} />
            </ImageList>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Imagens;
