import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { ProdutosContext } from 'contexts/ProdutosContext';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';

const Manutenção = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const defaultValues = {
    id: state?.id,
    produto_id: state?.produto_id || null,
    codigo: state?.codigo || '',
    descricaocurta: state?.descricaocurta || '',
  };
  const { drops } = useContext(DropsContext);
  const { postLoading, postIntegracao } = useContext(ProdutosContext);
  const { control, handleSubmit } = useForm({ defaultValues });

  const onSubmit = (data) =>
    postIntegracao({
      data,
      path: 'Bling/Produto',
      cb: () => navigate(-1),
    });

  return (
    <Container>
      <Header
        titulo={state?.id ? 'Editar Integração' : 'Cadastrar Integração'}
      />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VirtualDrop
              name="produto_id"
              control={control}
              label="Produto"
              options={drops?.Produto}
            />
          </Grid>
          <Grid item xs={12}>
            <Input name="codigo" control={control} label="Código" />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="descricaocurta"
              control={control}
              label="Descrição Curta"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Manutenção;
