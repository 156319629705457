import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { Info, PermMedia, Settings } from '@mui/icons-material';
import { DropsContext } from 'contexts/DropsContext';
import { ProdutosMlContext } from 'contexts/ProdutosMlContext';
import { useDialog } from 'components/Modals';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import CustomTabs from './Tabs';

const Detalhes = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const { state } = useLocation();
  const { drops } = useContext(DropsContext);
  const { closeDialog } = useDialog();
  const { produtoMl, getLoading, getProdutoMl, putProdutoMl, putLoading } =
    useContext(ProdutosMlContext);
  const disableActions = false;

  useEffect(() => {
    if (state?.id) {
      getProdutoMl({
        id: state?.id,
        integra_access_id: state?.integra_access_id,
      });
    } else {
      navigate(-1);
    }
  }, [state]);

  const onSubmit = (data) =>
    putProdutoMl({
      data: { ...data, integra_access_id: state?.integra_access_id },
      cb: () => {
        getProdutoMl({
          id: state?.id,
          integra_access_id: state?.integra_access_id,
        });
        closeDialog();
      },
    });

  const tabs = [
    {
      value: 0,
      key: 'Anúncio',
      label: 'Anúncio',
      icon: <Settings />,
    },
    {
      value: 1,
      key: 'Detalhes',
      label: 'Detalhes',
      icon: <Info />,
    },
    {
      value: 3,
      key: 'Imagens',
      label: 'Imagens',
      icon: <PermMedia />,
    },
  ];

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Anúncio" subtitulo={produtoMl?.title} />
      <Card>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(_, v) => setTab(v)}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          {tabs.map(({ show = true, ...t }) => show && <Tab {...t} />)}
        </Tabs>
      </Card>
      <CustomTabs
        tab={tab}
        drops={drops}
        produtoMl={produtoMl}
        getLoading={getLoading}
        disableActions={disableActions}
        loading={putLoading}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

export default Detalhes;
