import React from 'react';
import { useForm } from 'react-hook-form';
import { Checkbox, Grid, InputLabel, Switch } from '@mui/material';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import styles from '../styles';

const Anúncio = ({
  drops,
  loading,
  onSubmit,
  produtoMl = {},
  disableActions,
}) => {
  const defaultValues = {
    id: produtoMl?.id,
    title: produtoMl?.title || '',
    produto_id: produtoMl?.produto_id || null,
    price: produtoMl?.price || '',
    available_quantity: produtoMl?.available_quantity || '',
    reserva: produtoMl?.reserva || 'SIM',
    description: produtoMl?.description?.plain_text || '',
    local_pick_up: produtoMl?.shipping?.local_pick_up || false,
    free_shipping: produtoMl?.shipping?.free_shipping || false,
  };
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });

  const onConfirm = ({
    id,
    title,
    produto_id,
    reserva,
    price,
    available_quantity,
    description,
    free_shipping,
    local_pick_up,
  }) => {
    let data = { id, produto_id, reserva };
    if (title !== produtoMl?.title) {
      data = { ...data, title };
    }
    if (price !== produtoMl?.price) {
      data = { ...data, price };
    }
    if (available_quantity !== produtoMl?.available_quantity) {
      data = { ...data, available_quantity };
    }
    if (description !== produtoMl?.description?.plain_text) {
      data = { ...data, description };
    }
    if (free_shipping !== produtoMl?.shipping?.free_shipping) {
      data = { ...data, shipping: { ...data?.shipping, free_shipping } };
    }
    if (local_pick_up !== produtoMl?.shipping?.local_pick_up) {
      data = { ...data, shipping: { ...data?.shipping, local_pick_up } };
    }
    onSubmit(data);
  };

  return (
    <Card title="Informações Gerais" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input name="title" control={control} label="Título" />
        </Grid>
        <Grid item xs={12}>
          <VirtualDrop
            name="produto_id"
            control={control}
            label="Produto"
            options={drops?.Produto}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="price" control={control} label="Preço" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="available_quantity"
            control={control}
            label="Saldo"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <InputLabel variant="body2">Reservar estoque</InputLabel>
          <Switch
            checked={watch('reserva') === 'SIM'}
            onChange={(_, v) => setValue('reserva', v ? 'SIM' : 'NAO')}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="description"
            control={control}
            label="Descrição"
            rows={10}
            multiline
          />
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          <Checkbox
            checked={watch('local_pick_up')}
            id="local_pick_up"
            label="Retirada em Mãos"
            color="primary"
            onChange={(_, v) => setValue('local_pick_up', v)}
          />
          <InputLabel htmlFor="local_pick_up">Retirada em Mãos</InputLabel>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          <Checkbox
            checked={watch('free_shipping')}
            id="free_shipping"
            label="Frete Grátis"
            color="primary"
            onChange={(_, v) => setValue('free_shipping', v)}
          />
          <InputLabel htmlFor="free_shipping">Frete Grátis</InputLabel>
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={handleSubmit(onConfirm)}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Anúncio;
