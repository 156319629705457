import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Grid, Pagination, Tab, Tabs, Typography } from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { BarChart } from '@mui/x-charts/BarChart';
import Container from 'components/Container';
import moment from 'moment';

const Inventário = ({ indicadores }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [tab, setTab] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetWidth / 2);
  }, []);

  const chart = indicadores?.indicadores?.inventario;
  const rows = indicadores?.indicadores?.historicoInventario || [];

  const DocumentoCell = ({ row = {} }) => {
    const lines = [
      {
        label: 'Nº do Documento',
        value: row?.documento,
      },
      {
        label: 'Data de Emissão',
        value:
          moment(row?.dtemissao).isValid() &&
          moment(row?.dtemissao).format('DD/MM/YYYY [às] HH:mm'),
      },
      {
        label: 'Usuário',
        value: row?.user,
      },
      {
        label: 'Filial',
        value: row?.filial,
      },
      {
        label: 'Local de Estoque',
        value: row?.local,
      },
      {
        label: 'Variação',
        value: row?.variacao,
      },
      {
        label: 'Rastreabilidade',
        value: row?.rastreabilidade,
      },
    ];

    const title = () => {
      let str = '';
      if (row?.natureza === 'ESTOQUE') {
        str += row?.natureza || '';
        if (Boolean(row?.operacao)) {
          str += ` - ${row?.operacao || ''}`;
        }
      } else {
        str = row?.especie;
        if (Boolean(row?.natureza)) {
          str += ` DE ${row?.natureza}`;
        }
      }
      return str;
    };

    return (
      <Box flex={1}>
        <Typography fontWeight="bold" variant="body2" mb={1}>
          {title()}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs variant="fullWidth" value={tab} onChange={(_, v) => setTab(v)}>
            <Tab value={0} label="Quantidade" />
            <Tab value={1} label="Peso" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Box ref={ref}>
            {tab === 0 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#28518A',
                    label: 'Entrada',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.Entrada?.quantidade)
                      : [0],
                  },
                  {
                    color: '#01005E',
                    label: 'Saída',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.Saida?.quantidade)
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
            {tab === 1 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#28518A',
                    label: 'Entrada',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.Entrada?.peso)
                      : [0],
                  },
                  {
                    color: '#01005E',
                    label: 'Saída',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.Saida?.peso)
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            columns={[
              {
                field: 'documento',
                headerName: 'Informações Gerais',
                flex: 1,
                sortable: false,
                renderCell: (props) => <DocumentoCell {...props} />,
              },
              {
                field: 'quantidade',
                headerName: 'Quantidade',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ row }) =>
                  row?.quantidade && row?.unidade_medida
                    ? `${row?.quantidade} ${row?.unidade_medida}`
                    : row?.quantidade || 0,
              },
            ]}
            autoHeight
            pagination
            pageSize={10}
            density="compact"
            disableColumnMenu
            showCellRightBorder
            showColumnRightBorder
            disableSelectionOnClick
            localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            keepNonExistentRowsSelected
            getRowHeight={() => 'auto'}
            components={{ Pagination: CustomPagination }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Inventário;
