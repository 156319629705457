import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  dropBoolean,
  dropEstruturaLinhaProduto,
  dropIdSped,
  dropCusto,
  dropTipoItem,
} from 'utils/drops';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';

const Manutenção = () => {
  const rotina = 'Celinha';
  const defaultValues = {
    descricao: '',
    linha: '',
    descricao: '',
    servico: null,
    processo: null,
    estrutura: null,
    apontamento: null,
    referencia: null,
    obreferencia: null,
    sefazean: null,
    idsped: null,
    custo: null,
    tipoitem: null,
    ativo: null,
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getDrops } = useContext(DropsContext);
  const { postLoading, postGrid } = useContext(GridContext);
  const { control, handleSubmit, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(state?.values);
  }, []);

  const onSubmit = (data) =>
    postGrid({
      data: { data, rotina },
      cb: () => {
        navigate(-1);
        getDrops(['LinhaProduto']);
      },
    });

  return (
    <Container>
      <Header
        titulo={(Boolean(state) ? 'Editar' : 'Cadastrar') + ' Linha do Produto'}
      />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input name="linha" control={control} label="Linha" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="descricao" control={control} label="Descrição" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="servico"
              control={control}
              label="Serviço"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="processo"
              control={control}
              label="Processo"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="estrutura"
              control={control}
              label="Estrutura"
              options={dropEstruturaLinhaProduto}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="apontamento"
              control={control}
              label="Apontamento"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="referencia"
              control={control}
              label="Referência"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="obreferencia"
              control={control}
              label="Observação Referência"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="sefazean"
              control={control}
              label="SEFAZ EAN"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="idsped"
              control={control}
              label="ID Sped"
              options={dropIdSped}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="custo"
              control={control}
              label="Custo"
              options={dropCusto}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="tipoitem"
              control={control}
              label="Tipo Item"
              options={dropTipoItem}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="ativo"
              control={control}
              label="Ativo"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Manutenção;
