import React from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Grid, Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDialog } from 'components/Modals';
import moment from 'moment';

const MercadoLivre = ({ produto }) => {
  const navigate = useNavigate();
  const { closeDialog } = useDialog();

  const renderStatus = (value) => {
    const status =
      value === 'active'
        ? { color: '#7FFF7F', text: 'ATIVO' }
        : value === 'paused'
        ? { color: '#FFFF7F', text: 'PAUSADO' }
        : value === 'closed'
        ? { color: '#FF7F7F', text: 'FECHADO' }
        : { color: 'transparent', text: '' };

    const style = {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: status?.color,
      borderRadius: '50px',
    };
    return (
      <div style={style}>
        <span>{status?.text}</span>
      </div>
    );
  };

  const onShowDetails = (state) => {
    navigate('/app/Integrações/MercadoLivre/Detalhes', {
      state,
    });
    closeDialog();
  };

  const onRedirect = (link) => window.open(link);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DataGrid
          rows={produto?.ProdutoMls}
          columns={[
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              flex: 1,
              getActions: ({ row }) => [
                <GridActionsCellItem
                  icon={<Icon>visibility</Icon>}
                  label="Visualizar"
                  onClick={() => onShowDetails(row)}
                />,
                <GridActionsCellItem
                  icon={<Icon>open_in_new</Icon>}
                  label="Link"
                  onClick={() => onRedirect(row?.link)}
                />,
              ],
            },
            {
              field: 'titulo',
              headerName: 'Título do Anúncio',
              flex: 4,
              sortable: false,
            },
            {
              field: 'status',
              headerName: 'Status',
              flex: 1,
              sortable: false,
              renderCell: ({ value }) => renderStatus(value),
            },
            {
              field: 'validade',
              headerName: 'Validade',
              flex: 1,
              sortable: false,
              valueGetter: ({ value }) =>
                value && moment(value).format('DD/MM/YYYY [às] HH:mm'),
            },
            {
              field: 'estoque',
              headerName: 'Estoque',
              type: 'number',
              flex: 1,
              sortable: false,
            },
            {
              field: 'vendas',
              headerName: 'Vendas',
              type: 'number',
              flex: 1,
              sortable: false,
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
        />
      </Grid>
    </Grid>
  );
};

export default MercadoLivre;
