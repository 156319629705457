import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  status: [],
  titulo: '',
  estoque: '',
  sku: '',
  descricao: '',
};

export const ProdutosShopeeContext = createContext();

export const ProdutosShopeeProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [produtosShopee, setProdutosShopee] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getProdutosShopee = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: produtosShopee?.filter,
        page: produtosShopee?.page,
        size: produtosShopee?.size,
        order: produtosShopee?.order,
        ...payload,
      });
      setProdutosShopee((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getImgCategorias = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Integracoes/Shopee/Imagens', { params });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getMarcas = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Integracoes/Shopee/Marcas', { params });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getEnvios = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Integracoes/Shopee/AtributosEnvios', {
        params,
      });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const syncProdutoShopee = async ({ data, cb }) => {
    try {
      setSyncLoading(true);
      await api.post(`/Integracoes/Shopee/Sync`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setSyncLoading(false);
    }
  };

  return (
    <ProdutosShopeeContext.Provider
      value={{
        getLoading,
        getProdutosShopee,
        syncLoading,
        syncProdutoShopee,
        produtosShopee,
        getImgCategorias,
        getMarcas,
        getEnvios,
      }}
    >
      {children}
    </ProdutosShopeeContext.Provider>
  );
};
