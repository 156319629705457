import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { FormaDePagamentoContext } from 'contexts/FormaDePagamentoContext';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Card from 'components/Card';

const Manutenção = () => {
  const rotina = 'BoletoConfig';
  const navigate = useNavigate();
  const { state } = useLocation();
  const defaultValues = {
    forma_pagto_id: state?.id || null,
    banco_id: null,
    agencia: '',
    agenciadc: '',
    conta: '',
    contadc: '',
    cedente_id: null,
    codcedente: '',
    codcedentedc: '',
    nossonumero: '',
    integra_access_id: '',
    especiedoc: '',
    especie: '',
    convenio: '',
    usobanco: '',
    carteira: '',
    aceite: '',
    instrucao1: '',
    instrucao2: '',
    instrucao3: '',
    multa: '',
    mora: '',
    diasprotesto: '',
    sacaval_id: null,
    localpagamento: '',
    padrao: '',
  };
  const { drops } = useContext(DropsContext);
  const { postLoading, postGrid } = useContext(GridContext);
  const { boleto, getBoletoConfig, getLoading } = useContext(
    FormaDePagamentoContext
  );
  const { control, handleSubmit, setValue } = useForm({ defaultValues });

  useEffect(() => {
    if (state?.id) {
      getBoletoConfig({ id: state?.id });
    }
  }, []);

  useEffect(() => {
    if (state?.id && boleto) {
      setValue('banco_id', boleto?.banco_id || null);
      setValue('agencia', boleto?.agencia || '');
      setValue('agenciadc', boleto?.agenciadc || '');
      setValue('conta', boleto?.conta || '');
      setValue('contadc', boleto?.contadc || '');
      setValue('cedente_id', boleto?.cedente_id || null);
      setValue('codcedente', boleto?.codcedente || '');
      setValue('codcedentedc', boleto?.codcedentedc || '');
      setValue('nossonumero', boleto?.nossonumero || '');
      setValue('integra_access_id', boleto?.integra_access_id || '');
      setValue('especiedoc', boleto?.especiedoc || '');
      setValue('especie', boleto?.especie || '');
      setValue('convenio', boleto?.convenio || '');
      setValue('usobanco', boleto?.usobanco || '');
      setValue('carteira', boleto?.carteira || '');
      setValue('aceite', boleto?.aceite || '');
      setValue('instrucao1', boleto?.instrucao1 || '');
      setValue('instrucao2', boleto?.instrucao2 || '');
      setValue('instrucao3', boleto?.instrucao3 || '');
      setValue('multa', boleto?.multa || '');
      setValue('mora', boleto?.mora || '');
      setValue('diasprotesto', boleto?.diasprotesto || '');
      setValue('sacaval_id', boleto?.sacaval_id || null);
      setValue('localpagamento', boleto?.localpagamento || '');
      setValue('padrao', boleto?.padrao || '');
    }
  }, [boleto]);

  const onSubmit = (data) =>
    postGrid({
      data: { data, rotina },
      cb: () => navigate(-1),
    });

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Configuração de Boleto" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dropdown
              name="banco_id"
              control={control}
              label="Banco"
              options={drops?.EntidadeTipo?.filter(
                (f) => f?.tipo === 'INSTITUICAO FINANCEIRA'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="agencia" control={control} label="Agência" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="agenciadc" control={control} label="Agência (D.C)" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="conta" control={control} label="Conta" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="contadc" control={control} label="Conta (D.C)" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="cedente_id"
              control={control}
              label="Cedente"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="codcedente" control={control} label="Código Cedente" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input
              name="codcedentedc"
              control={control}
              label="Código Cedente (D.C)"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="nossonumero"
              control={control}
              label="Nosso Número"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="integra_access_id"
              control={control}
              label="Integração"
              options={drops?.IntegraAccess}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="especiedoc"
              control={control}
              label="Espécie do Documento"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="especie" control={control} label="Espécie da Moeda" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="convenio" control={control} label="Convênio" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="usobanco" control={control} label="Uso do Banco" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="carteira" control={control} label="Carteira" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="aceite" control={control} label="Aceite" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="instrucao1" control={control} label="Instrução 1" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="instrucao2" control={control} label="Instrução 2" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="instrucao3" control={control} label="Instrução 3" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="multa" control={control} label="Multa" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="mora" control={control} label="Mora" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              name="diasprotesto"
              control={control}
              label="Dias Protesto"
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <VirtualDrop
              name="sacaval_id"
              control={control}
              label="Sacador / Avalista"
              options={drops?.Entidade}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="localpagamento"
              control={control}
              label="Local de Pagamento"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="padrao" control={control} label="Padrão" />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Manutenção;
