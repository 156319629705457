import React, { useContext, useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Grid, Icon, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useModal } from 'components/Modals';
import { GridContext } from 'contexts/GridContext';
import { MoedaContext } from 'contexts/MoedaContext';
import DeleteModal from 'components/Modals/DeleteModal';
import Button from 'components/Button';
import InputMask from 'components/InputMask';
import Loader from 'components/Loader';
import moment from 'moment';

const CotaçõesModal = ({ item }) => {
  const [rows, setRows] = useState([]);
  const { openModal, closeModal } = useModal();
  const { postLoading, postGrid, deleteGrid } = useContext(GridContext);
  const { getCotacoes, getLoading } = useContext(MoedaContext);
  const rotina = 'MoedaCotacao';
  const defaultValues = {
    moeda_id: item?.id || null,
    cotacao: '',
    dtcotacao: moment().format('YYYY-MM-DD'),
  };
  const { control, handleSubmit, reset } = useForm({ defaultValues });

  const loadData = () => {
    getCotacoes({ id: item?.id, cb: (data) => setRows(data) });
    reset();
  };

  useEffect(() => {
    loadData();
  }, []);

  const onSubmit = (data) =>
    postGrid({
      data: { data, rotina },
      cb: loadData,
    });

  const onDelete = (row) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params: { rotina, id: row?.id },
            cb: () => {
              loadData();
              closeModal();
            },
          })
        }
      />
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          {item?.descricao}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask name="cotacao" control={control} label="Cotação" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          name="dtcotacao"
          control={control}
          label="Data da Cotação"
          type="date"
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Adicionar
        </Button>
      </Grid>
      <Grid item xs={12}>
        {getLoading ? (
          <Loader />
        ) : (
          <DataGrid
            rows={rows}
            columns={[
              {
                field: 'cotacao',
                headerName: 'Cotação',
                flex: 4,
                sortable: false,
                valueGetter: ({ value }) =>
                  value &&
                  `R$ ${value?.toLocaleString('pt-br', {
                    minimumFractionDigits: 6,
                  })}`,
              },
              {
                field: 'dtcotacao',
                headerName: 'Data da Cotação',
                flex: 4,
                sortable: false,
                valueGetter: ({ value }) =>
                  value && moment(value).format('DD/MM/YYYY'),
              },
              {
                field: 'actions',
                headerName: 'Ações',
                type: 'actions',
                flex: 1,
                getActions: ({ row }) => [
                  <GridActionsCellItem
                    icon={<Icon>delete</Icon>}
                    label="Excluir"
                    onClick={() => onDelete(row)}
                  />,
                ],
              },
            ]}
            hideFooter
            autoHeight
            density="compact"
            disableSelectionOnClick
            disableColumnMenu
            showCellRightBorder
            showColumnRightBorder
            localeText={{ noRowsLabel: 'Nenhum Registro.' }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CotaçõesModal;
