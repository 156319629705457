import React, { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { dropTipoMovimentacao, dropBoolean } from 'utils/drops';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { DropsContext } from 'contexts/DropsContext';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';

const Manutenção = () => {
  const defaultValues = {
    descricao_completa: '',
    descricao_reduzida: '',
    celinha_id: null,
    cegrupo_id: null,
    cemarca_id: null,
    cfiscal_id: null,
    origem_tributaria_id: null,
    anp_id: null,
    unidade_medida_id: null,
    referencia: '',
    codigo: '',
    cdbarra: '',
    entidade_id: null,
    lotemin: '',
    lotemult: '',
    loterepos: '',
    temporepos: '',
    estmin: '',
    estmax: '',
    custo: '',
    preco: '',
    margem: '',
    peso: '',
    volume: '',
    altura: '',
    largura: '',
    comprimento: '',
    qtrib: '',
    cest: '',
    complemento1: '',
    complemento2: '',
    localizador: '',
    fci: '',
    movimentacao: null,
    rastreavel: null,
    variacao: null,
    retornavel: null,
    dtcad: moment().format('YYYY-MM-DD'),
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { drops, getDrops } = useContext(DropsContext);
  const { postProduto, postLoading, produto, getLoading, getProduto } =
    useContext(ProdutosContext);
  const { control, handleSubmit, setValue } = useForm({ defaultValues });

  useEffect(() => {
    if (state?.id) {
      getProduto(state?.id);
    }
  }, []);

  useEffect(() => {
    if (produto && state?.id) {
      const descricao_completa = produto?.ProdutoDescricaos?.find(
        (d) => d?.tipo === 'COMPLETA'
      )?.descricao;
      const descricao_reduzida = produto?.ProdutoDescricaos?.find(
        (d) => d?.tipo === 'REDUZIDA'
      )?.descricao;
      setValue('descricao_completa', descricao_completa || '');
      setValue('descricao_reduzida', descricao_reduzida || '');
      setValue('celinha_id', produto?.celinha_id || null);
      setValue('cegrupo_id', produto?.cegrupo_id || null);
      setValue('cemarca_id', produto?.cemarca_id || null);
      setValue('cfiscal_id', produto?.cfiscal_id || null);
      setValue('origem_tributaria_id', produto?.origem_tributaria_id || null);
      setValue('anp_id', produto?.anp_id || null);
      setValue('unidade_medida_id', produto?.unidade_medida_id || null);
      setValue('referencia', produto?.referencia || '');
      setValue('codigo', produto?.codigo || '');
      setValue('cdbarra', produto?.cdbarra || '');
      setValue('entidade_id', produto?.entidade_id || '');
      setValue('lotemin', produto?.lotemin || '');
      setValue('lotemult', produto?.lotemult || '');
      setValue('loterepos', produto?.loterepos || '');
      setValue('temporepos', produto?.temporepos || '');
      setValue('estmin', produto?.estmin || '');
      setValue('estmax', produto?.estmax || '');
      setValue('custo', produto?.custo_compra || '');
      setValue('preco', produto?.preco || '');
      setValue('margem', produto?.margem || '');
      setValue('peso', produto?.peso || '');
      setValue('volume', produto?.volume || '');
      setValue('altura', produto?.altura || '');
      setValue('largura', produto?.largura || '');
      setValue('comprimento', produto?.comprimento || '');
      setValue('qtrib', produto?.qtrib || '');
      setValue('cest', produto?.cest || '');
      setValue('complemento1', produto?.complemento1 || '');
      setValue('complemento2', produto?.complemento2 || '');
      setValue('localizador', produto?.localizador || '');
      setValue('fci', produto?.fci || '');
      setValue('movimentacao', produto?.movimentacao || null);
      setValue('rastreavel', produto?.rastreavel || null);
      setValue('variacao', produto?.variacao || null);
      setValue('retornavel', produto?.retornavel || null);
      setValue('dtcad', produto?.dtcad || moment().format('YYYY-MM-DD'));
    }
  }, [produto]);

  const onPost = ({
    descricao_completa,
    descricao_reduzida,
    preco,
    custo,
    margem,
    ...Produto
  }) => {
    const Descricoes = [];
    let Preco = {};
    let Custo = {};
    if (preco) {
      Preco = { ...Preco, preco };
    }
    if (margem) {
      Preco = { ...Preco, margem };
    }
    if (custo) {
      Custo = { ...Custo, custo };
    }
    if (Boolean(descricao_completa)) {
      Descricoes?.push({
        tipo: 'COMPLETA',
        descricao: descricao_completa,
      });
    }
    if (Boolean(descricao_reduzida)) {
      Descricoes?.push({
        tipo: 'REDUZIDA',
        descricao: descricao_reduzida,
      });
    }
    let data = { Produto };
    if (Boolean(Descricoes?.length)) {
      data = { ...data, Descricoes };
    }
    if (Boolean(Object.keys(Preco)?.length)) {
      data = { ...data, Preco };
    }
    if (Boolean(Object.keys(Custo)?.length)) {
      data = { ...data, Custo };
    }
    return data;
  };

  const onPut = ({
    descricao_completa,
    descricao_reduzida,
    preco,
    custo,
    margem,
    ...rest
  }) => {
    const Descricoes = [];
    const Produto = { id: state?.id, ...rest };
    let Preco = {};
    let Custo = {};
    if (preco && preco !== produto?.preco) {
      Preco = { ...Preco, preco };
    }
    if (margem && margem !== produto?.margem) {
      Preco = { ...Preco, margem };
    }
    if (custo && custo !== produto?.custo_compra) {
      Custo = { ...Custo, custo };
    }
    if (Boolean(produto) || Boolean(descricao_completa)) {
      const desc = produto?.ProdutoDescricaos?.find(
        (d) => d?.tipo === 'COMPLETA'
      );
      Descricoes?.push({
        id: desc?.id,
        produto_id: state?.id,
        tipo: 'COMPLETA',
        descricao: descricao_completa,
      });
    }
    if (Boolean(produto) || Boolean(descricao_reduzida)) {
      const desc = produto?.ProdutoDescricaos?.find(
        (d) => d?.tipo === 'REDUZIDA'
      );
      Descricoes?.push({
        id: desc?.id,
        produto_id: state?.id,
        tipo: 'REDUZIDA',
        descricao: descricao_reduzida,
      });
    }
    let data = { Produto };
    if (Boolean(Descricoes?.length)) {
      data = { ...data, Descricoes };
    }
    if (Boolean(Object.keys(Preco)?.length)) {
      data = { ...data, Preco };
    }
    if (Boolean(Object.keys(Custo)?.length)) {
      data = { ...data, Custo };
    }
    return data;
  };

  const onSubmit = (values) => {
    const data = state?.id ? onPut(values) : onPost(values);
    postProduto({
      data,
      cb: () => {
        navigate(-1);
        getDrops(['Produto']);
      },
    });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header
        titulo={(Boolean(state?.id) ? 'Editar' : 'Cadastrar') + ' Produto'}
      />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input
              name="descricao_completa"
              control={control}
              label="Descrição Completa"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="descricao_reduzida"
              control={control}
              label="Descrição Reduzida"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="celinha_id"
              control={control}
              label="Linha"
              options={drops?.LinhaProduto}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="cegrupo_id"
              control={control}
              label="Grupo"
              options={drops?.GrupoProduto}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="cemarca_id"
              control={control}
              label="Marca"
              options={drops?.Marca}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VirtualDrop
              name="cfiscal_id"
              control={control}
              label="Classificação Fiscal"
              options={drops?.Cfiscal}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="origem_tributaria_id"
              control={control}
              label="Origem Tributária"
              options={drops?.OrigemTributaria}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="anp_id"
              control={control}
              label="ANP"
              options={drops?.Anp}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="unidade_medida_id"
              control={control}
              label="Unidade de Medida"
              options={drops?.UnidadeMedida}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="referencia" control={control} label="Referência" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="codigo" control={control} label="Código" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="cdbarra"
              control={control}
              label="Código de Barras"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <VirtualDrop
              name="entidade_id"
              control={control}
              label="Proprietário"
              options={drops?.Entidade}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="estmin" control={control} label="Estoque Mínimo" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="estmax" control={control} label="Estoque Máximo" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask name="lotemin" control={control} label="Lote Mínimo" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="lotemult"
              control={control}
              label="Lote Múltiplo"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="loterepos"
              control={control}
              label="Lote Reposição"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="temporepos"
              control={control}
              label="Tempo Reposição"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="custo" control={control} label="Custo" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="preco" control={control} label="Preço de Venda" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="margem" control={control} label="Margem" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="peso" control={control} label="Peso" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="volume" control={control} label="Volume" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="altura" control={control} label="Altura" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="largura" control={control} label="Largura" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="comprimento"
              control={control}
              label="Comprimento"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="qtrib"
              control={control}
              label="Fator Unidade Tributária"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="cest" control={control} label="CEST" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="complemento1"
              control={control}
              label="Complemento 1"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="complemento2"
              control={control}
              label="Complemento 2"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="localizador" control={control} label="Localizador" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="fci" control={control} label="FCI" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="movimentacao"
              control={control}
              label="Tipo de Movimentação"
              options={dropTipoMovimentacao}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="rastreavel"
              control={control}
              label="Rastreável"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="variacao"
              control={control}
              label="Variação"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="retornavel"
              control={control}
              label="Retornável"
              options={dropBoolean}
            />
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Container>
  );
};

export default Manutenção;
