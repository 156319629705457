import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit, Rule } from '@mui/icons-material';
import { dropTipoPerfil, dropDivisao } from 'utils/drops';
import { EntidadesContext } from 'contexts/EntidadesContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Perfis = ({
  drops,
  onSubmit,
  onDeletePerfil,
  entidade = {},
  disableActions,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'EntidadeTipo';

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      entidade_id: entidade?.id,
      tipo: item?.tipo || null,
      entidade_filial_id: item?.entidade_filial_id || null,
      entidade_tributacao_id: item?.entidade_tributacao_id || null,
      tabpreco_id: item?.tabpreco_id || null,
      regiao_entidade_id: item?.regiao_entidade_id || null,
      grupo_entidade_id: item?.grupo_entidade_id || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(EntidadesContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="tipo"
            control={control}
            label="Tipo"
            options={dropTipoPerfil}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="entidade_filial_id"
            control={control}
            label="Filial"
            options={drops?.Filial}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="entidade_tributacao_id"
            control={control}
            label="Tributação"
            options={drops?.EntidadeTributacao}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="tabpreco_id"
            control={control}
            label="Tabela de Preço"
            options={drops?.TabelaPreco}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="regiao_entidade_id"
            control={control}
            label="Região"
            options={drops?.RegiaoEntidade}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="grupo_entidade_id"
            control={control}
            label="Grupo"
            options={drops?.GrupoEntidade}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Perfis: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const RuleModal = ({ item }) => {
    const defaultValues = {
      entidade_tipo_id: item?.id,
      desconto1_pc: item?.EntidadeComercial?.desconto1_pc || '',
      desconto2_pc: item?.EntidadeComercial?.desconto2_pc || '',
      desconto3_pc: item?.EntidadeComercial?.desconto3_pc || '',
      desconto4_pc: item?.EntidadeComercial?.desconto4_pc || '',
      desconto5_pc: item?.EntidadeComercial?.desconto5_pc || '',
      divisaopc: item?.EntidadeComercial?.divisaopc || '',
      divisaotp: item?.EntidadeComercial?.divisaotp || null,
      inivalid: item?.EntidadeComercial?.inivalid || null,
      fimvalid: item?.EntidadeComercial?.fimvalid || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(EntidadesContext);

    const onConfirm = (EntidadeComercial) => {
      const data = {
        Perfis: [{ ...item, entidade_id: entidade?.id, EntidadeComercial }],
      };
      onSubmit(data);
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <InputMask
            name="desconto1_pc"
            control={control}
            label="% de Desconto 1"
          />
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <InputMask
            name="desconto2_pc"
            control={control}
            label="% de Desconto 2"
          />
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <InputMask
            name="desconto3_pc"
            control={control}
            label="% de Desconto 3"
          />
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <InputMask
            name="desconto4_pc"
            control={control}
            label="% de Desconto 4"
          />
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <InputMask
            name="desconto5_pc"
            control={control}
            label="% de Desconto 5"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="divisaopc" control={control} label="% de Divisão" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="divisaotp"
            control={control}
            label="Divisão do Documento"
            options={dropDivisao}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="inivalid"
            control={control}
            label="Início da Validade"
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="fimvalid"
            control={control}
            label="Fim da Validade"
            type="date"
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            // onClick={handleSubmit((data) => onSubmit({ Perfis: [data] }))}
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Perfis" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Perfil')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!entidade?.EntidadeTipos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo de Perfil</TableCell>
                    <TableCell>Filial</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entidade?.EntidadeTipos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.tipo}</TableCell>
                      <TableCell>{item?.Entidade?.nome}</TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <RuleModal item={item} />,
                                'Predefinição de Perfil'
                              )
                            }
                          >
                            <Rule />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(<Modal item={item} />, 'Editar Perfil')
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              onDeletePerfil({ rotina, id: item?.id })
                            }
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Perfis;
