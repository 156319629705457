import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  status: [],
  produto: [],
  linha: [],
  grupo: [],
  cliente: [],
  vendedor: [],
  grupo_entidade: [],
  condicao_pagamento: [],
  tabpreco: [],
  preco: '',
  margem: '',
};

export const PrecoContext = createContext();

export const PrecoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [preco, setPreco] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getPreco = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: preco?.filter,
        page: preco?.page,
        size: preco?.size,
        order: preco?.order,
        ...payload,
      });
      setPreco((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postPreco = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Regras/Preco', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <PrecoContext.Provider
      value={{
        getLoading,
        preco,
        getPreco,
        postLoading,
        postPreco,
      }}
    >
      {children}
    </PrecoContext.Provider>
  );
};
