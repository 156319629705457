import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  descricao: '',
  vlrmaxaprova: '',
  aprova: [],
  analisa: [],
  ativo: null,
};

export const GrupoEntidadeContext = createContext();

export const GrupoEntidadeProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [grupoEntidade, setGrupoEntidade] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getGrupoEntidade = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: grupoEntidade?.filter,
        page: grupoEntidade?.page,
        size: grupoEntidade?.size,
        order: grupoEntidade?.order,
        ...payload,
      });
      setGrupoEntidade((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <GrupoEntidadeContext.Provider
      value={{
        getLoading,
        grupoEntidade,
        getGrupoEntidade,
      }}
    >
      {children}
    </GrupoEntidadeContext.Provider>
  );
};
