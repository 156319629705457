import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  descricao: '',
  codigo: '',
  terceiro: null,
  ativo: null,
};

export const CcustoContext = createContext();

export const CcustoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [ccusto, setCcusto] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getCcusto = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: ccusto?.filter,
        page: ccusto?.page,
        size: ccusto?.size,
        order: ccusto?.order,
        ...payload,
      });
      setCcusto((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <CcustoContext.Provider
      value={{
        getLoading,
        ccusto,
        getCcusto,
      }}
    >
      {children}
    </CcustoContext.Provider>
  );
};
