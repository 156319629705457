import React from 'react';
import { Grid } from '@mui/material';
import { dropBoolean } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('local') && (
        <Grid item xs={12}>
          <Input name="local" control={control} label="Local" />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('filial') && (
        <Grid item xs={12}>
          <Dropdown
            name="filial"
            control={control}
            label="Filial"
            options={drops?.Filial}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('disponibilizar') && (
        <Grid item xs={12}>
          <Dropdown
            name="disponibilizar"
            control={control}
            label="Disponibilizar"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('negativo') && (
        <Grid item xs={12}>
          <Dropdown
            name="negativo"
            control={control}
            label="Negativo"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('ativo') && (
        <Grid item xs={12}>
          <Dropdown
            name="ativo"
            control={control}
            label="Ativo"
            options={dropBoolean}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
