import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  dropTipoRodado,
  dropTipoCarroceria,
  dropTipoCarga,
  dropTipoUnidadeTransporte,
  dropTipoProprietario,
} from 'utils/drops';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';

const Manutenção = () => {
  const rotina = 'Frota';
  const defaultValues = {
    descricao: '',
    frota: '',
    placa: '',
    marca: '',
    modelo: '',
    anofab: '',
    anoveic: '',
    qtlacres: '',
    compartimentos: '',
    renavan: '',
    cor: '',
    chassi: '',
    rntrc: '',
    tara: '',
    capkg: '',
    capm3: '',
    tprodado: null,
    tpcarroceria: null,
    tipocarga: null,
    tpuntransporte: null,
    tpproprietario: null,
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getDrops } = useContext(DropsContext);
  const { postLoading, postGrid } = useContext(GridContext);
  const { control, handleSubmit, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(state?.values);
  }, []);

  const onSubmit = (data) =>
    postGrid({
      data: { data, rotina },
      cb: () => {
        navigate(-1);
        getDrops([rotina]);
      },
    });

  return (
    <Container>
      <Header titulo={(Boolean(state) ? 'Editar' : 'Cadastrar') + ' Frota'} />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input name="descricao" control={control} label="Descrição" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="frota" control={control} label="Frota" type="number" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="placa" control={control} label="Placa" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="marca" control={control} label="Marca" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="modelo" control={control} label="Modelo" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input
              name="anogab"
              control={control}
              label="Ano de Fabricação"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input
              name="anoveic"
              control={control}
              label="Ano do Veículo"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="qtlacres"
              control={control}
              label="Quantidade de Lacres"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="compartimentos"
              control={control}
              label="Quantidade de Compartimentos"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="renavan" control={control} label="Renavan" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="cor" control={control} label="Cor" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="chassi" control={control} label="Chassi" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="rntrc" control={control} label="RNTRC" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="tara" control={control} label="Tara" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="capkg" control={control} label="Capacidade (KG)" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="capm3" control={control} label="Capacidade (M³)" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="tprodado"
              control={control}
              label="Tipo Rodado"
              options={dropTipoRodado}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="tpcarroceria"
              control={control}
              label="Tipo da Carroceria"
              options={dropTipoCarroceria}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="tipocarga"
              control={control}
              label="Tipo da Carga"
              options={dropTipoCarga}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="tpuntransporte"
              control={control}
              label="Tipo da Unidade de Transporte"
              options={dropTipoUnidadeTransporte}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="tpproprietario"
              control={control}
              label="Tipo do Proprietário"
              options={dropTipoProprietario}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Manutenção;
