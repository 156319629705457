import React from 'react';
import { Typography, Grid } from '@mui/material';
import Button from 'components/Button';
import Card from 'components/Card';
import styles from '../styles';

const Custos = ({ produto = {}, loading, onSubmitCusto, disableActions }) => {
  const summarizer = (field) =>
    produto?.Estruturas?.length &&
    produto?.Estruturas?.map((i) => i[field])?.reduce(
      (prev, next) => prev + next
    );

  const custos = [
    { label: 'Custo Atual', value: produto?.custo_compra },
    {
      label: 'Custo Matéria Prima',
      value: summarizer('custo_mp'),
    },
    { label: 'Custo Processo', value: summarizer('custo_processo') },
    {
      label: 'Projeção de Novo Custo',
      value: summarizer('custo_mp') + summarizer('custo_processo'),
    },
  ];

  const RenderCustos = () =>
    custos?.map((c) => {
      return (
        <Grid item xs={12} display="flex" key={c?.label}>
          <Grid item xs={6} sm={4}>
            <Typography variant="subtitle2" component="div">
              {c?.label}:
            </Typography>
          </Grid>
          <Grid item xs={6} sm={8}>
            <Typography variant="subtitle2" component="div">
              {(c?.value * 1)?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      );
    });

  return (
    <Card title="Custos" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              loading={loading}
              onClick={() => onSubmitCusto({ produtos: [produto?.id] })}
            >
              Atualizar Custo
            </Button>
          </Grid>
        )}
        <RenderCustos />
      </Grid>
    </Card>
  );
};

export default Custos;
