import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  codigo: '',
  descricao: '',
  updated_at: { i: null, f: null },
};

export const ProdutosBlingContext = createContext();

export const ProdutosBlingProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [produtosBling, setProdutosBling] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getProdutosBling = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: produtosBling?.filter,
        page: produtosBling?.page,
        size: produtosBling?.size,
        order: produtosBling?.order,
        ...payload,
      });
      setProdutosBling((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const syncProdutoBling = async ({ data, cb }) => {
    try {
      setSyncLoading(true);
      await api.post(`/Integracoes/Bling/Sync`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setSyncLoading(false);
    }
  };

  return (
    <ProdutosBlingContext.Provider
      value={{
        getLoading,
        getProdutosBling,
        produtosBling,
        syncLoading,
        syncProdutoBling,
      }}
    >
      {children}
    </ProdutosBlingContext.Provider>
  );
};
