import React, { useContext, useEffect } from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { useModal } from 'components/Modals';
import { useForm } from 'react-hook-form';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { ProdutosMlContext } from 'contexts/ProdutosMlContext';
import Input from 'components/Input';
import Button from 'components/Button';
import Loader from 'components/Loader';

const CopyModal = ({ row, callback }) => {
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({
    defaultValues: { title: '' },
  });
  const { produtoMl, getLoading, getProdutoMl } = useContext(ProdutosMlContext);
  const { postLoading, postIntegracao } = useContext(ProdutosContext);

  useEffect(() => {
    if (row) {
      getProdutoMl({ id: row?.id, integra_access_id: row?.integra_access_id });
    }
  }, []);

  const onSubmit = ({ title }) => {
    const data = {
      title,
      produto_id: produtoMl?.produto_id,
      category_id: produtoMl?.category_id,
      condition: produtoMl?.condition,
      listing_type_id: produtoMl?.listing_type_id,
      logistic_type: produtoMl?.shipping?.logistic_type,
      garantia:
        produtoMl?.sale_terms?.find((f) => f?.id === 'WARRANTY_TYPE')?.f
          ?.value_id || null,
      tempogarantia:
        produtoMl?.sale_terms?.find((f) => f?.id === 'WARRANTY_TIME')?.f
          ?.value_name || null,
      saldo: produtoMl?.available_quantity,
      free_shipping: produtoMl?.shipping?.free_shipping,
      local_pick_up: produtoMl?.shipping?.local_pick_up,
      attributes: produtoMl?.attributes?.map((a) => ({
        id: a?.id,
        value_name: a?.value_name || a?.value_id,
      })),
      integra_access_id: produtoMl?.integra_access_id,
    };
    postIntegracao({
      data,
      path: 'MercadoLivre/Anuncio',
      cb: () => {
        if (callback) {
          callback();
        }
        closeModal();
      },
    });
  };

  return (
    <>
      <DialogTitle>Copiar Anúncio</DialogTitle>
      <DialogContent>
        {getLoading ? (
          <Loader />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Input name="title" control={control} label="Título" />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} loading={postLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default CopyModal;
