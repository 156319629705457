import React from 'react';
import { Grid } from '@mui/material';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('sigla') && (
        <Grid item xs={12}>
          <Input name="sigla" control={control} label="Sigla" />
        </Grid>
      )}
      {checkDisplay('estado') && (
        <Grid item xs={12}>
          <Input name="estado" control={control} label="Estado" />
        </Grid>
      )}
      {checkDisplay('pais') && (
        <Grid item xs={12}>
          <Dropdown
            multiple
            name="pais"
            control={control}
            label="País"
            options={drops?.Pais}
          />
        </Grid>
      )}
      {checkDisplay('codigo_ibge') && (
        <Grid item xs={12}>
          <Input
            name="codigo_ibge"
            control={control}
            label="Código IBGE"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('icms_interno') && (
        <Grid item xs={12}>
          <Input
            name="icms_interno"
            control={control}
            label="ICMS Interno"
            type="number"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
