import React from 'react';
import { Grid } from '@mui/material';
import { dropBoolean, dropAtivo } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('ativo') && (
        <Grid item xs={12}>
          <Dropdown
            name="ativo"
            control={control}
            label="Ativo"
            options={dropAtivo}
          />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('referencia') && (
        <Grid item xs={12}>
          <Input name="referencia" control={control} label="Referência" />
        </Grid>
      )}
      {checkDisplay('preco') && (
        <Grid item xs={12}>
          <InputMask name="preco" control={control} label="Preço" />
        </Grid>
      )}
      {checkDisplay('localizador') && (
        <Grid item xs={12}>
          <Input name="localizador" control={control} label="Localização" />
        </Grid>
      )}
      {checkDisplay('unidade') && (
        <Grid item xs={12}>
          <Dropdown
            name="unidade"
            control={control}
            label="Unidade de Medida"
            options={drops?.UnidadeMedida}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('linha') && (
        <Grid item xs={12}>
          <Dropdown
            name="linha"
            control={control}
            label="Linha"
            options={drops?.LinhaProduto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('grupo') && (
        <Grid item xs={12}>
          <Dropdown
            name="grupo"
            control={control}
            label="Grupo"
            options={drops?.GrupoProduto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('marca') && (
        <Grid item xs={12}>
          <Dropdown
            name="marca"
            control={control}
            label="Marca"
            options={drops?.Marca}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('ncm') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="ncm"
            control={control}
            label="NCM"
            options={drops?.Cfiscal}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('origem_tributaria') && (
        <Grid item xs={12}>
          <Dropdown
            name="origem_tributaria"
            control={control}
            label="Origem Tributária"
            options={drops?.OrigemTributaria}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('complemento1') && (
        <Grid item xs={12}>
          <Input name="complemento1" control={control} label="Complemento 1" />
        </Grid>
      )}
      {checkDisplay('complemento2') && (
        <Grid item xs={12}>
          <Input name="complemento2" control={control} label="Complemento 2" />
        </Grid>
      )}
      {checkDisplay('rastreavel') && (
        <Grid item xs={12}>
          <Dropdown
            name="rastreavel"
            control={control}
            label="Rastreável"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('variacao') && (
        <Grid item xs={12}>
          <Dropdown
            name="variacao"
            control={control}
            label="Variação"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('especificacao') && (
        <Grid item xs={12}>
          <Input name="especificacao" control={control} label="Especificação" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
