import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ProdutosShopeeContext } from 'contexts/ProdutosShopeeContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Anúncios = () => {
  const rotina = 'ProdutoShopee';
  const titulo = 'Anúncios Integrados com Shopee';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { produtosShopee, getProdutosShopee, getLoading, syncProdutoShopee } =
    useContext(ProdutosShopeeContext);
  const { control, getValues, reset } = useForm({
    defaultValues: produtosShopee?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getProdutosShopee({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      action: () => navigate('/app/Integrações/Shopee/Manutencao'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: produtosShopee?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: produtosShopee?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={produtosShopee?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
  ];
  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) =>
        navigate(`/app/Integrações/Shopee/Detalhes/${row?.id}`),
    },
    {
      name: 'Sincronizar',
      icon: 'sync',
      action: ({ row }) => syncProdutoShopee({ data: row, cb: loadGrid }),
      menu: true,
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={produtosShopee?.colunas}
      />
      <Card>
        <Grid
          mock={mock}
          grid={produtosShopee}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={produtosShopee} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Anúncios;
