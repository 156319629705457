import React from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Grid, Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDialog } from 'components/Modals';

const Bling = ({ produto, onDeleteIntegracao }) => {
  const navigate = useNavigate();
  const { closeDialog } = useDialog();

  const onEdit = (state) => {
    closeDialog();
    navigate('/app/Integrações/Bling/Manutencao', { state });
  };

  const onDelete = (item) =>
    onDeleteIntegracao({ id: item?.id, path: 'Bling/Produto' });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DataGrid
          rows={produto?.ProdutoBlings}
          columns={[
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              flex: 1,
              getActions: ({ row }) => [
                <GridActionsCellItem
                  icon={<Icon>edit</Icon>}
                  label="Editar"
                  onClick={() => onEdit(row)}
                />,
                <GridActionsCellItem
                  icon={<Icon>delete</Icon>}
                  label="Excluir"
                  onClick={() => onDelete(row)}
                />,
              ],
            },
            {
              field: 'id',
              headerName: 'ID',
              flex: 1,
              sortable: false,
            },
            {
              field: 'codigo',
              headerName: 'Código',
              flex: 2,
              sortable: false,
            },
            {
              field: 'descricaocurta',
              headerName: 'Descrição',
              flex: 2,
              sortable: false,
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
        />
      </Grid>
    </Grid>
  );
};

export default Bling;
